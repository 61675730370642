import { DateTime } from 'luxon';

export type IProps = any;

export type LeadsFindRequestPayload = {
  start: number;
  take: number;
  name?: string;
  keyword?: string;
};

export const FilterComponentType = {
  SEARCH_INPUT: 'searchInput',
  DROP_DOWN: 'dropDown',
  MULTILEVEL_CHECKBOX: 'checkbox',
  TAG_CHECKBOX: 'savedTag',
  SAVED_LEAD_DATE: 'createdDate',
};

export const LeadFinderTabKey = {
  PEOPLE_TAB: 'people',
  SAVED_TAB: 'saved',
};

export const LeadRevealType = {
  EMAIL: 'email',
  EMAIL_PHONE: 'emailphone',
};

export const LeadRevealLimit = {
  VALID: 'valid',
  CREDIT_LIMIT_REACHED: 'credit-limit-reached',
  MORE_SELECTED: 'more-selected',
};

export const BulkLeadsActionType = {
  REVEAL: 'reveal',
  ADD_TO_PROSPECT: 'add_to_prospect',
  ADD_TO_SEQUENCE: 'add_tp_sequence',
  EXPORT: 'export',
};

export const BULK_ACTION_TIMEOUT = 15000;

export const RR_PAGINATION_LIMIT = 10000;

export const LEAD_FINDER_REVEAL_LIMIT = 100;

export type SavedTabFilters = {
  tags: number[];
  createdDate: Date[];
  search: string;
};

export type AddTagsToLeadsPayload = {
  tagIds: number[];
  leads: number[];
  newTags: string[];
};

export type RemoveTagsToLeadsPayload = {
  tagIds: number[];
  leads: number[];
};

export type BulkActionPayload = {
  deSelectedLeadIds?: number[];
  leadsFilter?: {
    tags?: number[];
    search?: string;
    createdDate?: any[];
  };
  newTags?: string[];
  tagIds?: number[];
};

export type BulkActionRemoveTagsPayload = {
  deSelectedLeadIds?: number[];
  leadsFilter?: {
    tags?: number[];
    search?: string;
    createdDate?: any[];
  };
  tagIds?: number[];
};

export class BulkAddToProspectSavedDto {
  deSelectedLeadIds?: number[];
  leadsFilter: {
    tags?: number[];
    search?: string;
    createdDate?: any[];
  };
}

export class BulkAddToSequenceSavedDto {
  sequenceId: number;
  stepId: number;
  deSelectedLeadIds?: number[];
  leadsFilter: {
    tags?: number[];
    search?: string;
    createdDate?: any[];
  };
}

export class BulkLeadRevealPeopleDto {
  leadsFilter: any;
  newTags?: string[];
  tagIds?: number[];
}

export class BulkLeadExportSavedDto {
  deSelectedLeadIds?: number[];
  leadsFilter: {
    tags?: number[];
    search?: string;
    createdDate?: any[];
  };
}
