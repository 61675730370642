import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { IProps } from './types';
import ContactStatusTag from '../../../../../../../../shared/design-system/components/atoms/contact-status-tag/contact-status-tag';
import TruncatedTextWithTooltip from '../../../../../../../../shared/components/truncated-text-with-tooltip/truncated-text-with-tooltip';
import { getOutcomeIcon } from '../../../../../../../sequence/components/sequence-single-content/components/sequence-single-contacts/components/contact-list-content/components/helpers/step-filter-mapper';
import {
  getOptionFromOutcome,
  mapOutcomeToOption,
} from '../../../../../../../sequence/components/sequence-single-content/components/sequence-single-contacts/components/prospect-outcome-select/utils/helper';
import GenericCustomOutcomeDropdown from '../../../../../../../settings/components/custom-outcomes/components/custom-outcome-dropdown';

const DataCard: React.FC<IProps> = ({
  data,
  showAllSequences,
  allOutcomes,
}) => {
  const { t } = useTranslation();

  return (
    <div className="data-card">
      <div className="data-card__header bs-mb-12">
        <div className="sequence-title-wrap">
          <span className="semibold-2 gray-txt-12">{t('labels.sequence')}</span>
        </div>
        <div className="status-title-wrap">
          <span className="semibold-2 gray-txt-12">{t('labels.status')}</span>
        </div>
        <div className="outcome-title-wrap">
          <span className="semibold-2 gray-txt-12">Outcome</span>
        </div>
      </div>
      <div className="data-card__content">
        {data.map(
          ({ sequenceId, sequence, status, categoryId, prospectId }) => {
            const selectedOutcomeOption = getOptionFromOutcome(
              allOutcomes,
              categoryId,
            );
            return (
              <div className="prospect-sequence">
                <div className="sequence-name">
                  <Link
                    className="regular-2 text-decoration-none blue-txt-11"
                    to={`/sequence/${sequenceId}/steps`}
                  >
                    <TruncatedTextWithTooltip
                      string={sequence}
                      length={20}
                      textClassName="regular-2"
                    />
                  </Link>
                </div>
                <div className="sequence-status-badge">
                  {status ? (
                    <ContactStatusTag
                      status={status}
                      isSequenceProspectTag={true}
                    />
                  ) : (
                    '-'
                  )}
                </div>

                <div className="">
                  <GenericCustomOutcomeDropdown
                    showFooter={false}
                    options={mapOutcomeToOption(allOutcomes)}
                    showTotal={false}
                    className="prospect-outcome-select"
                    onChange={() => {
                      showAllSequences();
                    }}
                    changeOutcomeRequestPayload={{
                      sequenceId,
                      prospectId,
                    }}
                    initialSelectedOption={selectedOutcomeOption}
                    optionRenderer={(option) => (
                      <div className="d-flex align-items-center">
                        {getOutcomeIcon(option?.name, false, {
                          isDefault: option.isDefault,
                          sentiment: option.sentiment,
                        })}
                        <span className="ml-1">{option.name}</span>
                      </div>
                    )}
                  />
                </div>
              </div>
            );
          },
        )}
      </div>
    </div>
  );
};

export default DataCard;
