export enum Stage {
  SelectFile = 'select-file',
  MapFields = 'map-fields',
  EmailVerification = 'email-verification',
}

export type StageOption = {
  label: string;
  value: Stage;
  disabled?: boolean;
};

export const stageOptions: StageOption[] = [
  {
    label: 'Upload file',
    value: Stage.SelectFile,
  },
  {
    label: 'Map Fields',
    value: Stage.MapFields,
  },
  {
    label: 'Review and Import',
    value: Stage.EmailVerification,
  },
];
