import React from 'react';
import { useHistory } from 'react-router';
import Tabs, { TabPane } from 'rc-tabs';
import classNames from 'classnames';
import { accessibleOnClick } from '../../../../shared/utils/accessible-on-click';
import * as Icons from '../../../../shared/svg';
import PeopleTabComponent from '../people-tab';
import NoSearchResult from '../no-search-result/no-search-result';
import { LeadFinderTabKey, SavedTabFilters } from '../../type';
import NoSearchResultSavedIcon from '../../../../shared/components/images/no-search-result-saved-icon';
import SavedTab from '../saved-tab/saved-tab';
import { useSavedLeadFilter } from '../hooks/useLeadSavedFilter';

interface LeadsSidebarProps {
  isSidebarExpanded: any;
  setIsSidebarExpanded: any;
  handleRecentSearch: any;
  handleGeneratePayload: any;
  activeTabKey: string;
  setActiveTabKey: (d: string) => void;
  handlePagination: (d?: any) => void;
  // eslint-disable-next-line react/require-default-props
  setLeadsData?: any;
  setSelectedLeads: (d?: any) => void;
  handleRouteChange: (d?: any) => void;
  leadTags: any[];
  isLeadTagsLoading: boolean;
  fetchLeadTags: (applyLoading?: boolean) => void;
  handleResetStateAfterAnyAction: () => void;
  savedFilters: SavedTabFilters;
  setSavedFilters: React.Dispatch<React.SetStateAction<SavedTabFilters>>;
}

const LeadsSidebar = (props: LeadsSidebarProps) => {
  const {
    isSidebarExpanded,
    setIsSidebarExpanded,
    handleRecentSearch,
    handleGeneratePayload,
    activeTabKey,
    setActiveTabKey,
    setLeadsData,
    setSelectedLeads,
    handleRouteChange,
    fetchLeadTags,
    leadTags,
    isLeadTagsLoading,
    handleResetStateAfterAnyAction,
    savedFilters,
    setSavedFilters,
  } = props;

  const history = useHistory();

  const { handleDefaultValues } = useSavedLeadFilter(activeTabKey);

  const onSidebarExpansionChange = () => {
    setIsSidebarExpanded(!isSidebarExpanded);
  };

  const handleTabChange = (key) => {
    if (key !== activeTabKey) {
      setLeadsData({
        profiles: [],
        pagination: null,
      });
      handleResetStateAfterAnyAction();
      setActiveTabKey(key);
      handleRouteChange();
      setSavedFilters(handleDefaultValues(true));
      const queryParams = new URLSearchParams();
      if (key === LeadFinderTabKey.SAVED_TAB) {
        queryParams.set('start', '1');
        queryParams.set('take', '25');
      }
      if (key === LeadFinderTabKey.PEOPLE_TAB) {
        queryParams.delete('start');
        queryParams.delete('take');
      }
      history.push({ search: queryParams.toString(), hash: key });
    }
  };

  const sideNavClassName = classNames([
    'leads-sidebar',
    'leadsSideNav',
    {
      'sidenav--collapse': !isSidebarExpanded,
    },
  ]);

  return (
    <div className={`leads-navigation ${sideNavClassName}`}>
      <div
        className="expand-btn"
        {...accessibleOnClick(onSidebarExpansionChange)}
      >
        {isSidebarExpanded ? (
          <Icons.ChevronLeft width={17} height={17} className="gray-txt-15" />
        ) : (
          <Icons.ChevronRight width={17} height={17} className="gray-txt-15" />
        )}
      </div>
      <div className="leads-tabbing">
        {isSidebarExpanded && (
          <Tabs
            defaultActiveKey={LeadFinderTabKey.PEOPLE_TAB}
            activeKey={activeTabKey}
            onChange={(key) => handleTabChange(key)}
            style={{ zIndex: 1000 }}
            prefixCls="bs-tabs"
            className="bs-tabs-small"
          >
            <TabPane tab="People" key={LeadFinderTabKey.PEOPLE_TAB}>
              <PeopleTabComponent
                {...{
                  handleRecentSearch,
                  handleGeneratePayload,
                  activeTabKey,
                  setSelectedLeads,
                }}
              />
            </TabPane>
            <TabPane tab="Saved" key={LeadFinderTabKey.SAVED_TAB}>
              <SavedTab
                {...{
                  leadTags,
                  isLeadTagsLoading,
                  fetchLeadTags,
                  activeTabKey,
                  savedFilters,
                  setSavedFilters,
                }}
              />
            </TabPane>
          </Tabs>
        )}
      </div>
    </div>
  );
};

export default LeadsSidebar;
