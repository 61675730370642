import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deselectLeads } from '../../lead-slice';
import { LeadFinderTabKey } from '../../type';
import { RootState } from '../../../../store/root-reducer';
// import { RootState } from '../../../../store/root-reducer';

export const useLeadBulkAction = (
  leadsData,
  selectedLeads,
  setSelectedLeads,
  activeTabKey,
) => {
  const dispatch = useDispatch();
  const [disabledLeads, setDisabledLeads] = useState([]);
  const [isBulkRevealing, setIsBulkRevealing] = useState(false);
  const [isSelectAllIndeterminate, setIsSelectAllIndeterminate] = useState(
    false,
  );

  const [leadCredits, setLeadsCredits] = useState(0);

  const leadCreditsState = useSelector((state: RootState) => state);

  const [deSelectedLeads, setDeSelectedLeads] = useState([]);
  const [selectedAllLeadsCount, setSelectedAllLeadsCount] = useState(0);
  const [isSelectedAllLeads, setIsSelectedAllLeads] = useState(false);

  const [topPeopleLimit, setTopPeopleLimit] = useState(0);

  useEffect(() => {
    setTopPeopleLimit(Number(leadCredits) >= 5000 ? 5000 : Number(leadCredits));
  }, [leadCredits]);

  useEffect(() => {
    const isAllSelected =
      leadsData?.profiles?.length > 0 &&
      leadsData?.profiles?.every(
        (lead) =>
          selectedLeads?.some((l) => l?.id === lead?.id) &&
          !disabledLeads?.some((dLead) => dLead?.id === lead?.id),
      );

    const isSomeSelected =
      leadsData?.profiles?.some(
        (lead) =>
          selectedLeads?.some((l) => l?.id === lead?.id) &&
          !disabledLeads?.some((dLead) => dLead?.id === lead?.id),
      ) && !isAllSelected;

    setIsSelectAllIndeterminate(isSomeSelected);
  }, [selectedLeads, disabledLeads, leadsData]);

  const getUncheckedLeads = (lead) => {
    const uncheckedLeads = lead.id;
    return uncheckedLeads;
  };

  const handleCheckUnCheckSingleLead = (lead) => {
    const isLeadAdded = selectedLeads?.find((l) => l?.id === lead?.id);
    const isLeadDisabled = disabledLeads?.some(
      (dLead) => dLead?.id === lead?.id,
    );
    let totalLeadsData = 0;

    if (!isLeadAdded && !isLeadDisabled) {
      setSelectedLeads((prevState) => [...prevState, lead]);
      totalLeadsData =
        selectedAllLeadsCount < leadsData.pagination.total
          ? selectedAllLeadsCount + 1
          : null;
    } else {
      if (isSelectedAllLeads) {
        dispatch(deselectLeads(lead.id));
      }
      setSelectedLeads((prevState) =>
        prevState.filter((l) => l?.id !== lead?.id),
      );
      var selectedLeadsData =
        leadsData.profiles.length -
        selectedLeads.filter((l) => l?.id !== lead?.id).length;
      totalLeadsData = leadsData.pagination.total - selectedLeadsData;
    }
    if (isSelectedAllLeads) {
      setSelectedAllLeadsCount(totalLeadsData);
    }
    getUncheckedLeads(lead);
  };

  const handleCheckUnCheckSingleLeadV2 = (lead) => {
    const isLeadAdded = selectedLeads?.find((l) => l?.id === lead?.id);
    const isLeadDisabled = disabledLeads?.some(
      (dLead) => dLead?.id === lead?.id,
    );

    if (!isLeadAdded && !isLeadDisabled) {
      // selecting the lead
      if (isSelectedAllLeads) {
        setDeSelectedLeads((prevState) => [
          ...prevState.filter((currLead) => currLead.id !== lead.id),
        ]);
      }
      setSelectedLeads((prevState) => [...prevState, lead]);
    } else {
      // de-selecting the lead
      if (isSelectedAllLeads) {
        setDeSelectedLeads((prevState) => [...prevState.concat([lead])]);
      }
      setSelectedLeads((prevState) =>
        prevState.filter((l) => l?.id !== lead?.id),
      );
    }
  };

  useEffect(() => {
    const MAX_BULK_ACTION_COUNT =
      activeTabKey === LeadFinderTabKey.PEOPLE_TAB ? 5000 : 25000;
    const finalCount =
      Math.min(MAX_BULK_ACTION_COUNT, leadsData?.pagination?.total) -
      deSelectedLeads?.length -
      disabledLeads?.length;

    setSelectedAllLeadsCount(finalCount);
  }, [deSelectedLeads, disabledLeads, leadsData]);

  const handleSelectAllBulkActionLead = (leadProfiles = []) => {
    if (isSelectedAllLeads) {
      return;
    }
    setIsSelectedAllLeads(true);
    setSelectedLeads([...leadProfiles]);
    setSelectedAllLeadsCount(leadsData.pagination.total);
    setDeSelectedLeads([]);
  };

  const handleClearSelectedAllBulkAction = () => {
    setIsSelectedAllLeads(false);
    setSelectedLeads([]);
    setSelectedAllLeadsCount(0);
    setDeSelectedLeads([]);
  };

  const handleResetStateAfterAnyAction = () => {
    setIsSelectedAllLeads(false);
    setSelectedLeads([]);
    setSelectedAllLeadsCount(0);
    setDeSelectedLeads([]);
  };

  const handleSelectAllLead = (leadProfiles = []) => {
    const isAllSelected = leadProfiles?.every(
      (lead) =>
        selectedLeads?.some((l) => l?.id === lead?.id) &&
        !disabledLeads?.some((dLead) => dLead?.id === lead?.id),
    );

    const leadProfileArrIds = new Set(leadProfiles?.map((item) => item?.id));
    const filteredSelectedLeadArr = selectedLeads?.filter(
      (item) =>
        !leadProfileArrIds?.has(item?.id) &&
        !disabledLeads?.some((dLead) => dLead?.id === item?.id),
    );

    if (isAllSelected) {
      setSelectedLeads(filteredSelectedLeadArr);
      if (isSelectedAllLeads) {
        setSelectedAllLeadsCount(null);
      }
      return;
    }

    const filteredLeadProfiles = leadProfiles?.filter(
      (lead) => !disabledLeads?.some((dLead) => dLead?.id === lead?.id),
    );
    setSelectedLeads(() => [
      ...filteredSelectedLeadArr,
      ...filteredLeadProfiles,
    ]);

    if (isSelectedAllLeads) {
      setSelectedAllLeadsCount(leadsData.pagination.total);
    }
  };

  useEffect(() => {
    setDisabledLeads(
      leadsData?.profiles?.filter(
        (l) => l?.isRevealed && l?.emails?.length === 0,
      ),
    );
  }, [leadsData]);

  return {
    handleCheckUnCheckSingleLead: handleCheckUnCheckSingleLeadV2,
    selectedLeads,
    handleSelectAllLead,
    setSelectedLeads,
    isBulkRevealing,
    setIsBulkRevealing,
    disabledLeads,
    setDisabledLeads,
    isSelectAllIndeterminate,
    selectedAllLeads: false,
    isSelectingAllLeads: selectedAllLeadsCount,
    setIsSelectingAllLeads: setSelectedAllLeadsCount,
    isSelectedAllLeads,
    setIsSelectedAllLeads,
    handleSelectAllBulkActionLead,
    handleClearSelectedAllBulkAction,
    deSelectedLeads,
    handleResetStateAfterAnyAction,
    leadCredits,
    setLeadsCredits,
    leadCreditsState,
    topPeopleLimit
  };
};
