import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { ChevronDown, ChevronUp, Users } from '@saleshandy/icons';
import { useTranslation } from 'react-i18next';
import { Button } from '@saleshandy/design-system';
import Transfer from '../../../../../../shared/design-system/components/transfer';
import { Placement } from '../../../../../../shared/design-system/components/overlay/enums';
import type { Option } from './types';
import Checkbox from '../../../../../../shared/design-system/components/atoms/checkbox';
import { IProps } from './tag-saved-filters-container';

const TagSavedFilters: React.FC<IProps> = ({
  leadTags,
  leadTagsIds,
  onLeadTagsIdsChange,
  className,
}) => {
  const { t } = useTranslation();

  const [selectedLeadTagsIds, setSelectedLeadTagsIds] = useState([]);
  const [show, setShow] = useState(false);

  useEffect(() => {
    setSelectedLeadTagsIds([...leadTagsIds]);
  }, [show, leadTagsIds]);

  const options =
    leadTags?.map((leadTags) => ({
      key: leadTags.id.toString(),
      id: leadTags.id,
      name: leadTags.name,
    })) || [];

  return (
    <Transfer<Option>
      show={show}
      onToggle={(value) => setShow(value)}
      className={`lead-filter-dropdown lead-filter ${className}`}
      showSearch
      searchPlaceholder="Search"
      multiSelect
      options={options}
      showOptionsSeparator={true}
      selectedOptionKey={selectedLeadTagsIds.map((key) => key.toString())}
      optionFilterProp="name"
      filterOption={(value, option) =>
        option?.name?.toLowerCase().includes(value.toLowerCase())
      }
      optionRenderer={(option) => (
        <span className="sequence-name-item">{option.name}</span>
      )}
      onChange={(selectedOptions) => {
        setSelectedLeadTagsIds(
          selectedOptions.map((option) => option.id),
        );
      }}
      placement={Placement.BottomStart}
      footer={
        <>
          <Checkbox
            checked={selectedLeadTagsIds.length === options.length}
            intermediate={selectedLeadTagsIds.length !== 0}
            label={t('labels.select_all')}
            onChange={(checked) => {
              if (checked) {
                setSelectedLeadTagsIds(
                  options.map((option) => parseInt(option.key, 10)),
                );
              } else {
                setSelectedLeadTagsIds([]);
              }
            }}
          />
          <Button
            onClick={() => {
              onLeadTagsIdsChange(selectedLeadTagsIds);
              setShow(false);
            }}
          >
            {t('labels.apply')}
          </Button>
        </>
      }
    >
      <Button
        className={`transfer-select-btn btn-white w-1 lead-tags-btn select-saved-tag`}
        variant="tertiary"
        onClick={() => setShow(!show)}
      >
        <div className="transfer-select-btn-content">
          <span>Select</span>
        </div>
        <div className="bs-select-box-icon-wrapper">
          {show ? <ChevronUp /> : <ChevronDown />}
        </div>
      </Button>
    </Transfer>
  );
};

export default withRouter(TagSavedFilters);
