/* eslint-disable import/no-cycle */
import React, { useState } from 'react';
import classNames from 'classnames';
import {
  OverlayPopover,
  Placement,
} from '../../../../../../../shared/design-system/components/overlay';
import { accessibleOnClick } from '../../../../../../../shared/utils/accessible-on-click';
import DataCard from './data-card/data-card';
import GenericCustomOutcomeDropdown from '../../../../../../settings/components/custom-outcomes/components/custom-outcome-dropdown';
import {
  getOptionFromOutcome,
  mapOutcomeToOption,
} from '../../../../../../sequence/components/sequence-single-content/components/sequence-single-contacts/components/prospect-outcome-select/utils/helper';
import { getOutcomeIcon } from '../../../../../../sequence/components/sequence-single-content/components/sequence-single-contacts/components/contact-list-content/components/helpers/step-filter-mapper';

const OutcomeCell = ({ sequences, prospectId, allOutcomes }) => {
  const categoryId = sequences?.[0]?.categoryId;
  const [isVisibleAllSequences, setIsVisibleAllSequences] = useState<boolean>(
    false,
  );

  const mappedCell = sequences?.length
    ? sequences.map((sequence) => ({
        sequenceId: sequence.id,
        sequence: sequence.title,
        status: sequence.status,
        prospectId,
        categoryId: sequence?.categoryId,
        allOutcomes,
      }))
    : [];

  const cls = classNames([
    'regular-2 blue-txt-11 sequence-name font-medium',
    {
      'underline-txt': isVisibleAllSequences,
    },
  ]);

  const onToggle = () => {
    setIsVisibleAllSequences(!isVisibleAllSequences);
  };

  const showAllSequences = () => {
    setIsVisibleAllSequences(true);
  };

  const renderOverlayPopoverSpan = (
    <OverlayPopover
      show={isVisibleAllSequences}
      onToggle={onToggle}
      className="sequence-name-popover"
      content={
        <DataCard
          data={mappedCell}
          showAllSequences={onToggle}
          allOutcomes={allOutcomes}
        />
      }
      placement={Placement.Auto}
      rootClose
    >
      <span
        role="button"
        {...accessibleOnClick(showAllSequences)}
        className={cls}
      >
        View Outcomes
      </span>
    </OverlayPopover>
  );

  const renderSpan = () => {
    const firstElement = sequences.shift();

    if (firstElement) {
      return (
        <GenericCustomOutcomeDropdown
          showFooter={false}
          options={mapOutcomeToOption(allOutcomes)}
          showTotal={false}
          className="prospect-outcome-select"
          changeOutcomeRequestPayload={{
            sequenceId: firstElement.id,
            prospectId,
          }}
          initialSelectedOption={getOptionFromOutcome(allOutcomes, categoryId)}
          optionRenderer={(option) => (
            <div className="d-flex align-items-center">
              {getOutcomeIcon(option?.name, false, {
                isDefault: option.isDefault,
                sentiment: option.sentiment,
              })}
              <span className="ml-1">{option.name}</span>
            </div>
          )}
        />
      );
    }

    return '-';
  };

  return (
    <div className="sequence-name-wrap">
      {mappedCell?.length > 1 ? renderOverlayPopoverSpan : renderSpan()}
    </div>
  );
};

export default OutcomeCell;
