import React from 'react';
import { Placement } from '../../../../shared/design-system/components/overlay';
import Select from '../../../../shared/design-system/components/select';
import { SequenceStep } from '../../types';

export type SequenceStepOption = SequenceStep & {
  key: number;
};

const AddToStepSelect = ({ stepOptions, stepKey, setStepKey }) => {
  return (
    <div className="map-fields-step map-fields-config">
      <div className="regular-2 step-label map-fields-config-label">
        Import into
      </div>

      <Select<SequenceStepOption>
        selectedOptionRenderer={([option]) => (
          <span>Step {option?.number}</span>
        )}
        optionRenderer={(option) => <span>Step {option?.number}</span>}
        options={stepOptions}
        selectedOptionKey={stepKey}
        onChange={([selectedOption]) => setStepKey(selectedOption.key)}
        placement={Placement.Top}
      />
    </div>
  );
};

export default AddToStepSelect;
