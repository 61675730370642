import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../../../../store/root-reducer';
import { getEmailVerificationPlanRequest } from '../../extra-actions';

import PurchaseCreditsModal from './purchase-credits-modal';
import { GetEmailVerificationPlanResponse } from '../../types';

const mapStateToProps = (state: RootState) => ({
  getEmailVerificationPlanRequestStatus:
    state.billingAndSubscription.getEmailVerificationPlanRequest.status,
  getEmailVerificationPlanResponse:
    state.billingAndSubscription.getEmailVerificationPlanResponse,
});

const mapDispatchToProps = {
  sendGetEmailVerificationPlanRequest: () => getEmailVerificationPlanRequest(),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & {
  show?: boolean;
  evCreditPlans?: GetEmailVerificationPlanResponse[];
  onClose: () => void;
  onBuyCredits: (stripeCardToken: string, planId: number) => void;
  isPurchaseEmailVerificationCreditsLoading?: boolean;
  remainingCredit?: number;
  sequenceName?: string;
  currentPlan: string;
};
export default connector(PurchaseCreditsModal);
