import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { RootState } from '../../../../../../store/root-reducer';
import SequenceSetting from './admin-setting-sequence';
import {
  resetAdminSettings,
  resetUpdateAdminSettings,
} from '../../admin-settings-slice';
import { updateAdminSettingsRequest } from '../../extra-actions';

const mapStateToProps = (state: RootState) => ({
  priorityAndDistributionData:
    state?.adminSettings?.adminSettingsPriority ?? [],
});

const mapDispatchToProps = {
  resetUpdateAdminSettings: () => resetUpdateAdminSettings(),
  resetAdminSettings: () => resetAdminSettings(),
  sendUpdateAdminDetailsRequest: (payload: any) =>
    updateAdminSettingsRequest(payload),
};
const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & {
  setToastMessage: (mes: string) => void;
};

export default connector(SequenceSetting);
