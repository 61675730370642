import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import SHTag from '../../../../shared/components/images/sh-tag';
import { Button } from '@saleshandy/design-system';
import Modal from '../../../../shared/design-system/components/atoms/modal';
import TagAutosuggest from '../../../../shared/design-system/components/molecules/tag-autosuggest';
import { RenderMode } from '../../../../shared/design-system/components/molecules/tag-autosuggest/tag-autosuggest';
import { Tag } from '@saleshandy/icons';
import {
  AddTagsToLeadsPayload,
  BulkActionPayload,
  BulkActionRemoveTagsPayload,
  SavedTabFilters,
} from '../../type';
import { map } from 'lodash';
import {
  addTagsToBulkLeads,
  addTagsToLeads,
  getTagsToLeads,
  removeTagsFromBulkLeads,
  removeTagsToLeads,
} from '../../helpers/leads.api';
import { redirectWithoutRefresh } from '../../../../shared/utils';
import toaster, { Theme } from '../../../../shared/toaster';
import { useSavedLeadFilter } from '../hooks/useLeadSavedFilter';
import { useHistory } from 'react-router';

const AddRemoveTagButton = ({
  handleAddTag,
  handleRemoveTag,
  disabled = false,
  show,
  leadTags,
  fetchLeadTags,
  selectedLeads,
  deselectedLeads,
  selectedAllLeads,
  leadsFilter,
  setSavedFilters,
  activeTabKey,
}: {
  handleAddTag: () => void;
  handleRemoveTag: () => void;
  isDropdownFlow?: boolean;
  show;
  disabled?: boolean;
  leadTags: any[];
  fetchLeadTags: (applyLoading: boolean) => void;
  selectedLeads: any[];
  deselectedLeads?: any[];
  selectedAllLeads?: any;
  leadsFilter: SavedTabFilters;
  setSavedFilters: React.Dispatch<React.SetStateAction<SavedTabFilters>>;
  activeTabKey: string;
}) => {
  const { t } = useTranslation();
  const [showDropdown, setShowDropdown] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [tagsToAdd, setTagsToAdd] = useState([]);
  const [tagsToRemove, setTagsToRemove] = useState([]);
  const [isRemoveMode, setIsRemoveMode] = useState(false);
  const [isApplyButtonDisabled, setIsApplyButtonDisabled] = useState(true);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const { handleDefaultValues } = useSavedLeadFilter(activeTabKey);

  const dropdownRef = useRef(null);

  const toggleDropdown = () => setShowDropdown(!showDropdown);
  const closeDropdown = () => setShowDropdown(false);
  const closeModal = () => setShowModal(false);

  const openAddTagModal = () => {
    closeDropdown();
    setModalTitle('Add Tags');
    setIsRemoveMode(false);
    setShowModal(true);
    setTagsToAdd([]);
    setIsApplyButtonDisabled(true);
  };

  const history = useHistory();

  const openRemoveTagModal = () => {
    closeDropdown();
    setModalTitle('Remove Tags');
    setIsRemoveMode(true);
    setShowModal(true);
    setTagsToRemove([]);
    setIsApplyButtonDisabled(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeDropdown();
      }
    };

    // Add event listener to detect clicks outside the dropdown
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup the event listener
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (!isRemoveMode) {
      const newTagValue = tagsToAdd.some((tag) => tag.name.length > 64);
      setIsApplyButtonDisabled(tagsToAdd.length === 0 || newTagValue);
    }
  }, [tagsToAdd, isRemoveMode]);

  const handleSubmitModal = async () => {
    const payload: AddTagsToLeadsPayload = {
      leads: map(selectedLeads, 'id'),
      tagIds: [],
      newTags: [],
    };

    try {
      setIsSubmitLoading(true);
      if (!isRemoveMode) {
        const bulkPayload: BulkActionPayload = {
          deSelectedLeadIds: map(deselectedLeads, 'id'),
          leadsFilter,
          newTags: [],
          tagIds: [],
        };
        tagsToAdd.forEach((tag) => {
          if (tag.isNew) {
            payload.newTags.push(tag.name);
            bulkPayload.newTags.push(tag.name);
          } else {
            payload.tagIds.push(tag.id);
            bulkPayload.tagIds.push(tag.id);
          }
        });

        if (selectedAllLeads) {
          await addTagsToBulkLeads(bulkPayload);
          toaster.success('Tag applied successfully', {
            theme: Theme.New,
          });
        } else {
          await addTagsToLeads(payload);
          toaster.success('Tag applied successfully', { theme: Theme.New });
        }

        await handleAddTag();
        setSavedFilters(handleDefaultValues(true));
        // redirectWithoutRefresh(history.location.pathname + history.location.search + history.location.hash);
        window.location.reload();
      } else {
        const bulkPayload: BulkActionRemoveTagsPayload = {
          deSelectedLeadIds: map(deselectedLeads, 'id'),
          leadsFilter,
          tagIds: [],
        };
        tagsToRemove.forEach((tag) => {
          payload.tagIds.push(tag);
          bulkPayload.tagIds.push(tag);
        });

        if (selectedAllLeads) {
          await removeTagsFromBulkLeads(bulkPayload);
          toaster.success('Tags removed successfully', { theme: Theme.New });
        } else {
          await removeTagsToLeads({
            tagIds: payload.tagIds,
            leads: payload.leads,
          });
          toaster.success('Tags removed successfully', { theme: Theme.New });
        }

        setSavedFilters(handleDefaultValues(true));
        await handleRemoveTag();
        // redirectWithoutRefresh(history.location.pathname + history.location.search + history.location.hash);
        window.location.reload();
      }
    } catch (error) {
      toaster.error(
        isRemoveMode ? 'Failed to remove tags' : 'Failed to add tags',
        error,
      );
    } finally {
      setIsSubmitLoading(false);
      closeModal();
    }
  };

  return (
    <>
      <div className="d-flex align-items-center pointer">
        <Button
          className="tag-button"
          onClick={toggleDropdown}
          disabled={disabled}
        >
          <Tag />
          <span>Tags</span>
        </Button>
      </div>

      {showDropdown && (
        <div className="tag-dropdown-view" ref={dropdownRef}>
          <ul className="dropdown-container">
            <li className="menu-item">
              <div
                className="d-flex px-2 export-cursor-pointer dropdown"
                onClick={openAddTagModal}
              >
                <div className="p-text-tag">
                  <p className="tag-list-font menu-text">Add Tags</p>
                </div>
              </div>
            </li>
            <li className="menu-item">
              <div
                className="d-flex px-2 export-cursor-pointer dropdown"
                onClick={openRemoveTagModal}
              >
                <div className="p-text-tag remove-tag-modal">
                  <p className="tag-list-font menu-text">Remove Tags</p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      )}

      <Modal
        show={showModal}
        className="add-remove-tag-modal"
        titleContent={
          <div className="d-flex modal-header-title">
            <SHTag />
            <div className="pl-2">{modalTitle}</div>
          </div>
        }
        onClose={closeModal}
        showCloseIcon={false}
        submitButtonText={t('labels.apply')}
        isSubmitDisabled={isApplyButtonDisabled}
        isSubmitLoading={isSubmitLoading}
        onSubmit={handleSubmitModal}
        backdrop="static"
        extraModalProps={{
          enforceFocus: false,
          'aria-labelledby': 'contained-modal-title-vcenter',
        }}
      >
        <div
          className={`overflow-hidden saved-tab-tags ${
            isRemoveMode && 'remove-tag-modal'
          }`}
        >
          <TagAutosuggest
            selectedProspects={selectedLeads}
            tags={leadTags}
            tagsToAdd={tagsToAdd}
            setTagsToAdd={setTagsToAdd}
            tagsToRemove={tagsToRemove}
            setTagsToRemove={setTagsToRemove}
            renderMode={RenderMode.Inline}
            // hideAlreadyAddedTags={isRemoveMode ? false : true}
            showAddedTags={isRemoveMode}
          />
        </div>
      </Modal>
    </>
  );
};

export default AddRemoveTagButton;
