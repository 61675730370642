import React from 'react';
import { CloudUpload } from '@saleshandy/icons';
import { PostSelectProps } from './types';
import Icon from '../../../../../../../../../../shared/design-system/components/atoms/icon/icon';
import ImageIcon from '../../../../../../../../../../shared/components/images/image-icon';
import { Images } from '../../../../../../../../../../shared/app-constants';
import { accessibleOnClick } from '../../../../../../../../../../shared/utils/accessible-on-click';

const PostSelect = ({
  fileName,
  onRemove,
  csvError: { error, message_one, message_two },
}: PostSelectProps) => (
  <div className="post-select">
    <div className="post-select-icon-wrapper">
      <ImageIcon src={Images.Sheets} />
    </div>

    <div className="post-select-filename">{fileName}</div>

    <div className="post-select-success">
      {error ? (
        <>
          <ImageIcon src={Images.AlertTriangleRed} />
          Import failed
        </>
      ) : (
        <>
          <ImageIcon src={Images.CheckGreen} />
          Successfully uploaded.
        </>
      )}
    </div>

    {error && (
      <p className="csv-error-message">
        <span>{message_one}</span>
        <span>{message_two}</span>
      </p>
    )}

    <div className="post-select-delete" {...accessibleOnClick(onRemove)}>
      {error ? (
        <>
          <CloudUpload />
          <span>Upload again</span>
        </>
      ) : (
        <>
          <Icon identifier="trash" />
          <span>Remove file</span>
        </>
      )}
    </div>
  </div>
);

export default PostSelect;
