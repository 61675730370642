import React from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { IProps } from './types';
import Button from '../../../../../shared/design-system/components/atoms/button';
import { Images } from '../../../../../shared/app-constants';
import ImageIcon from '../../../../../shared/components/images/image-icon';
import Icon from '../../../../../shared/design-system/components/atoms/icon/icon';
import { redirectWithoutRefresh } from '../../../../../shared/utils/redirect';
import { accessibleOnClick } from '../../../../../shared/utils/accessible-on-click';
import hasPermission from '../../../../../shared/utils/access-control/has-permission';
import { Permissions } from '../../../../../shared/utils/access-control/enums/permissions';
import { openChatSupport } from '../../../../../shared/utils/open-chat-support';
import { Routes } from '../../../../../shared/routes';

const ReachedEmailSendingLimitModal: React.FC<IProps> = ({ onClose }) => {
  const { t } = useTranslation();

  const handlerUpgrade = () => {
    redirectWithoutRefresh(Routes.Setting_Billing_Upgrade_Email_Outreach_Plan);
  };

  const onNeedHelp = () => {
    onClose();

    // Open chat
    openChatSupport();
  };

  return (
    <Modal
      show={true}
      backdrop="static"
      className="smtp-modal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="d-flex justify-content-between">
          <div>
            <ImageIcon src={Images.UploadCloudBlue} alt="sent-icon" />
            <span className="semibold-2 ml-3">Upgrade plan</span>
          </div>

          <div role="button" {...accessibleOnClick(onClose)}>
            <Icon identifier="close" className="semibold-4" />
          </div>
        </div>

        <div className="mt-3">
          <p className="regular-2 popover-arrow-color-txt">
            {t('messages.reached_email_sending_limit_message_one')}
          </p>
          {hasPermission(Permissions.ACCOUNT_SUBSCRIPTION_READ) ? (
            <p className="regular-2 popover-arrow-color-txt">
              {t('messages.reached_email_sending_limit_message_two')}
            </p>
          ) : (
            <p className="regular-2 popover-arrow-color-txt">
              {t('messages.reached_email_sending_limit_message_for_member')}
            </p>
          )}
        </div>
      </Modal.Body>

      {hasPermission(Permissions.ACCOUNT_SUBSCRIPTION_READ) && (
        <Modal.Footer>
          <Button variant={Button.Variant.Default} onClick={onNeedHelp}>
            {t('labels.need_help')}
          </Button>

          <Button variant={Button.Variant.Primary} onClick={handlerUpgrade}>
            {t('labels.upgrade')}
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default ReachedEmailSendingLimitModal;
