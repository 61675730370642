import React from 'react';
import ReportPage from '../report-page';
import { ReportStructure } from '../report-page/types/report-structure';
import Modal from '../modal';

type IProps = {
  onHide: () => void;
  show: boolean;
  modalTitle: React.ReactNode | string;
  reportDataStructure: ReportStructure;
  showContent?: boolean;
  infoDescription?: React.ReactNode;
  isAddSequenceStateModal?: boolean;
};

const ReportModal: React.FC<IProps> = ({
  onHide,
  show,
  modalTitle,
  reportDataStructure,
  showContent,
  infoDescription,
  isAddSequenceStateModal = false,
}) => (
  <Modal
    show={show}
    className={
      isAddSequenceStateModal
        ? 'add-sequence-report-modal unsubscribe-link-modal'
        : 'import-contacts-modal'
    }
    titleContent={modalTitle}
    onClose={onHide}
    onSubmit={onHide}
    onHide={onHide}
    showCloseIcon={!isAddSequenceStateModal}
    submitButtonText="Done"
    submitButtonClassName="report-submit-failed-width"
    hideCancelButton={true}
    extraModalProps={{
      centered: true,
      'aria-labelledby': 'contained-modal-title-vcenter',
    }}
  >
    <div className="import-contacts-content overflow-hidden report">
      <ReportPage
        structure={reportDataStructure}
        showContent={!!showContent}
        infoDescription={!!infoDescription && infoDescription}
        isAddSequenceStateModal={isAddSequenceStateModal}
      />
    </div>
  </Modal>
);

export default ReportModal;
