import React from 'react';
import classNames from 'classnames';
import { IProps, IState } from './types';
import { isEnterKey, isEscapeKey } from '../../../../../../shared/utils/event';
import { SequenceConfig } from '../../../../enums/sequence';
import {
  getTasksFiltersFromLocalStore,
  setTasksFiltersInLocalStore,
} from '../../../../../tasks/components/tasks-content/utils/helper';
import { setProspectsFiltersInLocalStore } from '../../../../../prospect/helpers/prospect-filters-resolver';
import hasPermission from '../../../../../../shared/utils/access-control/has-permission';
import { Permissions } from '../../../../../../shared/utils/access-control/enums/permissions';
import hasResource from '../../../../../../shared/utils/access-control/has-resource';
import { ResourceIdentifiers } from '../../../../../../shared/utils/access-control/enums/resource-identifiers';

class SequenceSingleTitle extends React.PureComponent<IProps, IState> {
  private readonly inputRef: React.RefObject<HTMLInputElement>;
  // Ideally, it should be 3. but for a better user experience, a higher value
  // would be preferable, because more space on right side on the input would
  // be convenience for the user to click for the first time.
  private readonly spanRef: React.RefObject<HTMLSpanElement>;

  constructor(props: IProps) {
    super(props);

    this.state = { title: props.title, isEditMode: false };

    this.inputRef = React.createRef<HTMLInputElement>();
    this.spanRef = React.createRef<HTMLSpanElement>();

    this.onSetTitle = this.onSetTitle.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onInputBlur = this.onInputBlur.bind(this);
    this.onInputKeyPress = this.onInputKeyPress.bind(this);
    this.activateEditMode = this.activateEditMode.bind(this);
    this.deactivateEditMode = this.deactivateEditMode.bind(this);
  }

  componentDidMount() {
    this.adjustInputWidth();
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: IState) {
    const { title: propsTitle } = this.props;

    if (prevProps.title !== propsTitle) {
      this.onSetTitle();
    }

    if (prevState.title !== this.state.title) {
      this.adjustInputWidth();
    }
  }

  onSetTitle() {
    const { title } = this.state;
    const { title: propsTitle } = this.props;
    if (!title) {
      this.setState({ title: propsTitle });
    }
  }

  onInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    this.setState({ title: value });
  }

  onInputKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
    if (isEnterKey(event.key)) {
      this.inputRef.current.blur();
    } else if (isEscapeKey(event.key)) {
      this.onRevert();
    }
  }

  onInputBlur() {
    this.onSave();
    this.changeFilterSequenceTitle();
  }

  onRevert() {
    const { title: previousTitle } = this.props;
    this.setState({ title: previousTitle }, () => {
      this.inputRef.current.blur();
    });
    this.deactivateEditMode();
  }

  onSave() {
    const { title: newTitle } = this.state;
    const { title: previousTitle, onChange } = this.props;
    if (newTitle.length === 0 || !newTitle.trim()) {
      // First state change.
      this.setState({ title: previousTitle });
    } else if (previousTitle !== newTitle) {
      onChange(newTitle);
    }
    // Second state change.
    // Even though this is a second state change in the same function,
    // react will batch both setState into one, hence preventing calling
    // render multiple times.
    this.deactivateEditMode();
  }

  adjustInputWidth = () => {
    const { title } = this.state;

    if (this.spanRef?.current && this.inputRef?.current) {
      this.spanRef.current.textContent = title;
      this.inputRef.current.style.width = `${
        this.spanRef.current.offsetWidth + 14
      }px`;
    }
  };

  changeFilterSequenceTitle() {
    const { sequenceId } = this.props;
    const { title: newTitle } = this.state;

    const taskFilter = getTasksFiltersFromLocalStore();
    const prospectFilter = getTasksFiltersFromLocalStore();

    if (taskFilter || prospectFilter) {
      const taskFilterSequence = taskFilter?.sequences?.map(
        ({ id, title }) => ({
          id,
          title: id === sequenceId ? newTitle : title,
        }),
      );

      const prospectFilterSequence = prospectFilter?.sequences?.map(
        ({ id, title }) => ({
          id,
          title: id === sequenceId ? newTitle : title,
        }),
      );

      const newTaskFilter = { ...taskFilter, sequences: taskFilterSequence };
      const newProspectFilter = {
        ...prospectFilter,
        sequences: prospectFilterSequence,
      };

      setTasksFiltersInLocalStore(newTaskFilter);
      setProspectsFiltersInLocalStore(newProspectFilter);
    }
  }

  activateEditMode() {
    this.setState({ isEditMode: true });
  }

  deactivateEditMode() {
    this.setState({ isEditMode: false });
  }

  render() {
    const { title, isEditMode } = this.state;

    const disabled =
      !hasPermission(Permissions.SEQUENCE_UPDATE) ||
      !hasResource(ResourceIdentifiers.SEQUENCES_RENAME);

    const inputClasses = classNames([
      'sequence-name--input',
      {
        disabled,
      },
    ]);

    return (
      <div className="sequence-name">
        <input
          className={inputClasses}
          value={title}
          onChange={this.onInputChange}
          onBlur={this.onInputBlur}
          onKeyUp={this.onInputKeyPress}
          onKeyPress={this.onInputKeyPress}
          onFocus={this.activateEditMode}
          ref={this.inputRef}
          maxLength={Number(SequenceConfig.MaxSequenceTitleLength)}
          readOnly={disabled || !isEditMode}
        />
        <span ref={this.spanRef} className="hidden-span">
          {title}
        </span>
      </div>
    );
  }
}

export default SequenceSingleTitle;
