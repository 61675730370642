/* eslint-disable @typescript-eslint/dot-notation */
import React, { ReactNode, useState } from 'react';
import { Avatar, SkeletonLoading } from '@saleshandy/design-system';
import { Send, Tag, Forbid, Trash, User } from '@saleshandy/icons';
import Tabs, { TabPane } from 'rc-tabs';
import { Dropdown } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import Icon from '../../../../../../../shared/design-system/components/atoms/icon/icon';
import ProspectSequenceListTable from './prospect-sequence-list-table';
import ProspectProfileForm from './prospect-profile-form';
import ProspectActivity from './prospect-activity';
import { RequestStatus } from '../../../../../../../shared/enums/request-status';
import ProspectTagsRow from '../../../../../../../shared/design-system/components/molecules/prospect-tags-row';
import Modal from '../../../../../../../shared/design-system/components/atoms/modal';
import { accessibleOnClick } from '../../../../../../../shared/utils/accessible-on-click';
import { SelectedProspectData } from '../../../../../../sequence/types';
import { getIsRequestPending } from '../../../../../../../shared/utils/get-request-status';
import { OverlayTooltip } from '../../../../../../../shared/design-system/components/overlay';
import TruncatedTextWithTooltip from '../../../../../../../shared/components/truncated-text-with-tooltip/truncated-text-with-tooltip';
import hasPermission from '../../../../../../../shared/utils/access-control/has-permission';
import { Permissions } from '../../../../../../../shared/utils/access-control/enums/permissions';

enum DropDownActionOptions {
  Unsubscribe = 'unsubscribe',
  Remove = 'remove',
}

export type IProps = {
  show: boolean;
  onHide: () => void;
  selectedProspectId: number;
  getSingleProspectSequencesDetailsStatus: RequestStatus;
  getSingleProspectDetailsStatus: RequestStatus;
  saveContactAttributesRequestStatus: RequestStatus;
  viewAddToSequenceModal: () => void;
  unsubscribeProspectRequest: () => void;
  removeProspectRequest: () => void;
  sendGetSingleProspectSequencesDetailsRequest: () => void;
  prospectSequenceRemoveFromSequence: (sequenceId: number) => void;
  prospectSequenceMarkAsReplied: (stepId: number) => void;
  prospectSequenceMarkAsFinished: (stepId: number) => void;
  prospectSequenceAddToStep: (sequenceId, stepId) => void;
  sendSaveContactAttributesRequest: (contactId, attributes) => void;
  selectedProspectData: SelectedProspectData;
  selectedProspectSequences: any[];
  sendGetSingleProspectDetailsRequest: (id: number) => void;
  isSingleProspectSubscribed: boolean;
  t: (x: string) => ReactNode;
  viewTagsModal: () => void;
  sendPrimaryEmailChangeInProspectRequest: (
    primaryEmailProspectInfoId: number,
    contactId: number,
  ) => void;
  sendDeleteEmailFromProspectRequest: (
    prospectInfoId: number,
    contactId: number,
  ) => void;
  sendDeletePhoneFromProspectRequest: (
    prospectInfoId: number,
    contactId: number,
  ) => void;
  deleteSecondaryEmailRequestStatus?: string;
  resetProspectDeleteEmailRequestStatus?: () => void;
  deleteSecondaryPhoneRequestStatus?: string;
  resetProspectDeletePhoneRequestStatus?: () => void;
  resetProspectPrimaryEmailChangeRequestStatus?: () => void;
  primaryEmailChangeRequestStatus?: string;
  allOutcomes: any[];
};

type DropDownAction = {
  id: number;
  key: string;
  displayName: ReactNode;
  isSVGIcon?: boolean;
  SVGIcon?: ReactNode;
};

const ProspectDetailsModal: React.FC<IProps> = ({
  show,
  onHide,
  selectedProspectId,
  selectedProspectData,
  selectedProspectSequences,
  getSingleProspectSequencesDetailsStatus,

  viewAddToSequenceModal,
  unsubscribeProspectRequest,
  removeProspectRequest,
  prospectSequenceMarkAsReplied,
  sendGetSingleProspectSequencesDetailsRequest,
  prospectSequenceRemoveFromSequence,
  prospectSequenceAddToStep,
  sendSaveContactAttributesRequest,
  sendGetSingleProspectDetailsRequest,
  getSingleProspectDetailsStatus,
  saveContactAttributesRequestStatus,
  isSingleProspectSubscribed,
  prospectSequenceMarkAsFinished,
  sendPrimaryEmailChangeInProspectRequest,
  sendDeleteEmailFromProspectRequest,
  sendDeletePhoneFromProspectRequest,
  viewTagsModal,
  deleteSecondaryEmailRequestStatus,
  resetProspectDeleteEmailRequestStatus,
  deleteSecondaryPhoneRequestStatus,
  resetProspectDeletePhoneRequestStatus,
  resetProspectPrimaryEmailChangeRequestStatus,
  primaryEmailChangeRequestStatus,
  t,
  allOutcomes,
}) => {
  const isLoading = getIsRequestPending(getSingleProspectDetailsStatus);
  const [fetchActivity, setFetchActivity] = useState(false);
  const [activeTab, setActiveTab] = useState('');

  const onActivityChange = (value: boolean) => {
    setFetchActivity(value);
  };

  const dropDownListOptions = [
    hasPermission(Permissions.SEQUENCE_PROSPECT_STATUS_UNSUBSCRIBE) && {
      id: 1,
      key: DropDownActionOptions.Unsubscribe,
      displayName: t('labels.unsubscribe'),
      isSVGIcon: true,
      SVGIcon: <Forbid className="gray-txt-15 mr-1" />,
      permission: Permissions.SEQUENCE_PROSPECT_STATUS_UNSUBSCRIBE,
    },
    hasPermission(Permissions.SEQUENCE_PROSPECT_REMOVE) && {
      id: 2,
      key: DropDownActionOptions.Remove,
      displayName: t('labels.remove_delete'),
      isSVGIcon: true,
      SVGIcon: <Trash className="gray-txt-15 mr-1" />,
      permission: Permissions.SEQUENCE_PROSPECT_REMOVE,
    },
  ];

  const onDropdownActionSelect = (actionKey: string) => {
    switch (actionKey) {
      case DropDownActionOptions.Unsubscribe:
        unsubscribeProspectRequest();
        break;

      case DropDownActionOptions.Remove:
        removeProspectRequest();
        break;
      default:
        break;
    }
  };

  const generateDropdownItemsListFromActions = (actions: DropDownAction[]) =>
    actions !== null
      ? actions.map((action) => (
          <Dropdown.Item
            onClick={() => onDropdownActionSelect(action.key)}
            key={action.id}
            bsPrefix="dropdown-item-custom"
          >
            {action.SVGIcon}
            {action.displayName && (
              <span className="gray-txt-10">{action.displayName}</span>
            )}
          </Dropdown.Item>
        ))
      : null;

  const dropDownActions = generateDropdownItemsListFromActions(
    dropDownListOptions,
  );

  const prospectAttributes = {};

  if (selectedProspectData) {
    selectedProspectData?.attributes?.forEach(
      ({ attributeValue, fieldRef }) => {
        if (
          fieldRef?.['label'].trim().toLowerCase() ===
          'First Name'.trim().toLowerCase()
        ) {
          prospectAttributes[fieldRef?.['label']] = attributeValue;
        }
        if (
          fieldRef?.['label'].trim().toLowerCase() ===
          'Last Name'.trim().toLowerCase()
        ) {
          prospectAttributes[fieldRef?.['label']] = attributeValue;
        }
        if (
          fieldRef?.['label'].trim().toLowerCase() ===
          'Email'.trim().toLowerCase()
        ) {
          prospectAttributes[fieldRef?.['label']] = attributeValue;
        }
        if (
          fieldRef?.['label'].trim().toLowerCase() ===
          'Time Zone'.trim().toLowerCase()
        ) {
          prospectAttributes[fieldRef?.['label']] = attributeValue;
        }
      },
    );

    prospectAttributes['isBlacklisted'] =
      selectedProspectData?.isBlacklisted || false;
  }

  const getFirstName = () => prospectAttributes?.['First Name'] || '';
  const getLastName = () => prospectAttributes?.['Last Name'] || '';

  const handleSaveContactAttributesSubmit = async (attributes) => {
    const res: any = await sendSaveContactAttributesRequest(
      selectedProspectId,
      attributes,
    );
    if (!res.error) {
      sendGetSingleProspectDetailsRequest(selectedProspectId);
    }
  };

  const renderAddTag = () =>
    hasPermission(Permissions.SEQUENCE_PROSPECT_UPDATE_TAGS) ? (
      <div
        {...accessibleOnClick(viewTagsModal)}
        className="prospect-add-tag regular-2"
      >
        {t('messages.add_tag')}
      </div>
    ) : (
      <></>
    );

  const renderTags = () => {
    const tags =
      (selectedProspectData &&
        selectedProspectData?.tags?.length > 0 &&
        selectedProspectData.tags) ||
      [];

    return (
      <div className="prospect-tag-container">
        <div className="prospect-tag-icon">
          <Tag width={16} height={16} className="gray-txt-12" />
        </div>
        <div className="prospect-tag-content">
          {tags && tags.length ? (
            <ProspectTagsRow
              tags={tags}
              maxTags={2}
              renderAddTag={renderAddTag}
            />
          ) : (
            renderAddTag()
          )}
        </div>
      </div>
    );
  };

  const renderAvatar = () => {
    if (getFirstName() || getLastName()) {
      return (
        <Avatar
          firstName={getFirstName()}
          lastName={getLastName()}
          size="lg"
          className="cs-avatar bs-mr-12"
        />
      );
    }

    return (
      <div className="empty-avatar bs-mr-12">
        <User width={25} height={20} className="blue-txt-11" />
      </div>
    );
  };

  return (
    <>
      <Modal
        show={show}
        className="prospect-details-modal prospect-modal"
        titleContent=""
        onClose={onHide}
        onHide={onHide}
        showCloseIcon={true}
        isCustomCloseIcon={true}
        hideFooter={true}
        extraModalProps={{
          enforceFocus: false,
          'aria-labelledby': 'contained-modal-title-vcenter',
        }}
        footerContent={false}
      >
        <div className="prospect-details-container">
          <div className="prospect-details">
            <div className="prospect-info">
              <div className="d-flex justify-content-between">
                <div className="d-flex align-items-center prospect-name">
                  {isLoading ? (
                    <SkeletonLoading width={200} height={18} />
                  ) : (
                    <>
                      {renderAvatar()}
                      <div className="d-flex flex-column">
                        <TruncatedTextWithTooltip
                          string={`${getFirstName()} ${getLastName()}`}
                          length={25}
                          textClassName="semibold-4 gray-txt-10"
                        />
                        <span className="regular-2 gray-txt-12">
                          {selectedProspectData?.email}
                        </span>
                      </div>
                    </>
                  )}
                </div>
                <div className="d-flex">
                  {hasPermission(
                    Permissions.SEQUENCE_PROSPECT_ADD_TO_SEQUENCE,
                  ) && (
                    <OverlayTooltip text="Add to Sequence">
                      <div className="icon-hover">
                        <Send
                          onClick={viewAddToSequenceModal}
                          className="gray-txt-15 pointer"
                          width={20}
                          height={20}
                        />
                      </div>
                    </OverlayTooltip>
                  )}
                  {(hasPermission(
                    Permissions.SEQUENCE_PROSPECT_STATUS_UNSUBSCRIBE,
                  ) ||
                    hasPermission(Permissions.SEQUENCE_PROSPECT_REMOVE)) && (
                    <Dropdown bsPrefix="dropdown-custom" drop="down">
                      <Dropdown.Toggle
                        bsPrefix="dropdown-toggle-custom"
                        variant="dropdown-custom"
                        id="dropdown-basic"
                      >
                        <Icon
                          identifier="more-vertical-alt"
                          className="regular-4 gray-txt-15 icon-hover"
                        />
                      </Dropdown.Toggle>
                      <Dropdown.Menu bsPrefix="dropdown-menu-custom">
                        {dropDownActions}
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                </div>
              </div>

              {prospectAttributes['Time Zone'] && (
                <div className="prospect-timezone">
                  {prospectAttributes['Time Zone']}
                </div>
              )}

              {isLoading ? (
                <SkeletonLoading width={300} height={24} />
              ) : (
                renderTags()
              )}
            </div>
          </div>
          <div className="prospect-tab-action">
            <Tabs
              defaultActiveKey="activity"
              prefixCls="bs-tabs"
              className="bs-tabs-small"
              onChange={(key) => setActiveTab(key)}
            >
              {hasPermission(Permissions.SEQUENCE_PROSPECT_ACTIVITY) && (
                <TabPane tab={t('labels.activity')} key="activity">
                  <ProspectActivity
                    prospectId={selectedProspectId}
                    fetchActivity={fetchActivity}
                    onActivityChange={onActivityChange}
                    activeTab={activeTab}
                  />
                </TabPane>
              )}
              {hasPermission(Permissions.SEQUENCE_PROSPECT_SEQUENCES) && (
                <TabPane tab={t('labels.sequences')} key="sequences">
                  <ProspectSequenceListTable
                    selectedProspectSequences={selectedProspectSequences}
                    sendSequenceMarkAsRepliedRequest={
                      prospectSequenceMarkAsReplied
                    }
                    sendRemoveFromSequenceRequest={
                      prospectSequenceRemoveFromSequence
                    }
                    sendAddToStepRequest={prospectSequenceAddToStep}
                    sendGetSingleProspectSequencesDetailsRequest={
                      sendGetSingleProspectSequencesDetailsRequest
                    }
                    sendSequenceMarkAsFinishedRequest={
                      prospectSequenceMarkAsFinished
                    }
                    getSingleProspectSequencesDetailsStatus={
                      getSingleProspectSequencesDetailsStatus
                    }
                    isSingleProspectSubscribed={isSingleProspectSubscribed}
                    allOutcomes={allOutcomes}
                  />
                </TabPane>
              )}

              {hasPermission(Permissions.SEQUENCE_PROSPECT_PROFILE) && (
                <TabPane tab={t('labels.profile')} key="contacts">
                  <ProspectProfileForm
                    onClose={onHide}
                    selectedProspectId={selectedProspectId}
                    handleSaveContactAttributesSubmit={
                      handleSaveContactAttributesSubmit
                    }
                    selectedProspectData={selectedProspectData}
                    getSingleProspectDetailsStatus={
                      getSingleProspectDetailsStatus
                    }
                    sendGetSingleProspectDetailsRequest={
                      sendGetSingleProspectDetailsRequest
                    }
                    saveContactAttributesRequestStatus={
                      saveContactAttributesRequestStatus
                    }
                    sendPrimaryEmailChangeInProspectRequest={
                      sendPrimaryEmailChangeInProspectRequest
                    }
                    sendDeleteEmailFromProspectRequest={
                      sendDeleteEmailFromProspectRequest
                    }
                    sendDeletePhoneFromProspectRequest={
                      sendDeletePhoneFromProspectRequest
                    }
                    deleteSecondaryEmailRequestStatus={
                      deleteSecondaryEmailRequestStatus
                    }
                    resetProspectDeleteEmailRequestStatus={
                      resetProspectDeleteEmailRequestStatus
                    }
                    deleteSecondaryPhoneRequestStatus={
                      deleteSecondaryPhoneRequestStatus
                    }
                    resetProspectDeletePhoneRequestStatus={
                      resetProspectDeletePhoneRequestStatus
                    }
                    resetProspectPrimaryEmailChangeRequestStatus={
                      resetProspectPrimaryEmailChangeRequestStatus
                    }
                    primaryEmailChangeRequestStatus={
                      primaryEmailChangeRequestStatus
                    }
                    onActivityChange={onActivityChange}
                  />
                </TabPane>
              )}
            </Tabs>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default withTranslation()(ProspectDetailsModal);
