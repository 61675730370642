/* eslint-disable import/no-cycle */
import { connect, ConnectedProps } from 'react-redux';
import { getPostLoadMetaRequest } from '../../../../../../../home/extra-actions';
import {
  getProspectCountRequest,
  getProspectRequest,
} from '../../../../../../../prospect/extra-actions';
import { resetProspect } from '../../../../../../../prospect/prospect-slice';
import { SortOrder } from '../../../../../../enums';
import {
  getContactStatusCountRequest,
  getSequenceContactListRequest,
} from '../../../../../../extra-actions';
import { resetContacts } from '../../../../../../sequence-slice';
import { ContactsPagination } from '../../../../../../types';
import Report from './report';
import { ReportProps } from './types';

const mapDispatchToProps = {
  sendGetPostLoadMetaRequest: () => getPostLoadMetaRequest(),
  sendGetContactStatusCountRequest: (
    sequenceId: string,
    paginationOption: ContactsPagination,
  ) => getContactStatusCountRequest({ sequenceId, ...paginationOption }),
  sendGetSequenceContactListRequest: (
    sequenceId: string,
    contactPagination: ContactsPagination,
    sortOrder: SortOrder = SortOrder.Asc,
  ) =>
    getSequenceContactListRequest({
      sequenceId,
      ...contactPagination,
      sortOrder,
    }),

  sendGetProspectListRequest: (prospectPagination: ContactsPagination) =>
    getProspectRequest({ ...prospectPagination }),
  sendGetProspectCountRequest: () => getProspectCountRequest({}),

  resetSequenceContacts: () => resetContacts(),
  resetProspects: () => resetProspect(),
};

const connector = connect(null, mapDispatchToProps);

export type SequenceSingleContactsReportProps = ConnectedProps<
  typeof connector
> &
  ReportProps & {
    isEmailVerification: boolean;
  };

export default connector(Report);
