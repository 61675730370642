/* eslint-disable import/no-cycle */
import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { Modal } from 'react-bootstrap';
import Button from '../../../../../../../../shared/design-system/components/atoms/button';
import Icon from '../../../../../../../../shared/design-system/components/atoms/icon/icon';
import { SequenceSingleContactsReportProps } from './report-container';
import { RouteParams } from '../../../../../../types';
import ReportPage from '../../../../../../../../shared/design-system/components/atoms/report-page';
import { SHModules } from '../../../../../../../../shared/enums/module';
import toaster from '../../../../../../../../shared/toaster';
import eventBus from '../../../../../../../../shared/utils/event-bus';
import { constants } from '../../../../../../../../shared/enums/constants';
import { getProspectTablePerPageSize } from '../../../../../../../prospect/helpers/helper';
import { reportConstants } from '../../../../../../../../shared/design-system/components/atoms/report-page/constants/report-constants';
import { ProductTour } from '../../../../../../../../shared/enums/product-tour';

const Report = ({
  onFinish,
  importCSVReport: { success, skipped },
  resetSequenceContacts,
  resetProspects,
  sendGetContactStatusCountRequest,
  sendGetSequenceContactListRequest,
  sendGetProspectListRequest,
  sendGetProspectCountRequest,
  fromModule,
  isEmailVerification,
  sendGetPostLoadMetaRequest,
}: SequenceSingleContactsReportProps) => {
  const { sequenceId } = useParams<RouteParams>();
  let timeOut: NodeJS.Timeout;

  const onFinishImporting = () => {
    if (isEmailVerification) {
      toaster.success(
        'Email verification can take some time, we will notify you with an email when it’s done.',
      );
    }
    onFinish();
    const getPageSize = getProspectTablePerPageSize();
    const defaultPagination = {
      pageNum: constants.DEFAULT_PAGE_NUM,
      pageSize: getPageSize,
    };
    // to reset listing after report.
    switch (fromModule) {
      case SHModules.Prospect:
        resetProspects();
        sendGetProspectListRequest(defaultPagination);
        sendGetProspectCountRequest();
        break;
      case SHModules.Sequence:
        resetSequenceContacts();
        sendGetSequenceContactListRequest(sequenceId, defaultPagination);
        sendGetContactStatusCountRequest(sequenceId, defaultPagination);
        break;
      default:
        return;
    }
    sendGetPostLoadMetaRequest();
    eventBus.dispatch(constants.MODAL_HANDLER, { isModalOpened: false });
  };

  useEffect(() => {
    if (isEmailVerification) {
      timeOut = setTimeout(() => {
        onFinishImporting();
      }, 5000);
    }
    return () => {
      if (timeOut) {
        clearTimeout(timeOut);
      }
    };
  }, []);

  const reportDataStructure = {
    heading: reportConstants.IMPORT_PROSPECT_REPORT,

    sections: [
      {
        topic: reportConstants.IMPORTED_SUCCESSFULLY,
        value: success.count,
        icon: <Icon identifier="check-o" className="lime-txt-7" />,
        link: null,
      },
      {
        topic: reportConstants.SKIPPED,
        value: skipped.count,
        icon: <Icon identifier="danger" className="gold-txt-6" />,
        link: skipped.reportFilePath ? skipped.reportFilePath : null,
      },
    ],
    reportData: null,
  };

  const infoDescription = (
    <div className="verification-section">
      <div className="verification-spinner">
        <div className="spinner-loading">
          <Icon identifier="spinner-alt" className="icon-loading" />
          <div style={{ paddingLeft: '0.7rem' }}>Verifying Emails</div>
        </div>
      </div>
      <div className="content-title">
        'The process to verify email may take sometime'
      </div>
      <div className="content-description">
        Don't worry, you can close the window, we'll automatically
        <br /> start your sequence after verifying prospects.
      </div>
    </div>
  );

  return (
    <div className="report import-contact-stage">
      <ReportPage
        structure={reportDataStructure}
        showContent={isEmailVerification && success.count > 0}
        infoDescription={infoDescription}
      />
      <div className="import-contacts-footer">
        <Modal.Footer>
          <Button
            id={ProductTour.ImportProspectReport}
            variant={Button.Variant.Primary}
            onClick={onFinishImporting}
            className="import-contacts-footer-button"
          >
            Done
          </Button>
        </Modal.Footer>
      </div>
    </div>
  );
};

export default Report;
