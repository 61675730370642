import React from 'react';
import classNames from 'classnames';
import { ChevronRight } from '@saleshandy/icons';

import { Stage, stageOptions } from '../stage';

type IProps = {
  stage: Stage;
  onStageChange: (state: Stage) => void;
};

const ImportStageTabs: React.FC<IProps> = ({ stage }) => {
  const options = stageOptions.map((option) => ({
    ...option,
    ...(option.value !== stage && { disabled: true }), // Disabled all stages except the current stage.
  }));

  return (
    <div className="import-stage-tabs--container">
      {options.map((option, index) => (
        <>
          <div
            key={option.value}
            className={classNames([
              'import-stage-tabs--tab',
              { disabled: option.disabled, selected: option.value === stage },
            ])}
          >
            <p>{option.label}</p>
          </div>
          {index < options.length - 1 && (
            <div className="import-stage-tabs--icon">
              <ChevronRight />
            </div>
          )}
        </>
      ))}
    </div>
  );
};

export default ImportStageTabs;
