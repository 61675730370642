import React from 'react';
import { IProps } from './types';
import { SequenceAction } from '../../../enums';
import { capitalize } from '../../../../../shared/utils';
import ConfirmationModal from '../../../../../shared/design-system/components/atoms/confirmation-modal';

const PauseResumeSequenceModal: React.FC<IProps> = ({
  show,
  action,
  onClose,
  onSubmit,
  isRequestPending,
}) => {
  const actionMessage = action === SequenceAction.Pause ? 'pause' : 'activate';

  const capitalizedActionMessage = capitalize(actionMessage);

  return (
    <ConfirmationModal
      show={show}
      onClose={onClose}
      onSubmit={onSubmit}
      isRequestPending={isRequestPending}
      title={`${capitalizedActionMessage} Sequence`}
      content={` Are you sure you want to ${actionMessage} the sequence?`}
      showSuccessButton={true}
      showCancelButton={true}
    />
  );
};

export default PauseResumeSequenceModal;
