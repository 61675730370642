import React from 'react';
import produce from 'immer';
import { SkeletonLoading } from '@saleshandy/design-system';

import { validate } from './validator';
import type { IState } from './types';
import type { IProps } from './agency-client-login-container';

import Input from '../../../../shared/design-system/components/input';
import Button from '../../../../shared/design-system/ui/button';

import {
  getIsRequestPending,
  initializeThirdPartyIntegrations,
  executeOnRequestStatusWithPrevStatusCheck,
  AuthHelper,
  initializeSentryIntegrations,
  getIsRequestSucceeded,
} from '../../../../shared/utils';
import { setAgencyClientRedirectURLOnLogout } from './helper';
import { isInternalUrl } from '../../../../shared/utils/is-internal-url';
import HelmetIcon from '../../../../shared/components/helmet-icon';
import { SHLogoDark } from '../../../../shared/svg';
import { defaultAgencyObj } from '../../../home/utils/helper';

class AgencyClientLogin extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      values: {
        email: '',
        password: '',
      },
      errors: {
        email: '',
        password: '',
      },
      dirty: {
        email: false,
        password: false,
      },
      showPassword: false,
    };

    this.onInputChange = this.onInputChange.bind(this);
    this.onInputBlur = this.onInputBlur.bind(this);
    this.onPasswordVisibilityChange = this.onPasswordVisibilityChange.bind(
      this,
    );
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.hasErrors = this.hasErrors.bind(this);
  }

  componentDidMount() {
    const { history } = this.props;
    const { values } = this.state;

    const { search } = history.location;
    const userEmail = new URLSearchParams(search).get('userEmail');

    if (userEmail !== '') {
      this.setState({ values: { ...values, email: userEmail } });
    }
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    const {
      loginRequestStatus,
      token,
      trackingId,
      firstName,
      lastName,
      email,
      agencyConfig,
    } = this.props;

    executeOnRequestStatusWithPrevStatusCheck({
      status: loginRequestStatus,
      prevStatus: prevProps.loginRequestStatus,
      onSuccess: () => {
        if (token) {
          AuthHelper.login({ token });

          setAgencyClientRedirectURLOnLogout();

          initializeThirdPartyIntegrations({
            trackingId,
            showChatSupport: agencyConfig?.showChatSupport,
            firstName,
            lastName,
            email,
          });
        }
        initializeSentryIntegrations(email);
      },
    });
  }

  componentWillUnmount() {
    const { hideError } = this.props;
    hideError?.();
  }

  onInputChange(value: string, e: React.ChangeEvent<HTMLInputElement>) {
    const { name } = e.target;
    this.setState(
      produce((draft) => {
        draft.values[name] = value;
        draft.dirty[name] = true;
      }),
    );
  }

  onInputBlur(e) {
    const { name } = e.target;
    this.setState(
      produce((draft) => {
        if (draft.dirty[name]) {
          draft.errors[name] = validate(name, draft.values[name]);
        }
      }),
    );
  }

  onPasswordVisibilityChange() {
    const { showPassword } = this.state;

    this.setState({ showPassword: !showPassword });
  }

  onFormSubmit(e) {
    e.preventDefault();

    const { dirty, errors, values } = this.state;
    const { sendLoginRequest } = this.props;

    const dirtyRef = { ...dirty };
    const dirtyKeys = Object.keys(dirtyRef);

    dirtyKeys.forEach((key) => {
      dirtyRef[key] = true;
    });

    const errorsRef = { ...errors };
    const errorsKeys = Object.keys(errorsRef);
    let isError = false;

    errorsKeys.forEach((key) => {
      const error = validate(key, values[key]);

      errorsRef[key] = error;
      isError = isError || !!error;
    });

    this.setState({ errors: errorsRef, dirty: dirtyRef });

    if (isError) {
      return;
    }

    const { email, password } = values;
    sendLoginRequest(email, password);
  }

  hasErrors() {
    const { errors, dirty } = this.state;

    let isError = false;

    Object.keys(errors).forEach((key) => {
      if (errors[key] !== '' || !dirty[key]) {
        isError = true;
      }
    });

    return isError;
  }

  companyNameLoader(
    agencyLoading: boolean,
    isWhitelabel: boolean,
    companyName?: string,
  ) {
    if (agencyLoading) {
      return (
        <div className="container-fluid d-flex justify-content-center align-items-center">
          <SkeletonLoading width={350} height={40} />
        </div>
      );
    }

    return (
      <span>
        {`Welcome Back to ${isWhitelabel ? companyName || '' : 'Saleshandy'} `}
      </span>
    );
  }

  clientLogoLoader(
    agencyLoading: boolean,
    isWhitelabel?: boolean,
    clientLogo?: string,
  ) {
    if (agencyLoading) {
      return (
        <div className="container-fluid d-flex justify-content-center align-items-center">
          <SkeletonLoading width={200} />
        </div>
      );
    }

    if (!isWhitelabel && !clientLogo) {
      return <SHLogoDark width={200} />;
    }

    if (isWhitelabel && clientLogo) {
      return <img src={clientLogo} alt="logo" />;
    }
  }

  render() {
    const { values, errors, showPassword } = this.state;
    const {
      loginRequestStatus,
      loginRequestError,
      loginRequestShowError,
      companyName,
      agencyConfig,
      agencyConfigRequestStatus,
      clientLogo,
    } = this.props;

    const isWhitelabel = !!agencyConfig.clientBaseUrl;

    const isLoginBtnLoading = getIsRequestPending(loginRequestStatus);
    const isLoginBtnDisabled = isLoginBtnLoading || this.hasErrors();
    const isAgencyLoading =
      getIsRequestPending(agencyConfigRequestStatus) && !isInternalUrl();

    return (
      <>
        {getIsRequestSucceeded(agencyConfigRequestStatus) && (
          <HelmetIcon icon={agencyConfig?.favicon} />
        )}
        <div className="auth-wrapper w-100 d-flex">
          <div className="auth-container agency-login">
            <div className="auth-form">
              <div className={`sh-logo ${isWhitelabel && 'd-block'}`}>
                {this.clientLogoLoader(
                  isAgencyLoading,
                  isWhitelabel,
                  clientLogo,
                )}
              </div>
              <h1 className="auth-container--title">
                {this.companyNameLoader(
                  isAgencyLoading,
                  isWhitelabel,
                  companyName,
                )}
              </h1>

              <form
                onSubmit={this.onFormSubmit}
                className="auth-container--form"
              >
                <div className="auth-form-row">
                  <div className="auth-form-input email">
                    <Input
                      name="email"
                      label="Work Email"
                      type="email"
                      placeholder="Enter your work email address"
                      value={values.email}
                      variant={errors.email && Input.Variant.Error}
                      caption={
                        errors.email ||
                        (loginRequestShowError ? loginRequestError.message : '')
                      }
                      onChange={this.onInputChange}
                      onBlur={this.onInputBlur}
                      tabIndex={0}
                      autoComplete="current-email"
                    />
                  </div>
                </div>

                <div className="auth-form-row">
                  <div className="auth-form-input password">
                    <Input
                      name="password"
                      label="Password"
                      type={showPassword ? 'text' : 'password'}
                      placeholder="Enter your password"
                      value={values.password}
                      variant={errors.password && Input.Variant.Error}
                      caption={errors.password}
                      onChange={this.onInputChange}
                      onBlur={this.onInputBlur}
                      className="auth-form-input"
                      autoComplete="current-password"
                      icons={[
                        {
                          place: Input.IconPlace.Right,
                          identifier: showPassword ? 'eye-alt' : 'eye',
                          className: 'pointer',
                          onClick: this.onPasswordVisibilityChange,
                        },
                      ]}
                    />
                  </div>
                </div>

                <Button
                  type="submit"
                  isFullWidth
                  isLoading={isLoginBtnLoading}
                  disabled={isLoginBtnDisabled}
                  loadingText="Logging in, please wait..."
                >
                  Login
                </Button>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default AgencyClientLogin;
