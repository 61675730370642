import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../store/root-reducer';
import VerifyEmailModal from './verify-email-modal';
import { resendVerificationEmailRequest } from '../../../components/auth/components/signup/extra-actions';

const mapStateToProps = (state: RootState) => ({
  resendVerificationEmailRequestStatus:
    state.signup.resendVerificationEmailRequest.status,
  resendVerificationEmailRequestError:
    state.signup.resendVerificationEmailRequest.error,

  email: state.home.email,
});

const mapDispatchToProps = {
  resendVerificationEmailRequest: (email: string) =>
    resendVerificationEmailRequest(email),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & {
  onClose: () => void;
  show: boolean;
};

export default connector(VerifyEmailModal);
