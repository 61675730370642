export enum WebhookTriggerEvent {
  EmailSent = 'email-sent',
  EmailOpened = 'email-opened',
  EmailLinkClicked = 'email-link-clicked',
  ReplyReceived = 'reply-received',
  EmailBounced = 'email-bounced',
  ProspectUnsubscribed = 'prospect-unsubscribed',
  ProspectFinished = 'prospect-finished',
  ProspectOutcomeUpdated = 'prospect-outcome-updated',
  sequencePaused = 'sequence-paused',
  EmailAccountDisconnected = 'email-account-disconnected',
}

export enum WebhookTriggerEventName {
  EmailSent = 'Email Sent',
  EmailOpened = 'Email Opened',
  EmailLinkClicked = 'Email Link Clicked',
  ReplyReceived = 'Reply Received',
  EmailBounced = 'Email Bounced',
  ProspectUnsubscribed = 'Prospect Unsubscribed',
  ProspectFinished = 'Prospect Finished',
  ProspectOutcomeUpdated = 'Prospect Outcome Updated',
  sequencePaused = 'Sequence Paused',
  EmailAccountDisconnected = 'Email Account Disconnected',
}

export enum WebhookStatus {
  Active = 'active',
  Paused = 'paused',
}

export enum WebhookActivityType {
  All = 'all',
  Success = 'success',
  Failed = 'failed',
}

export const WebhookFailedMessages = {
  400: 'Invalid request. Please check webhook configuration.',
  401: 'Access denied. Check credentials.',
  403: 'Permission denied. Verify permissions.',
  404: 'Resource not found. Check webhook URL.',
  405: 'Invalid HTTP method. Use correct method.',
  408: 'Server timed out',
  429: 'Too many requests on your server. ',
  500: 'Server error. Contact your technical team.',
  502: 'Invalid response from your server. Contact your technical team.',
  503: 'Server overloaded or under maintenance. Contact your technical team.',
  504: 'Server did not receive timely response. Contact your technical team.',
  generic: 'An unknown error occurred. Contact your technical team.',
};
