/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { useTranslation, withTranslation } from 'react-i18next';
import { Button } from '@saleshandy/design-system';
import { IProps } from './types';
import Select from '../../../../../../../../shared/design-system/components/select';
import toaster from '../../../../../../../../shared/toaster';
import {
  OverlayTooltip,
  Placement,
} from '../../../../../../../../shared/design-system/components/overlay';
import MapList from './components/map-list';
import { MapConfig } from '../../../../../../types';
import { Field } from '../../../../../../../settings/types/field';
import { getEmailField } from '../../../../../../helpers/field';
import { ConflictActionOption, conflictActionOptions } from './conflict-action';
import { ConflictAction } from '../../../../../../enums';
import { isRowDataTypeValid } from './components/map-list/utils/field-type-validator';
import AddToStepSelect from '../../../../../../shared/add-to-step-select';
import { SequenceStepOption } from '../../../../../../shared/add-to-step-select/add-to-step-select';
import CreateProspectCustomFieldModal from '../../../../../../../settings/components/custom-fields/components/generic-create-update-custom-field-modal/prospect-modal/create-prospect-custom-field-modal';
import { ProspectType } from '../../../../../../../settings/enums/prospect-type';
import { CreateUpdateModalData } from '../../../../../../../settings/components/custom-fields/types';
import { RequestStatus } from '../../../../../../../../shared/enums/request-status';
import store from '../../../../../../../../store';
import { usePrevious } from '../../../../../../../../shared/utils/hooks';
import { SHModules } from '../../../../../../../../shared/enums/module';
import InfoCircle from '../../../../../../../../shared/components/info-circle';
import hasResource from '../../../../../../../../shared/utils/access-control/has-resource';
import { ResourceIdentifiers } from '../../../../../../../../shared/utils/access-control/enums/resource-identifiers';
import { ProductTour } from '../../../../../../../../shared/enums/product-tour';

export type FieldOption = Field & {
  key: number;
};

const MapFields: React.FC<IProps> = ({
  headers,
  firstRow,
  fields,
  onFinish,
  isRequestPending,
  steps,
  sendCreateCustomFieldRequest,
  isLoading,
  sendGetContactFieldsRequest,
  createCustomFieldRequestStatus,
  createCustomFieldRequestMessage,
  createCustomFieldRequestError,
  resetCreateCustomFieldRequest,
  fromModule,
  onClose,
}) => {
  const [fieldKeys, setFieldKeys] = useState<number[]>(
    Array(headers.length).fill(null),
  );

  const fieldOptions: FieldOption[] = fields.map((field) => ({
    ...field,
    key: field.id,
  }));

  const emailFieldResponse = getEmailField(fields);
  const emailFieldId = emailFieldResponse?.id;

  const addMissingFieldsConflictActionKey = conflictActionOptions.find(
    (option) => option.value === ConflictAction.AddMissingFields,
  ).key;

  const [conflictActionKey, setConflictActionKey] = useState<number>(
    addMissingFieldsConflictActionKey,
  );

  let stepOptions: SequenceStepOption[] = [];
  let firstStepKey = 0;
  const [stepKey, setStepKey] = useState<number>(firstStepKey);

  useEffect(() => {
    setStepKey(firstStepKey);
  }, [firstStepKey]);

  if (steps.length > 0) {
    stepOptions = steps.map((step) => ({
      ...step,
      key: step.id,
    }));
    firstStepKey = stepOptions.reduce(
      (previousStep, currentStep) =>
        previousStep.number < currentStep.number ? previousStep : currentStep,
      stepOptions[0],
    ).key;
  }

  // eslint-disable-next-line consistent-return
  const onImport = useCallback(() => {
    const fieldIds = fieldKeys.map(
      (key) => fieldOptions.find((option) => option.key === key)?.id,
    );

    if (!fieldIds.includes(emailFieldId)) {
      return toaster.error('Email field must be mapped');
    }

    const mapConfig: MapConfig = {};

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < fieldIds.length; i++) {
      const field = fieldIds[i];
      if (field || field === 0) {
        mapConfig[i] = field;
      }
    }

    const conflictAction = conflictActionOptions?.find(
      (option) => option.key === conflictActionKey,
    )?.value;
    if (steps.length > 0) {
      const stepId = stepOptions?.find((option) => option.key === stepKey)?.id;
      onFinish(mapConfig, conflictAction, stepId);
    } else {
      onFinish(mapConfig, conflictAction, null);
    }
  }, [
    fieldKeys,
    fieldOptions,
    emailFieldId,
    conflictActionKey,
    stepOptions,
    stepKey,
    onFinish,
    steps,
  ]);

  const onFieldRemove = useCallback(
    (index) => {
      const keys = [...fieldKeys];
      if (firstRow?.[index]) {
        firstRow[index].isTypeValid = null;
      }
      keys[index] = null;
      setFieldKeys(keys);
    },
    [fieldKeys, setFieldKeys, firstRow],
  );

  const onFieldChange = useCallback(
    (index, key, fieldType) => {
      const keys = [...fieldKeys];
      if (firstRow?.[index]) {
        firstRow[index].isTypeValid = isRowDataTypeValid(
          fieldType,
          firstRow[index]?.value || '',
        );
      }

      keys[index] = key;
      setFieldKeys(keys);
    },
    [fieldKeys, setFieldKeys, firstRow],
  );

  const [openCustomFieldBox, setIsOpenCustomFieldBox] = useState<boolean>(
    false,
  );

  // function to open custom dialog box or close custom dialog box
  const openCustomFieldDialogBox = () => {
    if (hasResource(ResourceIdentifiers.FIELDS_CREATE)) {
      setIsOpenCustomFieldBox(!openCustomFieldBox);
    }
  };

  // api call to create new custom field
  const onCreateProspectCustomFieldSubmit = async (
    payload: CreateUpdateModalData,
  ) => {
    await sendCreateCustomFieldRequest({
      prospectType: ProspectType.Contact,
      fieldType: payload.type,
      label: payload.label,
      fallbackText: payload.fallbackText ? payload.fallbackText : '',
    });

    // api call to reacieve all the custom fields
    sendGetContactFieldsRequest();
  };

  // this function creates new custom fields if it available else it will show an error
  const createProspectCustomFieldRefresh = () => {
    if (createCustomFieldRequestStatus === RequestStatus.Succeeded) {
      toaster.success(createCustomFieldRequestMessage);
      setIsOpenCustomFieldBox(false);
    }
    if (
      createCustomFieldRequestStatus === RequestStatus.Failed &&
      createCustomFieldRequestError
    ) {
      toaster.error(createCustomFieldRequestError.message);
    }
  };
  const prevLoading = usePrevious(isLoading);
  const prevCreateCustomFieldRequestStatus = usePrevious(
    createCustomFieldRequestStatus,
  );
  useEffect(() => {
    if (isLoading !== prevLoading) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      isLoading ? store.dispatch(showLoading()) : store.dispatch(hideLoading());
    }
    if (createCustomFieldRequestStatus !== prevCreateCustomFieldRequestStatus) {
      createProspectCustomFieldRefresh();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, createCustomFieldRequestStatus]);

  // reset all the request status
  useEffect(
    () => () => {
      resetCreateCustomFieldRequest();
    },
    [],
  );
  const isCreateRequestPending =
    createCustomFieldRequestStatus === RequestStatus.Pending;

  const { t } = useTranslation();
  return (
    <div className="map-fields import-contact-stage overflow-hidden">
      <div className="map-fields-content import-contact-stage-content overflow-hidden map-list-scroll">
        <MapList
          headers={headers}
          firstRow={firstRow}
          fieldKeys={fieldKeys}
          fieldOptions={fieldOptions}
          onFieldRemove={onFieldRemove}
          onFieldChange={onFieldChange}
          setFieldKeys={setFieldKeys}
          customFieldFooter={
            <Container>
              <Row className="map-fields-config-wrapper map-list-footer-row">
                <div className="d-flex align-items-center">
                  {steps.length > 0 && fromModule === SHModules.Sequence ? (
                    <AddToStepSelect
                      setStepKey={setStepKey}
                      stepKey={stepKey}
                      stepOptions={stepOptions}
                    />
                  ) : null}
                  <div className="map-fields-conflict-action map-fields-config ">
                    <div className="regular-2 conflict-action-label map-fields-config-label  semibold">
                      {t(`After Import`)}
                    </div>
                    <OverlayTooltip
                      text="Please select the action to perform on your prospect details after importing"
                      placement={Placement.Bottom}
                      className="cell-card__tooltip tooltip-md"
                    >
                      <div className="info-circle">
                        <InfoCircle />
                      </div>
                    </OverlayTooltip>
                    <Select<ConflictActionOption>
                      selectedOptionRenderer={([option]) => (
                        <span className="semibold-10">{option?.label}</span>
                      )}
                      optionRenderer={(option) => (
                        <OverlayTooltip
                          placement={Placement.Right}
                          text={option?.text}
                          className="tooltip-md"
                        >
                          <div className="conflict-action-option semibold-10">
                            {option?.label}
                          </div>
                        </OverlayTooltip>
                      )}
                      options={conflictActionOptions}
                      onChange={([selectedOption]) => {
                        setConflictActionKey(selectedOption.key);
                      }}
                      selectedOptionKey={conflictActionKey}
                      placement={Placement.Bottom}
                      className="conflict-action-select"
                    />
                  </div>
                </div>
                {hasResource(ResourceIdentifiers.FIELDS_CREATE) && (
                  <div>
                    <Button
                      className="create-custom-field-button"
                      onClick={openCustomFieldDialogBox}
                    >
                      {t(`+ Create Custom Field`)}
                    </Button>
                    {openCustomFieldBox && (
                      <CreateProspectCustomFieldModal
                        show={openCustomFieldBox}
                        onClose={openCustomFieldDialogBox}
                        onSave={onCreateProspectCustomFieldSubmit}
                        isRequestPending={isCreateRequestPending}
                      />
                    )}
                  </div>
                )}
              </Row>
            </Container>
          }
        />
      </div>

      <div className="import-contact-stage-footer">
        <div className="import-contact-stage-footer--left" />

        <div className="import-contact-stage-footer--right">
          <Button
            className="gray-secondary-btn"
            variant="secondary"
            onClick={onClose}
            isLoading={isRequestPending}
            disabled={isRequestPending}
          >
            {t(`Cancel`)}
          </Button>
          <Button
            id={ProductTour.MapFieldsNextStep}
            onClick={onImport}
            isLoading={isRequestPending}
            disabled={isRequestPending}
          >
            {t(`Next`)}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(MapFields);
