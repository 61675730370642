export const reportConstants = {
  //Headings
  ADD_PROSPECT_HEADING: 'Adding Prospects to Steps Status',
  ADD_PROSPECT_SEQUENCE: 'Adding Prospects to Sequence Report',
  IMPORT_PROSPECT_REPORT: 'Import Prospect Report',
  ADD_PROSPECTS_TO_SEQUENCE_REPORT: 'Add Prospects to Sequence Report',

  //Sections
  FAILED_TO_MOVE: 'Failed to move',
  SUCCESSFULLY_MOVED: 'Successfully Moved',
  IMPORTED_SUCCESSFULLY: 'Imported Successfully',
  SKIPPED: 'Skipped',
};
