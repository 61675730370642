import _ from 'lodash';
import { isString } from '../../../../../../../../../../../shared/utils';
import { FieldType } from '../../../../../../../../../../settings/enums/field';

export const isRowDataTypeValid = (
  fieldType: FieldType,
  rowData: string,
): boolean => {
  if (fieldType === FieldType.Number) {
    return !_.isNaN(rowData);
  }

  if (fieldType === FieldType.Text) {
    return isString(rowData);
  }

  return true;
};
