/* eslint-disable react/no-danger */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { Bell, Checks } from '@saleshandy/icons';
import {
  Col,
  OverlayTrigger,
  Row,
  Tooltip,
  Spinner as Loader,
} from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTranslation } from 'react-i18next';
import {
  Notifications,
  NotificationType,
} from '../../../../../components/home/types';
import { getRelativeDate } from '../../../../utils/time-conversions';
import { Images } from '../../../../app-constants';
import ImageIcon from '../../../../components/images/image-icon';
import { useOutClickHook } from '../../../../utils/hooks';
import {
  getImageIcon,
  getNotificationClassName,
  getNotificationReason,
  getNotificationType,
  getRedirectUrl,
  getRegexForEmail,
  moreNotificationExist,
} from './helper/notification-helper';
import { accessibleOnClick } from '../../../../utils/accessible-on-click';
import history from '../../../../history';
import hasPermission from '../../../../utils/access-control/has-permission';
import { Permissions } from '../../../../utils/access-control/enums/permissions';
import { AnalyticsEvents } from '../../../../enums/analytics';
import Spinner from '../custom-spinner';
import { RequestStatus } from '../../../../enums/request-status';
import { getIsRequestPending } from '../../../../utils';
import { SpinnerAnimation } from '../spinner/spinner';

type IProps = {
  notifications: Notifications[];
  markAsRead: (notifiationId?: string) => void;
  notificationCount: number;
  userUnreadNotificationCount: number;
  fetchNextNotifications: () => void;
  timeZone: string;
  sendGetUserUnreadNotification: () => void;
  trackingId: string;
  isHasPermissionToCallUnreadNotification: boolean;
  resetNotificationData: () => void;
  getUserNotificationsDataRequestStatus: RequestStatus;
};
type NotificationBadgeProps = {
  userUnreadNotificationCount: number;
};
const NotificationBadge: React.FC<NotificationBadgeProps> = ({
  userUnreadNotificationCount,
}) => {
  const [bounce, setBounce] = useState(true);

  useEffect(() => {
    // Function to toggle the classname
    const toggleClassName = () => {
      setBounce((prevState) => !prevState);
    };

    // Add the classname for 0.7 seconds, then remove it
    const interval = setInterval(() => {
      toggleClassName();
      setTimeout(() => {
        toggleClassName();
      }, 700); // 700 milliseconds = 0.7 seconds
    }, 10700); // 10700 milliseconds = 10.7 seconds

    // Clean up the interval to avoid memory leaks
    return () => clearInterval(interval);
  }, []);

  return (
    <span id="NF_badge_cont" className="NF_badge_cont NF_visible">
      <span
        id="NF_badge"
        className={`NF_badge NF_visible NF_animated  ${
          bounce ? 'NF_bounce' : ''
        }`}
      >
        {userUnreadNotificationCount > 9 ? '9+' : userUnreadNotificationCount}
      </span>
    </span>
  );
};
const Notification: React.FC<IProps> = ({
  notifications,
  markAsRead,
  notificationCount,
  fetchNextNotifications,
  timeZone,
  sendGetUserUnreadNotification,
  userUnreadNotificationCount,
  trackingId,
  isHasPermissionToCallUnreadNotification,
  resetNotificationData,
  getUserNotificationsDataRequestStatus,
}) => {
  const [showNotifications, setShowNotifications] = useState(false);
  const [showUnreadNotifications, setShowUnreadNotifications] = useState(false);
  const [hasMoreNotifications, setHasMoreNotifications] = useState(false);
  const notificationsLength = notifications?.length;

  const toggleNotifications = () => {
    window.analytics?.track({
      userId: trackingId,
      event: AnalyticsEvents.NotificationClicked,
      properties: {
        current_tab_url: document.location.href,
      },
    });

    setShowNotifications(!showNotifications);
    if (!showNotifications) {
      resetNotificationData();
    }
  };

  const closeNotifications = () => setShowNotifications(false);

  const { t } = useTranslation();

  const route = history.location.pathname;

  const notificationRef = useRef(null);
  useOutClickHook(notificationRef, closeNotifications);

  const markAsReadAndDownload = async (id: string, reportUrl: string) => {
    await markAsRead(id);
    window.location.href = reportUrl;
  };
  const markAsReadAndRedirect = async (id: string, redirectUrl: string) => {
    await markAsRead(id);
    window.location.href = redirectUrl;
  };
  useEffect(() => {
    if (isHasPermissionToCallUnreadNotification) {
      sendGetUserUnreadNotification();
    }
  }, [route]);

  useEffect(() => {
    setShowUnreadNotifications(false);
    if (notificationsLength > 0) {
      const unread = notifications.some(
        (notification) => !notification.hasRead,
      );
      setShowUnreadNotifications(unread);

      setHasMoreNotifications(
        moreNotificationExist(notificationsLength, notificationCount),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications]);

  const NotificationTooltip = (popoverText) => (
    <Tooltip id="popover-basic" className="bs-tooltip-inner">
      {popoverText}
    </Tooltip>
  );
  return (
    hasPermission(Permissions.NOTIFICATION_READ) && (
      <div ref={notificationRef}>
        <OverlayTrigger
          placement="bottom"
          overlay={NotificationTooltip(t('labels.notifications'))}
        >
          <div
            className={getNotificationClassName(showNotifications)}
            {...accessibleOnClick(toggleNotifications)}
          >
            {(showUnreadNotifications || userUnreadNotificationCount) &&
            userUnreadNotificationCount > 0 ? (
              <>
                <NotificationBadge
                  userUnreadNotificationCount={userUnreadNotificationCount}
                />
              </>
            ) : (
              <></>
            )}
            <Bell height={20} width={20} className="d-flex notification" />
          </div>
        </OverlayTrigger>

        {showNotifications && (
          <div
            className="notification-view-area text-left"
            id="scrollable-notification"
          >
            <Row className="px-3 py-2 border-bottom no-margin">
              <Col xs={6} className="no-padding">
                <span className="notification-font">
                  {t('labels.notifications')}
                </span>
              </Col>
              <Col xs={6} className="text-right no-padding">
                {notificationsLength && showUnreadNotifications ? (
                  <span
                    className="notification-font mark-read"
                    {...accessibleOnClick(() => markAsRead())}
                  >
                    {t('labels.mark_all_as_read')}
                  </span>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
            <InfiniteScroll
              dataLength={notificationsLength}
              hasMore={hasMoreNotifications}
              next={fetchNextNotifications}
              loader={
                // <div className="d-flex justify-content-center align-items-center">
                //   <p className="text-secondary regular-3">Loading ...</p>
                // </div>
                <div className="d-flex justify-content-center align-items-center">
                  <Spinner />
                </div>
              }
              endMessage={
                notificationsLength ? (
                  <div className="d-flex justify-content-center align-items-center my-2">
                    <p className="notification-end-text">The End</p>
                  </div>
                ) : (
                  <span />
                )
              }
              scrollableTarget="scrollable-notification"
            >
              {notifications && notificationsLength > 0 ? (
                notifications.map((notification) => (
                  <Row
                    className="px-3 py-2 no-margin border-bottom"
                    // eslint-disable-next-line no-underscore-dangle
                    key={notification._id}
                  >
                    <Col
                      xs={1}
                      className="text-center d-flex justify-content-center"
                    >
                      <div
                        className={`${
                          (notification?.reportUrl  || notification.notificationType === NotificationType.ProspectBulkAction || 
                            notification.notificationType ===
                              NotificationType.SequenceProspectImport) &&
                          ![
                            NotificationType.LeadRevealCompleted,
                            NotificationType.LeadRevealCompletedNoEmailOrPhone,
                          ].includes(notification.notificationType)
                            ? 'notification-green'
                            : notification.notificationType ===
                                NotificationType.LeadRevealCompleted ||
                              notification.notificationType ===
                                NotificationType.LeadRevealCompletedNoEmailOrPhone
                            ? 'notification-purple'
                            : 'notification-red'
                        } d-flex bs-badge mt-2`}
                      >
                        {!notification.hasRead && (
                          <sup
                            data-show="true"
                            className={`bs-scroll-number ${
                              (notification?.reportUrl || notification.notificationType === NotificationType.ProspectBulkAction ||
                                notification.notificationType ===
                                  NotificationType.SequenceProspectImport) &&
                              ![
                                NotificationType.LeadRevealCompleted,
                                NotificationType.LeadRevealCompletedNoEmailOrPhone,
                              ].includes(notification.notificationType)
                                ? 'bs-badge-status bs-badge-status-success bs-badge-dot'
                                : notification.notificationType ===
                                    NotificationType.LeadRevealCompleted ||
                                  notification.notificationType ===
                                    NotificationType.LeadRevealCompletedNoEmailOrPhone
                                ? 'bs-badge-purple-dot'
                                : 'bs-badge-dot'
                            } custom-position-icon`}
                          />
                        )}
                        {/* <ImageIcon src={getImageIcon(notification)} /> */}

                        {(notification?.reportUrl || notification.notificationType === NotificationType.ProspectBulkAction ||
                          notification.notificationType ===
                            NotificationType.SequenceProspectImport) &&
                        ![
                          NotificationType.LeadRevealCompleted,
                          NotificationType.LeadRevealCompletedNoEmailOrPhone,
                        ].includes(notification.notificationType) ? (
                          <Checks
                            width={20}
                            height={20}
                            className="green-txt-14"
                          />
                        ) : (
                          <ImageIcon src={getImageIcon(notification)} />
                        )}
                      </div>
                    </Col>
                    <Col xs={9}>
                      <p className="notification-font my-1 mt-2">
                        {t(`labels.${getNotificationReason(notification)}`)}
                      </p>
                      <p
                        className="notification-content"
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                          __html: notification.log.replace(
                            getRegexForEmail(notification),
                            '<span>$1</span>',
                          ),
                        }}
                      />
                      <p className="date-time-content">
                        {
                          getRelativeDate(
                            notification.timestamp,
                            timeZone,
                            true,
                          ).value
                        }
                      </p>
                    </Col>
                    <Col xs={2} className="text-right no-padding">
                      {notification?.reportUrl &&
                      ![
                        NotificationType.LeadRevealCompleted,
                        NotificationType.LeadRevealCompletedNoEmailOrPhone,
                      ].includes(notification.notificationType) ? (
                        <a
                          className="action-button mt-2"
                          download
                          target="_blank"
                          rel="noreferrer"
                          {...accessibleOnClick(() =>
                            markAsReadAndDownload(
                              // eslint-disable-next-line no-underscore-dangle
                              notification._id,
                              notification?.reportUrl,
                            ),
                          )}
                        >
                          {t(`labels.${getNotificationType(notification)}`)}
                        </a>
                      ) :  notification.notificationType !== NotificationType.ProspectBulkAction && (
                        <a
                          className="action-button mt-2"
                          {...accessibleOnClick(() =>
                            markAsReadAndRedirect(
                              // eslint-disable-next-line no-underscore-dangle
                              notification._id,
                              getRedirectUrl(notification),
                            ),
                          )}
                        >
                          {getNotificationType(notification)
                            ? t(`labels.${getNotificationType(notification)}`)
                            : ''}
                        </a>
                      )}
                    </Col>
                  </Row>
                ))
              ) : (
                <>
                  {getIsRequestPending(
                    getUserNotificationsDataRequestStatus,
                  ) ? (
                    <div className="d-flex justify-content-center align-items-center NF_loder">
                      <Loader
                        animation={SpinnerAnimation.Border}
                        className="spinner--blue"
                      />
                    </div>
                  ) : (
                    <div className="d-flex justify-content-center align-items-center no-notifications">
                      <div>
                        <ImageIcon src={Images.NoNotifications} />
                        <p className="no-notification-text mt-3">
                          {t('labels.noNotifications')}
                        </p>
                      </div>
                    </div>
                  )}
                </>
              )}
            </InfiniteScroll>
          </div>
        )}
      </div>
    )
  );
};

export default Notification;