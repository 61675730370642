/* eslint-disable @typescript-eslint/naming-convention */
import { connect, ConnectedProps } from 'react-redux';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { RootState } from '../../../../../../../../store/root-reducer';
import {
  ProspectCountParams,
  ProspectParams,
} from '../../../../../../../prospect/types';
import {
  addToStepModalRequest,
  getProspectCountRequestModal,
  getProspectRequestModal,
  getSequenceStepsRequest,
} from '../../../../../../extra-actions';
import { resetProspectsForModal } from '../../../../../../sequence-slice';
import { AddToStepParams } from '../../../../../../types';
import SearchAndAddModalWrapper from './search-and-add-modal';

export type SelectContact = {
  status: boolean;
  id: number;
};

export type updateProspectListType = {
  sendGetProspectRequest?: boolean;
  sendGetProspectCountRequest?: boolean;
};

const mapStateToProps = (state: RootState) => ({
  getProspectRequestStatus: state.sequence.getProspectRequest.status,
  getProspectRequestError: state.sequence.getProspectRequest.error,
  getProspectCountRequestStatus: state.sequence.getProspectCountRequest.status,
  getProspectCountRequestError: state.sequence.getProspectCountRequest.error,
  addToStepRequestStatus: state.sequence.addToStepRequest.status,
  addToStepRequestError: state.sequence.addToStepRequest.error,
  addToStepRequestMessage: state.sequence.addToStepRequest.message,
  addToStepRequestFailedCount:
    state.sequence.addToSequenceResultFromModalCount.failed,
  addToStepRequestSuccessfulCount:
    state.sequence.addToSequenceResultFromModalCount.successful,
  addToStepRequestResultData:
    state.sequence.addToSequenceResultFromModalCount.resultData,

  prospectCount: state.sequence.prospectCount,
  prospects: state.sequence.prospects,
  stepList: state.sequence.steps || [],
  remainingProspectLimit: parseInt(
    state.home.getPostLoadMetaResponse?.accountUsageQuotaRemaining?.[
      'PROSPECT.ADD'
    ],
    10,
  ),
});

const mapDispatchToProps = {
  sendGetProspectRequest: (params: ProspectParams) =>
    getProspectRequestModal(params),
  resetProspect: () => resetProspectsForModal(),
  showLoading: () => showLoading(),
  hideLoading: () => hideLoading(),
  sendGetProspectCountRequest: (params: ProspectCountParams) =>
    getProspectCountRequestModal(params),
  sendAddContactToStepRequest: (payload: AddToStepParams) =>
    addToStepModalRequest(payload),
  sendGetSequenceStepsRequest: (sequenceId: number) =>
    getSequenceStepsRequest(sequenceId),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & {
  showSearchAndAddModal: boolean;
  closeModal: () => void;
  onSubmit: ({ successful, failed, resultData }) => void;
  showRestrictionErrorModal: () => void;
};

export default connector(SearchAndAddModalWrapper);
