import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { IPropsComponent } from './types';
import { RootState } from '../../../../../../store/root-reducer';
import TagSavedFilter from './tag-saved-filter';
import { getTagsToLeadsRequest } from '../../../../extra-actions';

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = {
  sendGetLeadTagsRequest: () => getTagsToLeadsRequest(),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> &
  RouteComponentProps<any> &
  IPropsComponent;

export default connector(TagSavedFilter);
