/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { ReactNode } from 'react';
import { ReportStructure } from './types/report-structure';
import { reportConstants } from './constants/report-constants';

type IProps = {
  structure: ReportStructure;
  showContent?: boolean;
  infoTitle?: string;
  infoContent?: string;
  infoDescription?: ReactNode;
  isAddSequenceStateModal?: boolean;
};

const ReportPage: React.FC<IProps> = ({
  structure,
  showContent,
  infoDescription,
  isAddSequenceStateModal = false,
}) => {
  const { heading, sections, reportData } = structure;

  const isDownloadTxtShow = () =>
    sections.some(({ value, link }) => link && value > 0);

  const handleDownloadErrorReport = () => {
    const failedReport = reportData.failedResultData.failedReport;

    if (failedReport && failedReport.length > 0) {
      const csvContent = `Email,Failed reason\n${failedReport
        .map((item) => `"${item.email}","${item.reason}"`)
        .join('\n')}`;

      const blob = new Blob([csvContent], { type: 'text/csv' });
      const url = URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = 'sh_seq_import_err_report.csv';
      a.click();

      // Release the URL resource
      URL.revokeObjectURL(url);
    }
  };

  if (isAddSequenceStateModal) {
    return (
      <div className="action-state-data">
        {sections.map(({ topic, value, icon, link }) => {
          const shouldShowDownload =
            topic === reportConstants.FAILED_TO_MOVE && value > 0;
          return (
            <div key={topic} className=" action-state-data__row">
              <div className="d-flex justify-content-between ">
                <span className="status semibold-2 font-medium gray-txt-8">
                  {topic}
                  {/* {reportData.payload.message} */}
                </span>
                <div className="d-flex align-items-center">
                  <span className="count semibold-2 font-medium gray-txt-9">
                    {value}
                  </span>
                  <span
                    className={`icon-wrapper ${
                      isDownloadTxtShow() && value === 0 && 'right-space'
                    } ${link && value > 0 && 'margin-space'}`}
                  >
                    {icon}
                  </span>
                  <span className="download-link-wrapper regular-2">
                    {link && value > 0 && (
                      <a href={link} download>
                        Download
                      </a>
                    )}
                  </span>
                </div>
              </div>
              {shouldShowDownload && (
                <div
                  className="failed-report-download-search-add blue-txt-11"
                  onClick={handleDownloadErrorReport}
                >
                  Download Error Report
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <div
      className={`${
        heading === reportConstants.ADD_PROSPECT_HEADING ? 'mt-3' : ''
      }`}
    >
      <div className="report-content import-contact-stage-content d-flex justify-content-center ">
        <div className="report-data-wrapper report-table-data">
          <table className="report-data report-table-inner">
            <thead>
              <tr>
                <th
                  colSpan={3}
                  className="table-header  gray-txt-7 text-center report-header-failed regular-2"
                >
                  {heading}
                </th>
                <th />
              </tr>
              <tr className="head-body-spacer" />
            </thead>
            <tbody className="action-state-data">
              {sections.map(({ topic, value, icon, link }) => (
                <tr
                  key={topic}
                  className="action-state-data__row d-flex justify-content-between"
                >
                  <td className="status semibold-2 gray-txt-8">{topic}</td>
                  <td className=" semibold-2 gray-txt-9">
                    <div className="tox-dialog__body">
                      <span className="count semibold-2 font-medium gray-txt-9">
                        {value}
                      </span>

                      <span className="icon-wrapper">{icon}</span>
                    </div>

                    {link && value > 0 ? (
                      <a
                        href={link}
                        download
                        className="failed-report-download-search-add blue-txt-11"
                      >
                        Download Error Report
                      </a>
                    ) : (
                      topic === reportConstants.FAILED_TO_MOVE &&
                      value > 0 && (
                        <div
                          className="failed-report-download-search-add blue-txt-11"
                          onClick={handleDownloadErrorReport}
                        >
                          Download Error Report
                        </div>
                      )
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {showContent && infoDescription}
      </div>
    </div>
  );
};

export default ReportPage;
