import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import HeaderBanner from '../header-banner';
import AdminSettingToggle from './components/admin-setting-toggle';
import Spinner from '../../../../shared/design-system/components/atoms/spinner';
import toaster, { Theme } from '../../../../shared/toaster';
import { showGeneralErrorNotification } from '../../../../shared/utils/errors';
import {
  executeOnRequestStatus,
  executeOnErrorWithErrorCheck,
} from '../../../../shared/utils/execute-on-request-status';
import { SubscriptionPlans } from '../../../../shared/utils/subscription-plans';
import { ShAccountSettingsCode } from '../../enums/admin-settings';
import {
  getAdminSettingsValues,
  getEmailVerificationModalContent,
  getProspectDuplicationAllowedModalContent,
  getToggleOnOrOffMessage,
  getProspectSharingModalContent,
  getProspectSharingOnOffMessage,
  getAutoAddToProspectOnLeadRevealOnOffMessage,
  getAutoAddToProspectOnLeadRevealModalContent,
} from './utils/helper';
import { IProps } from './types';
import { getIsRequestPending } from '../../../../shared/utils/get-request-status';
import { SpinnerAnimation } from '../../../../shared/design-system/components/atoms/spinner/spinner';
import ConfirmationModalV3 from '../../../../shared/design-system/components/atoms/confirmation-modal/confirmation-modal-v3';
import { ConfirmationModalIconType } from '../../../../shared/design-system/components/atoms/confirmation-modal/enum';
import { GlobalSettings } from '../../../../shared/components/global-settings-wrapper';
import SequenceSetting from './components/admin-setting-sequence';
import AdminSettingSection from './components/admin-setting-section';
import { supportUrls } from '../../../../shared/utils/urls';

const AdminSettings: React.FC<IProps> = ({
  adminSettings,
  resetAdminSettings,

  getAdminSettingsRequestStatus,
  getAdminSettingsRequestError,
  sendGetAdminDetailsRequest,

  updateAdminSettingsStatus,
  updateAdminSettingsMessage,
  updateAdminSettingsError,
  sendUpdateAdminDetailsRequest,
  resetUpdateAdminSettings,

  integrationAdminSetting,
  getIntegrationAdminSettingRequestStatus,
  changeIntegrationSettingRequestStatus,
  getIntegrationSetting,
  sendChangeIntegrationSetting,

  subscriptionPlan,
  agencyConfig,
}) => {
  const { t } = useTranslation();

  const [isInitialRequest, setIsInitialRequest] = useState(true);
  const [emailVerificationModal, setEmailVerificationModal] = useState(false);
  const [toastMessage, setToastMessage] = useState<string>('');
  const [prospectDuplicationModal, setProspectDuplicationModal] = useState(
    false,
  );
  const [
    isProspectSharingConfirmationModalVisible,
    setIsProspectSharingConfirmationModalVisible,
  ] = useState(false);
  const [
    autoAddToProspectOnLeadRevealConfirmationModalVisible,
    setAutoAddToProspectOnLeadRevealConfirmationModalVisible,
  ] = useState(false);
  const [integrationSetting, setIntegrationSetting] = useState(
    integrationAdminSetting,
  );

  const onHideEmailVerificationModal = () => {
    setEmailVerificationModal(false);
  };

  const onHideProspectDuplicationModal = () => {
    setProspectDuplicationModal(false);
  };

  const onHideProspectSharingModal = () => {
    setIsProspectSharingConfirmationModalVisible(false);
  };

  const onToggle = (code: ShAccountSettingsCode) => {
    if (code === ShAccountSettingsCode.IsVerificationActive) {
      setEmailVerificationModal(true);
    }
    if (code === ShAccountSettingsCode.ProspectDuplicationAllowed) {
      setProspectDuplicationModal(true);
    }
    if (code === ShAccountSettingsCode.ProspectSharing) {
      setIsProspectSharingConfirmationModalVisible(true);
    }
    if (code === ShAccountSettingsCode.AutoAddToProspectOnLeadReveal) {
      setAutoAddToProspectOnLeadRevealConfirmationModalVisible(true);
    }
    if (code === ShAccountSettingsCode.IntegrationSetting) {
      sendChangeIntegrationSetting(integrationSetting === 1);
    }
  };

  const onSettingToggle = (code: ShAccountSettingsCode, value: boolean) => {
    const settings = [
      {
        code,
        value: value ? '1' : '0',
      },
    ];

    if (code === ShAccountSettingsCode.TrackExternalMails && !value) {
      settings.push({
        code: ShAccountSettingsCode.AddProspectFromBusinessDomain,
        value: '0',
      });
    }

    sendUpdateAdminDetailsRequest({
      settings,
    });
  };

  useEffect(() => {
    sendGetAdminDetailsRequest();
    getIntegrationSetting();
  }, []);

  useEffect(() => {
    executeOnRequestStatus({
      status: getAdminSettingsRequestStatus,
      onSuccess: () => {
        setIsInitialRequest(false);
      },
      onFailed: () => {
        executeOnErrorWithErrorCheck({
          error: getAdminSettingsRequestError,
          onError: () => {
            showGeneralErrorNotification(getAdminSettingsRequestError.message);
          },
        });
      },
    });
  }, [getAdminSettingsRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: updateAdminSettingsStatus,
      onSuccess: () => {
        toaster.success(toastMessage || updateAdminSettingsMessage, {
          theme: Theme.New,
        });
        setToastMessage('');
        sendGetAdminDetailsRequest();
        resetUpdateAdminSettings();
        emailVerificationModal && setEmailVerificationModal(false);
        prospectDuplicationModal && setProspectDuplicationModal(false);
        isProspectSharingConfirmationModalVisible &&
          setIsProspectSharingConfirmationModalVisible(false);
        autoAddToProspectOnLeadRevealConfirmationModalVisible &&
          setAutoAddToProspectOnLeadRevealConfirmationModalVisible(false);
      },
      onFailed: () => {
        executeOnErrorWithErrorCheck({
          error: updateAdminSettingsError,
          onError: () => {
            showGeneralErrorNotification(updateAdminSettingsError.message);
            resetUpdateAdminSettings();
          },
        });
      },
    });
  }, [updateAdminSettingsStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: getIntegrationAdminSettingRequestStatus,
      onSuccess: () => {
        setIntegrationSetting(integrationAdminSetting);
      },
    });
  }, [getIntegrationAdminSettingRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: changeIntegrationSettingRequestStatus,
      onSuccess: () => {
        getIntegrationSetting();
      },
    });
  }, [changeIntegrationSettingRequestStatus]);

  useEffect(
    () => () => {
      resetAdminSettings();
    },
    [],
  );

  const {
    isVerificationActive,
    prospectDuplicationAllowed,
    prospectSharing,
    AutoAddToProspectOnLeadReveal,
    isAICategorization,
    trackUniboxOpen,
    trackUniboxClick,
    isAddProspectFromBusinessDomain,
    trackExternalMails,
  } = getAdminSettingsValues(adminSettings);

  return (
    <>
      <GlobalSettings.Header>
        <GlobalSettings.HeaderLeft>
          <GlobalSettings.HeaderTitle title="Admin Settings" />
        </GlobalSettings.HeaderLeft>
      </GlobalSettings.Header>
      {subscriptionPlan === SubscriptionPlans.FREE && <HeaderBanner />}
      <GlobalSettings.Content className="admin-settings">
        {getIsRequestPending(getAdminSettingsRequestStatus) &&
        isInitialRequest ? (
          <div className="admin-settings--spinner">
            <Spinner animation={SpinnerAnimation.Border} />
          </div>
        ) : (
          <div className="admin-settings--content">
            <AdminSettingSection title="Sequence">
              <SequenceSetting setToastMessage={setToastMessage} />
            </AdminSettingSection>
            <AdminSettingSection title="Unified Inbox">
              <AdminSettingToggle
                checked={isAICategorization}
                onChange={() =>
                  onSettingToggle(
                    ShAccountSettingsCode.AICategorization,
                    !isAICategorization,
                  )
                }
                code={ShAccountSettingsCode.AICategorization}
                label="AI Categorization in Unified Inbox : Assign outcome to conversation based on reply content"
              />
              <AdminSettingToggle
                checked={trackUniboxOpen}
                onChange={() =>
                  onSettingToggle(
                    ShAccountSettingsCode.TrackUniboxOpen,
                    !trackUniboxOpen,
                  )
                }
                code={ShAccountSettingsCode.TrackUniboxOpen}
                label="Track opens for emails sent from unified inbox"
              />
              <AdminSettingToggle
                checked={trackUniboxClick}
                onChange={() =>
                  onSettingToggle(
                    ShAccountSettingsCode.TrackUniboxClick,
                    !trackUniboxClick,
                  )
                }
                code={ShAccountSettingsCode.TrackUniboxClick}
                label="Track clicks for emails sent from unified inbox"
              />
              <AdminSettingToggle
                checked={trackExternalMails}
                onChange={() =>
                  onSettingToggle(
                    ShAccountSettingsCode.TrackExternalMails,
                    !trackExternalMails,
                  )
                }
                code={ShAccountSettingsCode.TrackExternalMails}
                label="Track external replies"
                learnMoreLink={supportUrls.trackExternalEmailsBlog}
              />
              <AdminSettingToggle
                checked={isAddProspectFromBusinessDomain}
                onChange={() => {
                  if (trackExternalMails) {
                    onSettingToggle(
                      ShAccountSettingsCode.AddProspectFromBusinessDomain,
                      !isAddProspectFromBusinessDomain,
                    );
                  }
                }}
                code={ShAccountSettingsCode.AddProspectFromBusinessDomain}
                label="Track all replies from recipients with the same domain as your prospects"
              />
            </AdminSettingSection>

            <AdminSettingSection title="Lead Finder">
              <AdminSettingToggle
                checked={AutoAddToProspectOnLeadReveal}
                onChange={onToggle}
                code={ShAccountSettingsCode.AutoAddToProspectOnLeadReveal}
                label="Save revealed leads from Lead Finder as prospects automatically"
              />
            </AdminSettingSection>

            <AdminSettingSection title="Prospects">
              <AdminSettingToggle
                checked={isVerificationActive}
                onChange={onToggle}
                code={ShAccountSettingsCode.IsVerificationActive}
                label="Allow verifying prospects while importing"
              />
              <AdminSettingToggle
                checked={prospectDuplicationAllowed}
                onChange={onToggle}
                code={ShAccountSettingsCode.ProspectDuplicationAllowed}
                label="Allow adding one prospect in multiple sequences"
              />
            </AdminSettingSection>

            {agencyConfig?.agencyId === undefined && (
              <AdminSettingSection title="Team Management">
                <AdminSettingToggle
                  checked={prospectSharing}
                  onChange={onToggle}
                  code={ShAccountSettingsCode.ProspectSharing}
                  label="Allow team members to see prospects of each other"
                />
              </AdminSettingSection>
            )}

            <AdminSettingSection title="Integration and Webhooks">
              <AdminSettingToggle
                checked={integrationSetting === 2}
                onChange={onToggle}
                code={ShAccountSettingsCode.IntegrationSetting}
                label="Send Integration/Webhook events for entire account(Including user's account)"
              />
            </AdminSettingSection>
          </div>
        )}
      </GlobalSettings.Content>

      <ConfirmationModalV3
        show={emailVerificationModal}
        title={getToggleOnOrOffMessage(isVerificationActive, t)}
        iconType={ConfirmationModalIconType.INFO_CIRCLE_YELLOW}
        content={getEmailVerificationModalContent(isVerificationActive, t)}
        onClose={onHideEmailVerificationModal}
        onSubmit={() =>
          onSettingToggle(
            ShAccountSettingsCode.IsVerificationActive,
            !isVerificationActive,
          )
        }
        isSubmitLoading={getIsRequestPending(updateAdminSettingsStatus)}
        isSubmitDisabled={getIsRequestPending(updateAdminSettingsStatus)}
        submitButtonText={t('labels.yes')}
        cancelButtonText={t('labels.cancel')}
      />

      <ConfirmationModalV3
        show={prospectDuplicationModal}
        title={getToggleOnOrOffMessage(prospectDuplicationAllowed, t)}
        iconType={ConfirmationModalIconType.INFO_CIRCLE_YELLOW}
        contents={getProspectDuplicationAllowedModalContent(
          prospectDuplicationAllowed,
          t,
        )}
        onClose={onHideProspectDuplicationModal}
        onSubmit={() =>
          onSettingToggle(
            ShAccountSettingsCode.ProspectDuplicationAllowed,
            !prospectDuplicationAllowed,
          )
        }
        isSubmitLoading={getIsRequestPending(updateAdminSettingsStatus)}
        isSubmitDisabled={getIsRequestPending(updateAdminSettingsStatus)}
        submitButtonText={t('labels.yes')}
        cancelButtonText={t('labels.cancel')}
      />

      <ConfirmationModalV3
        show={isProspectSharingConfirmationModalVisible}
        title={getProspectSharingOnOffMessage(prospectSharing, t)}
        iconType={ConfirmationModalIconType.INFO_CIRCLE_YELLOW}
        contents={getProspectSharingModalContent(prospectSharing)}
        onClose={onHideProspectSharingModal}
        onSubmit={() =>
          onSettingToggle(
            ShAccountSettingsCode.ProspectSharing,
            !prospectSharing,
          )
        }
        isSubmitLoading={getIsRequestPending(updateAdminSettingsStatus)}
        isSubmitDisabled={getIsRequestPending(updateAdminSettingsStatus)}
        submitButtonText={t('labels.yes')}
        cancelButtonText={t('labels.cancel')}
      />

      <ConfirmationModalV3
        show={autoAddToProspectOnLeadRevealConfirmationModalVisible}
        title={getAutoAddToProspectOnLeadRevealOnOffMessage(
          AutoAddToProspectOnLeadReveal,
          t,
        )}
        iconType={ConfirmationModalIconType.INFO_CIRCLE_YELLOW}
        contents={getAutoAddToProspectOnLeadRevealModalContent(
          AutoAddToProspectOnLeadReveal,
          t,
        )}
        onClose={() => {
          setAutoAddToProspectOnLeadRevealConfirmationModalVisible(false);
        }}
        onSubmit={() =>
          onSettingToggle(
            ShAccountSettingsCode.AutoAddToProspectOnLeadReveal,
            !AutoAddToProspectOnLeadReveal,
          )
        }
        isSubmitLoading={getIsRequestPending(updateAdminSettingsStatus)}
        isSubmitDisabled={getIsRequestPending(updateAdminSettingsStatus)}
        submitButtonText={t('labels.yes')}
        cancelButtonText={t('labels.cancel')}
      />
    </>
  );
};

export default AdminSettings;
