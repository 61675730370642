import { EmailInboxFilters } from '../../type';
import { UnifiedInboxFeed } from '../../enum/unified-inbox.enum';
import { ConversationType } from '../../../prospect/enums/prospect';

export const getDefaultFilters = (): EmailInboxFilters => ({
  page: 1,
  limit: 50, // Changed from 10 to 50 limit for new changes

  feed: UnifiedInboxFeed.All,
  search: '',
  conversationType: ConversationType.All,

  outcomes: null,
  owners: [],
  sequences: [],
  emailAccounts: [],
  clients: [],
  date: null,
});
