export enum AgencyClientStatus {
  Active = 'active',
  InActive = 'in-active',
  Deleted = 'deleted',
}

export enum AgencyResourceType {
  Sequence = 'sequence',
  EmailAccount = 'emailAccount',
}

export enum AgencyClientSortBy {
  firstName = 'firstName',
  CompanyName = 'companyName',
  ActiveSequence = 'activeSequence',
  ActiveEmailAccounts = 'activeEmailAccounts',
  TotalProspects = 'totalProspects',
  TotalEmailSent = 'emailSent',
  CreatedDate = 'createdDate',
}

export enum AgencyClientPermissions {
  FullAccess = 'full-access',
  LimitedAccess = 'limited-access',
  NoAccess = 'no-access',
}
