import React, { memo } from 'react';

import Checkbox from '../../atoms/checkbox';
import EmailVerificationError from '../../atoms/email-verification-error';

import { Images } from '../../../../app-constants';
import { accessibleOnClick } from '../../../../utils/accessible-on-click';
import { supportUrls } from '../../../../utils/urls';

type IProps = {
  availableCredits: number;
  requiredCredits: number;
  showVerifyProspectCheckbox?: boolean;
  verifyProspect?: boolean;
  setVerifyProspect?: (value: boolean) => void;
};

const ImportEmailVerification: React.FC<IProps> = ({
  availableCredits,
  requiredCredits,
  showVerifyProspectCheckbox = false,
  verifyProspect,
  setVerifyProspect,
}) => (
  <div className="review-section-container mt-2">
    <div className="review-section-title-container">
      <img src={Images.Shield} alt="Shield" />
      <span className="review-section-title">Email verification</span>
    </div>

    {showVerifyProspectCheckbox && (
      <div className="verify-prospect-checkbox">
        <Checkbox
          id="verify-prospect"
          checked={verifyProspect}
          onChange={(checked) => setVerifyProspect(checked)}
        />
        <span
          {...accessibleOnClick(() => {
            setVerifyProspect(!verifyProspect);
          })}
        >
          Start Email verification after Import
        </span>
      </div>
    )}

    <div className="email-verification-desc-container">
      <p>
        <span>
          To ensure high deliverability and avoid spam, verify prospect emails
          before sending emails. Unverified emails can harm your sender
          reputation, increase bounce rate, and your emails may end up in spam.
        </span>
        <a
          href={supportUrls.emailVerificationBlog}
          target="_blank"
          rel="noreferrer"
          className="ml-1"
        >
          Learn more
        </a>
      </p>
    </div>

    {verifyProspect && requiredCredits > availableCredits && (
      <EmailVerificationError
        availableCredits={availableCredits}
        requiredCredits={requiredCredits}
      />
    )}
  </div>
);

export default memo(ImportEmailVerification);
