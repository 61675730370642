import React, { useRef } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { FileUploaderProps } from './types';
import { ProductTour } from '../../../../../../../../../../shared/enums/product-tour';

const FileUploader = ({
  className,
  fileTypes = '.csv, text/csv',
  onSelect,
}: FileUploaderProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const fileUploaderClass = classNames(['file-uploader', className]);

  const clickInput = () => inputRef.current.click();

  const inputChangeHandler = ({ target: { files } }) =>
    files.length > 0 && onSelect(files[0]);

  const { t } = useTranslation();
  return (
    <div
      className={fileUploaderClass}
      id={ProductTour.ChooseFile}
      onClick={clickInput}
    >
      <div className="choose-file">{t(`Choose a file`)}</div>
      <input
        type="file"
        accept={fileTypes}
        ref={inputRef}
        onChange={inputChangeHandler}
      />
    </div>
  );
};

export default FileUploader;
