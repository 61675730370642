/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import Select, { components } from 'react-select';
import { SelectFilterProps } from '../../../../../shared/design-system/components/molecules/prospects-filter/type';

const Option = (props) => (
  <div className={!props?.data?.isUncategorizedUser && 'gray-19'}>
    <components.Option {...props} className="d-flex align-items-center">
      <div className="ml-4 d-flex align-items-center pointer">
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{' '}
        <span className="ml-2 regular-2 popover-arrow-color-txt">
          {props.label}
        </span>
      </div>
    </components.Option>
  </div>
);

/**
 * Styles to pass in `react-select`
 */
const textStyle = {
  fontSize: '14px',
  fontStyle: 'normal',
  lineHeight: '20px',
  color: '#6b7280',
};

const createGroup = (
  groupName,
  options,
  setValue,
  onSelect,
  selectedOptions,
) => {
  const isChecked = options.every((subItem) =>
    selectedOptions.some(
      (selectedItem) =>
        // Define the condition for matching items here
        subItem.id === selectedItem.id,
    ),
  );

  return {
    label: (() => (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
      <div
        className="d-flex align-items-center pointer"
        onClick={() => {
          setValue(options);
          onSelect(options);
        }}
        key={groupName}
      >
        <input type="checkbox" checked={isChecked} onChange={() => null} />{' '}
        <span className="ml-2 regular-2 font-medium popover-arrow-color-txt text-decoration-uppercase">
          {groupName}
        </span>
      </div>
    ))(),
    options,
  };
};

const MultiSelect: React.FC<SelectFilterProps> = ({
  placeholder,
  options,
  onSelect,
  values = [],
  isDisabled = false,
  containerClassName = '',
}) => {
  const filteredValue = values.map((item) => ({
    label: item?.firstName,
    value: item?.id,
    id: item?.id,
  }));

  const newOptions = options.map((option, idx) => ({
    ...option,
    users: option.users.map((user) => ({
      label: user?.userName?.trim()?.length > 0 ? user?.userName : user?.email,
      value: user?.id,
      uniqueKey: idx,
      isUncategorizedUser: option?.teamName?.toLowerCase() === 'uncategorized',
      ...user,
    })),
  }));

  const [selectedOptions, setSelectedOptions] = useState(filteredValue);
  const [search, setSearch] = useState('');

  const getSelectedOptions = (subOptions) => {
    // Check if all user IDs in the team are in selectedUserIds
    const allUsersSelected = subOptions.every((subItem) =>
      selectedOptions.some(
        (selectedItem) =>
          // Define the condition for matching items here
          subItem.id === selectedItem.id,
      ),
    );

    if (allUsersSelected) {
      // If all users are selected, remove all of them
      const updatedSelectedUserIds = selectedOptions.filter(
        (selectedItem) =>
          !subOptions.some((user) => user.id === selectedItem.id),
      );

      setSelectedOptions(updatedSelectedUserIds);
    } else {
      // If not all users are selected, add those who are not already in selectedUserIds
      const userIDsToAdd = subOptions.filter(
        (selectedItem) =>
          !selectedOptions.some((user) => user.id === selectedItem.id),
      );

      const newSelectedUserIds = [...selectedOptions, ...userIDsToAdd];
      setSelectedOptions(newSelectedUserIds);
    }
  };

  const handleOnSelect = (option) => {
    onSelect(option);
    setSelectedOptions(option);
    setSearch('');
  };
  // Create Options
  const structuredOptions = newOptions.map((option) =>
    createGroup(
      option.teamName,
      option.users,
      getSelectedOptions,
      onSelect,
      selectedOptions,
    ),
  );

  const filterOption = ({ label, data }, searchValue) => {
    setSearch(searchValue);

    // default search
    if (label?.toLowerCase()?.includes(searchValue.toLowerCase())) {
      return true;
    }

    // Search team
    const groupOptions: any = newOptions.filter((group: any) =>
      group.teamName?.toLowerCase().includes(searchValue.toLowerCase()),
    );

    // Search options from the groupOptions
    if (groupOptions) {
      // eslint-disable-next-line no-restricted-syntax
      for (const groupOption of groupOptions) {
        // Check if current option is in group
        const option = groupOption.users.find(
          (opt) => opt?.uniqueKey === data.uniqueKey,
        );

        if (option) {
          return true;
        }
      }
    }

    // If neither the name nor any user within the team matches, exclude the team
    return false;
  };

  const handleKeyDown = (event) => {
    if (
      event.key === 'Enter' ||
      event.key === 'Tab' ||
      (event.key === ' ' && search === '')
    ) {
      event.preventDefault(); // Prevent default Enter key behavior
    }
  };

  const customStyles = {
    control: (base) => ({
      ...base,
      border: '1px solid #E5E7EB',
    }),
    placeholder: (base) => ({
      ...base,
      ...textStyle,
    }),
    option: (base) => ({
      ...base,
      ...textStyle,
      color: '#1F2937',
      wordWrap: 'break-word',
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: '#EFF6FF',
      },
    }),
    multiValue: (base) => ({
      ...base,
      marginRight: '8px',
      borderRadius: '4px',
      border: '1px solid #D1D5DB',
      backgroundColor: '#FFF',
    }),
    multiValueLabel: (base) => ({
      ...base,
      ...textStyle,
      fontWeight: 500,
      color: '#1F2937',
    }),
    multiValueRemove: (base) => ({
      ...base,
      marginRight: '0px',
      color: '#6B7280',
      '&:hover': {
        backgroundColor: '#dbeafe',
        color: '#1d4ed8',
      },
    }),
  };

  useEffect(() => {
    if (isDisabled) {
      setSelectedOptions([]);
    }
  }, [isDisabled]);

  return (
    <Select
      name="owners"
      isMulti
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      filterOption={filterOption}
      onChange={handleOnSelect}
      onKeyDown={handleKeyDown}
      components={{
        Option,
      }}
      value={selectedOptions}
      isOptionSelected={(option) =>
        selectedOptions.filter((op) => op.id === option?.id).length > 0
      }
      options={structuredOptions}
      className={classNames(['basic-multi-select', containerClassName])}
      classNamePrefix="select"
      placeholder={placeholder}
      styles={customStyles}
      isDisabled={isDisabled}
    />
  );
};

export default MultiSelect;
