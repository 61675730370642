import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Button } from '@saleshandy/design-system';
import { Download } from '@saleshandy/icons';

import PreSelect from './components/pre-select';
import PostSelect from './components/post-select';
import toaster, { Theme } from '../../../../../../../../shared/toaster';

import { FileData, SelectFileProps } from './types';
import { constants } from '../../../../../../../../shared/enums/constants';
import { ProductTour } from '../../../../../../../../shared/enums/product-tour';
import { isWhiteLabel } from '../../../../../../../../shared/utils/user-details';

const SelectFile = ({
  onFinish,
  onClose,
  handleCSVImport,
}: SelectFileProps) => {
  const [fileData, setFileData] = useState<FileData>(null);
  const [csvError, setCsvError] = useState<{
    error: boolean;
    message_one: string;
    message_two: string;
  }>({
    error: false,
    message_one: '',
    message_two: '',
  });

  const onRemoveHandler = () => {
    setFileData(null);

    if (csvError) {
      setCsvError({
        error: false,
        message_one: '',
        message_two: '',
      });
    }
  };

  const hasMoreThanTwoEmptyStrings = (arr: string[]) => {
    const emptyStringsCount = arr.filter((item) => item === '').length;
    return emptyStringsCount >= 2;
  };

  const hasMoreThanTwoIdenticalNonEmptyStrings = (arr: string[]) => {
    const nonEmptyArray = arr.filter((item) => item !== '');

    return nonEmptyArray.some(
      (item, index) => nonEmptyArray.indexOf(item) !== index,
    );
  };

  const onSelectSuccess = useCallback(
    (file, headers, firstRow, count) => {
      const payload = {
        file,
        headers,
        firstRow,
        importedContactsCount: count - 1,
      };

      handleCSVImport(payload);
      setFileData(payload);

      const hasEmptyValues = hasMoreThanTwoEmptyStrings(headers);
      const hasDuplicateValues = hasMoreThanTwoIdenticalNonEmptyStrings(
        headers,
      );

      if (hasEmptyValues && hasDuplicateValues) {
        setCsvError({
          error: true,
          message_one:
            'The uploaded file has duplicate column headers and blank columns between data columns.',
          message_two: 'Please fix this and re-upload the file.',
        });
      } else if (hasEmptyValues) {
        setCsvError({
          error: true,
          message_one:
            'The uploaded file has multiple blank columns between data columns.',
          message_two: 'Please remove them and re-upload the file.',
        });
      } else if (hasDuplicateValues) {
        setCsvError({
          error: true,
          message_one: 'The uploaded file has duplicate column headers.',
          message_two:
            'Please ensure the column header is unique and re-upload the file.',
        });
      }

      if (hasEmptyValues || hasDuplicateValues) {
        toaster.error('Import prospect CSV failed', { theme: Theme.New });
      } else {
        toaster.success(`${file.name} file selected successfully`, {
          theme: Theme.New,
        });
      }
    },
    [setFileData],
  );

  const onContinue = () => onFinish(fileData);
  const { t } = useTranslation();

  const clsNames = classNames([
    'select-file import-contact-stage',
    {
      'pre-select-stage': !fileData,
      'post-select-stage': fileData,
      'csv-error': csvError.error,
    },
  ]);

  return (
    <>
      <div className={clsNames}>
        {!fileData ? (
          <div className="select-file-content import-contact-stage-content">
            <PreSelect onSelectSuccess={onSelectSuccess} />
          </div>
        ) : (
          <div className="select-file-content import-contact-stage-content">
            <PostSelect
              fileName={fileData.file.name}
              onRemove={onRemoveHandler}
              csvError={csvError}
            />
          </div>
        )}
        <div className="import-contact-stage-footer">
          <div className="import-contact-stage-footer--left">
            <a
              id={ProductTour.DownloadSampleCSV}
              className="download-import-contacts-sample-file"
              href={
                isWhiteLabel()
                  ? constants.DOWNLOAD_SAMPLE_WHITELABEL_FILE_URL
                  : constants.DOWNLOAD_SAMPLE_FILE_URL
              }
              download
            >
              <span>
                <Download />
              </span>
              <span>{t(`Try sample file`)}</span>
            </a>
          </div>
          <div className="import-contact-stage-footer--right">
            <Button
              className="gray-secondary-btn"
              variant="secondary"
              onClick={onClose}
            >
              {t(`Cancel`)}
            </Button>
            <Button
              id={ProductTour.UploadFileNextStep}
              disabled={!fileData || csvError.error}
              onClick={onContinue}
            >
              {t(`Next`)}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectFile;
