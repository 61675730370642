import { connect, ConnectedProps } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { ReactNode } from 'react';
import { RootState } from '../../../../../store/root-reducer';
import AddContactsButton from './add-contact-button';
import { SHModules } from '../../../../../shared/enums/module';
import {
  getContactStatusCountRequest,
  getSequenceContactListRequest,
  getSequenceStatsCountsRequest,
} from '../../../extra-actions';
import { ContactListParams } from '../../../types';
import {
  resetContacts,
  setSequenceProspectsFilters,
} from '../../../sequence-slice';
import { getEmailVerificationCreditsRequest } from '../../../../settings/components/billing-subscription/extra-actions';
import { ProspectsFilters } from '../../../../../shared/types/prospects-filters';
import { UpdateProfileProgressPayload } from '../../../../../shared/types/user-setting';
import { updateProfileProgressRequest } from '../../../../home/home-slice';

const mapStateToProps = (state: RootState) => ({
  isStepsListEmpty: state.sequence.sequence.steps.length === 0,
  subscriptionPlanType: state.home.subscription?.planCode,
  remainingProspectLimit: parseInt(
    state.home.getPostLoadMetaResponse?.accountUsageQuotaRemaining?.[
      'PROSPECT.ADD'
    ],
    10,
  ),
  sequenceProspectsFilters: state.sequence.sequenceProspectsFilters,
  profileProgress: state.home.profileProgress,
});

const mapDispatchToProps = {
  sendGetContactStatusCountRequest: (
    sequenceId: string,
    params: ContactListParams,
  ) => getContactStatusCountRequest({ sequenceId, ...params }),
  sendGetSequenceContactListRequest: (
    sequenceId: string,
    params: ContactListParams,
  ) =>
    getSequenceContactListRequest({
      sequenceId,
      ...params,
    }),
  resetSequenceContacts: () => resetContacts(),
  sendGetEmailVerificationCreditsRequest: () =>
    getEmailVerificationCreditsRequest(),

  setSequenceProspectsFilters: (filters: ProspectsFilters) =>
    setSequenceProspectsFilters(filters),

  updateProfileProgressRequest: (payload: UpdateProfileProgressPayload) =>
    updateProfileProgressRequest(payload),
  getSequenceStatsCountRequest: (sequenceId: number) =>
    getSequenceStatsCountsRequest(sequenceId),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type SequenceButtonContactsProps = ConnectedProps<typeof connector> & {
  fromModule: SHModules;
  onTabChange?: (tab: string) => void;
  isDropDown?: boolean;
  t: (x: string) => ReactNode;
};

export default withTranslation()(connector(AddContactsButton));
