import { AddTagsToImportedContactsPayload } from '../../components/sequence/types';

export const parseTagPayload = (tags: any[]) => {
  const payload: AddTagsToImportedContactsPayload = {
    ids: [],
    newTags: [],
  };

  tags.forEach((tag) => {
    if (tag.isNew) {
      payload.newTags.push(tag.name);
    } else {
      payload.ids.push(tag.id);
    }
  });

  return payload;
};
