import React, { memo, useMemo } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { DateTime } from 'luxon';
import { DateFilterProps } from './type';
import { stringToDate } from '../../../../functions/date';
import Icon from '../../atoms/icon';
import { getUserTimeZone } from '../../../../utils/user-details';

const DateFilter: React.FC<DateFilterProps> = ({
  onSelect,
  values = [],
  isDisabled = false,
}) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const onDateChange = (date: Date) => {
    onSelect(date);
    date[1] && setIsOpen(false);
  };

  const Input = React.forwardRef<
    HTMLInputElement,
    React.HTMLProps<HTMLInputElement>
  >(({ value, onClick, onChange }, ref) => (
    <div className=" bs-input date-picker-container">
      <input
        placeholder="Select"
        onClick={onClick}
        className="input bs-input"
        onFocus={() => setIsOpen(true)}
        onChange={onChange}
        value={value.toString().length > 15 ? value : ''} // Displaying the value only when both start_date and end_date are selected
        ref={ref}
      />
      <div className="d-flex align-items-center p-2">
        <Icon
          className="cal-icon mr-1 sh-close close-icon"
          identifier="close"
          onClick={() => {
            onSelect([]);
          }}
        />
        <Icon className="cal-icon" identifier="calendar-today" />
      </div>
    </div>
  ));

  const startDate = useMemo(
    () =>
      values[0]?.date
        ? stringToDate(values[0].date)
        : DateTime.fromJSDate(new Date()).setZone(getUserTimeZone()).toJSDate(),
    [values],
  );

  const endDate = useMemo(
    () => (values[1]?.date ? stringToDate(values[1].date) : null),
    [values],
  );

  return (
    <div className="mt-1 prospect-filter-date-piker">
      <DatePicker
        onInputClick={() => setIsOpen(true)}
        selected={startDate}
        onChange={onDateChange}
        startDate={startDate}
        endDate={endDate}
        onClickOutside={() => setIsOpen(false)}
        selectsRange
        open={isOpen}
        customInput={<Input />}
        dateFormat="MMM d, yyyy"
        isDisabled={isDisabled}
      />
    </div>
  );
};

export default memo(DateFilter);
