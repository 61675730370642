import React, { useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import type { IProps } from './add-prospect-form-container';
import { Field } from '../../../components/settings/types/field';
import Form from '../../design-system/components/organisms/form';
import toaster from '../../toaster';
import { ProfileProgressSteps } from '../../types/user-setting';
import {
  generateFormFieldConfig,
  getAddProspectToasterMessage,
  getIsLoading,
  getIsRequestPending,
  showGeneralError,
} from './helper';
import { executeOnRequestStatus } from '../../utils/execute-on-request-status';
import { FormMode } from '../../design-system/components/organisms/form/types';

const AddProspectForm: React.FC<IProps> = ({
  fields,
  addProspectRequestStatus,
  addProspectRequestMessage,
  addProspectRequestError,
  getFieldsRequestStatus,
  containerClasses,
  sequenceId,
  stepId,
  addProspectResponse,
  shouldSendAddToContactStepRequest = false,
  buttonTitle,
  onClose,
  onSubmit,
  sendGetFieldsRequest,
  sendAddProspectRequest,
  resetAddToProspectForm,
  sendAddContactToStepRequest,
  profileProgress,
  sendGetUserSettingsRequest,
  updateProfileProgressRequest,
  updateProfileProgressRequestStatus,
  content,
  getUserSettingsRequestStatus,
}) => {
  const { t } = useTranslation();
  const loading = getIsLoading({ getFieldsRequestStatus, fields });

  const prepareFormConfig = () =>
    fields.reduce(
      (result, field: Field) => generateFormFieldConfig(field, result),
      [],
    );

  const addProspectStepForGettingStarted = () => {
    if (profileProgress) {
      const addProspectStep = profileProgress.find(
        (step) =>
          step.profileProgressStep.stepName ===
          ProfileProgressSteps.AddProspects,
      );

      if (!addProspectStep.isCompleted) {
        updateProfileProgressRequest({
          step: ProfileProgressSteps.AddProspects,
          isCompleted: true,
        });
      }
    }
  };

  useEffect(() => {
    executeOnRequestStatus({
      status: addProspectRequestStatus,
      onSuccess: () => {
        if (shouldSendAddToContactStepRequest) {
          sendAddContactToStepRequest({
            contactIds: [addProspectResponse.id],
            sequenceId,
            stepId,
          });
          if (onSubmit) {
            onSubmit();
          }
        } else if (onClose) {
          onClose();
        }

        addProspectStepForGettingStarted();

        toaster.success(
          getAddProspectToasterMessage({
            t,
            shouldSendAddToContactStepRequest,
            addProspectRequestMessage,
          }),
        );
      },
      onFailed: () => {
        showGeneralError(addProspectRequestError);
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addProspectRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: addProspectRequestStatus,
      onSuccess: () => {
        sendGetUserSettingsRequest();
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addProspectRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: updateProfileProgressRequestStatus,
      onSuccess: () => {
        sendGetUserSettingsRequest();
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateProfileProgressRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: getUserSettingsRequestStatus,
      onIdeal: () => {
        sendGetUserSettingsRequest();
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUserSettingsRequestStatus]);

  const handleSubmit = (values) => {
    const payload = fields.map((field: any) => {
      const key = field?.label?.replace(' ', '')?.toLowerCase();
      return { attributeValue: values[key], fieldRef: field.id };
    });
    sendAddProspectRequest({ attributes: payload });
  };

  useEffect(() => {
    sendGetFieldsRequest();
  }, [sendGetFieldsRequest]);

  useEffect(
    () => () => {
      resetAddToProspectForm();
    },
    [resetAddToProspectForm],
  );

  return (
    <>
      {loading ? (
        <div className="d-flex w-100 add-prospect-manually-modal-spinner  justify-content-center">
          <Spinner
            animation="border"
            size="sm"
            style={{ position: 'absolute', top: '35%' }}
          />
        </div>
      ) : (
        <>
          <Form
            fields={prepareFormConfig()}
            formBodyClasses="form-body"
            formContainerClasses={containerClasses}
            formMode={FormMode.Add}
            onSubmit={(values) => handleSubmit(values)}
            onCancel={onClose}
            submitStatus={getIsRequestPending(addProspectRequestStatus)}
            buttonTitle={buttonTitle}
          />
          <div className="custom-content-with-form">{content}</div>
        </>
      )}
    </>
  );
};

export default AddProspectForm;
