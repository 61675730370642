import api from '../../../api';
import {
  EmailCategoryPayload,
  EmailDeletePayload,
  EmailInboxFiltersPayload,
  EmailReadUnreadStatusPayload,
  EmailThreadActivityPayload,
  EmailThreadFilters,
  ForwardEmailPayload,
  ReplyEmailPayload,
  BulkMarkAsReadUnreadPayload,
  BulkDeletePayload,
  BulkCategoryAssignPayload,
  ReplyDraftPayload,
  ReplyScheduleBasePayload,
  ReplyDraftDiscardBasePayload,
  ReplyScheduleCancelBasePayload,
} from '../type';

// Get Email Inbox List
export const getEmailInbox = async ({
  page,
  limit,
  search,
  categoryId,
  sequenceIds,
  emailAccountIds,
  clientIds,
  owners,
  date,
  isRead,
  status,
  type,
}: EmailInboxFiltersPayload) =>
  api.post('/unibox/emails/', {
    page,
    limit,
    ...(type && { type }),
    ...(search !== '' && { search }),
    ...(categoryId > 0 && { categoryId }),
    ...(sequenceIds?.length > 0 && { sequenceIds }),
    ...(emailAccountIds?.length > 0 && { emailAccountIds }),
    ...(clientIds?.length > 0 && { clientIds }),
    ...(owners?.length > 0 && { owners }),
    ...(date?.startDate &&
      date?.endDate && { startDate: date.startDate, endDate: date.endDate }),
    ...(isRead !== null && { isRead }),
    ...(status && { status }),
  });

// Get Email From Thread List
export const getEmailThread = async ({ hashId }: EmailThreadFilters) =>
  api.get(`/unibox/emails/${hashId}`);

// Get Email From Thread List
export const getEmailThreadActivity = async ({
  id,
  ...rest
}: EmailThreadActivityPayload) =>
  api.get(`/unibox/emails/activity/${id}`, {
    params: {
      ...rest,
    },
  });

// Get Sequences List For Filter
export const getSequencesListForFilter = async () =>
  api.get('/unibox/sequences');

// Get Email Accounts List For Filter
export const getEmailAccountsListForFilter = async () =>
  api.get('/unibox/email-accounts');

export const replyEmail = async (payload: ReplyEmailPayload) =>
  api.post('/unibox/emails/reply', payload);

export const forwardEmail = async (payload: ForwardEmailPayload) =>
  api.post('/unibox/emails/forward', payload);

// Get Email Categories List
export const getEmailCategories = async () => api.get(`/unibox/categories`);

export const updateEmailCategory = async (payload: EmailCategoryPayload) =>
  api.patch('/unibox/category', payload);

// Update Read Unread Status For Email
export const updateEmailReadUnreadStatus = async (
  payload: EmailReadUnreadStatusPayload,
) => api.patch('/unibox/read-status', payload);

// Delete Email Conversation
export const deleteEmailConversation = async (payload: EmailDeletePayload) =>
  api.patch('/unibox/email-thread-delete', payload);

// Bulk Mark As Read and Unread
export const bulkMarkAsReadUnread = async ({
  readStatus,
  deSelectedEmailThreadIds,
  emailsFilter: {
    categoryId,
    search,
    sequenceIds,
    emailAccountIds,
    date,
    clientIds,
    type,
  },
}: BulkMarkAsReadUnreadPayload) =>
  api.patch('/unibox/bulk-read-status', {
    readStatus,
    ...(deSelectedEmailThreadIds.length > 0 && { deSelectedEmailThreadIds }),
    emailsFilter: {
      ...(type && { type }),
      ...(clientIds?.length > 0 && { clientIds }),
      ...(categoryId > 0 && { categoryId }),
      ...(search !== '' && { search }),
      ...(sequenceIds?.length > 0 && { sequenceIds }),
      ...(emailAccountIds?.length > 0 && { emailAccountIds }),
      ...(date?.startDate &&
        date?.endDate && { startDate: date.startDate, endDate: date.endDate }),
    },
  });

// Bulk Delete
export const bulkDelete = async ({
  deSelectedEmailThreadIds,
  emailsFilter: {
    categoryId,
    search,
    sequenceIds,
    emailAccountIds,
    date,
    clientIds,
    type,
  },
}: BulkDeletePayload) =>
  api.patch('/unibox/email-thread/bulk-delete', {
    ...(deSelectedEmailThreadIds.length > 0 && { deSelectedEmailThreadIds }),
    emailsFilter: {
      ...(type && { type }),
      ...(clientIds?.length > 0 && { clientIds }),
      ...(categoryId > 0 && { categoryId }),
      ...(search !== '' && { search }),
      ...(sequenceIds?.length > 0 && { sequenceIds }),
      ...(emailAccountIds?.length > 0 && { emailAccountIds }),
      ...(date?.startDate &&
        date?.endDate && { startDate: date.startDate, endDate: date.endDate }),
    },
  });

// Bulk Category Assign
export const bulkCategoryAssign = async ({
  categoryId: assignCategoryId,
  deSelectedEmailThreadIds,
  emailsFilter: {
    categoryId,
    search,
    sequenceIds,
    emailAccountIds,
    date,
    clientIds,
    type,
  },
}: BulkCategoryAssignPayload) =>
  api.patch('/unibox/bulk-category', {
    categoryId: assignCategoryId,
    ...(deSelectedEmailThreadIds.length > 0 && { deSelectedEmailThreadIds }),
    emailsFilter: {
      ...(type && { type }),
      ...(clientIds?.length > 0 && { clientIds }),
      ...(categoryId > 0 && { categoryId }),
      ...(search !== '' && { search }),
      ...(sequenceIds?.length > 0 && { sequenceIds }),
      ...(emailAccountIds?.length > 0 && { emailAccountIds }),
      ...(date?.startDate &&
        date?.endDate && { startDate: date.startDate, endDate: date.endDate }),
    },
  });

// Unibox reply draft (Create/Update)
export const replyDraft = async (payload: ReplyDraftPayload) =>
  api.post('/unibox/emails/draft', payload);

// Unibox reply schedule
export const replySchedule = async (payload: ReplyScheduleBasePayload) =>
  api.post('/unibox/emails/schedule', payload);

// Unibox draft/schedule discard
export const replyDraftDiscard = async ({
  repliedOnEmailId,
}: ReplyDraftDiscardBasePayload) =>
  api.delete(`/unibox/emails/draft/${repliedOnEmailId}`);

// Unibox reply schedule cancel
export const replyScheduleCancel = async ({
  unifiedScheduledId,
}: ReplyScheduleCancelBasePayload) =>
  api.delete(`/unibox/emails/schedule/${unifiedScheduledId}`);
