// RadioButtonGroupWithShow.tsx
import React from 'react';
import { useTranslation } from 'react-i18next';
import RadioButtonGroup from '../../../../../../../../../../shared/design-system/components/radio-button-group';

interface Option {
  id: number;
  label: JSX.Element;
  value: string;
  descriptionKey?: string;
  titleKey?: string;
}

interface RadioButtonGroupWithShowProps {
  name: string;
  options: Option[];
  value: string;
  onChange: (val: string) => void;
  shouldDisplayHorizontal: boolean;
}

const RadioButtonGroupWithShow: React.FC<RadioButtonGroupWithShowProps> = ({
  name,
  options,
  value,
  onChange,
  shouldDisplayHorizontal,
}) => {
  return (
    <RadioButtonGroup
      name={name}
      className={
        shouldDisplayHorizontal ? 'radio-horizontal' : 'radio-vertical'
      }
      options={options}
      value={value}
      onChange={onChange}
    />
  );
};

export default RadioButtonGroupWithShow;
