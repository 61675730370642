import { DateTime } from 'luxon';
import MasterLog from '../../../../../../../../../../shared/enums/master-log-enum';
import { ProspectActivityTabs } from '../../../../../../../../enums';

export const getActivityWithLabel = (activityArr, t) => {
  const yesterday = DateTime.fromMillis(
    new Date().setDate(new Date().getDate() - 1),
  ).toFormat('dd LLLL yyyy');

  const today = DateTime.fromMillis(new Date().getTime()).toFormat(
    'dd LLLL yyyy',
  );

  const activity = [...activityArr];

  if (activity.length > 0) {
    if (activity[0].dayText === today) {
      activity[0].dayText = t('labels.today');
    }

    if (activity[0].dayText === yesterday) {
      activity[0].dayText = t('labels.yesterday');
    }

    if (activity[1] && activity[1].dayText === yesterday) {
      activity[1].dayText = t('labels.yesterday');
    }
  }

  return activity.map((item, index) => ({
    ...item,
    id: index,
  }));
};

export const getLabel = (tab): string => {
  if (tab === ProspectActivityTabs.Replied) {
    return 'replied';
  }
  if (tab === ProspectActivityTabs.Sent) {
    return 'sent';
  }
  if (tab === ProspectActivityTabs.Opened) {
    return 'opened';
  }
  if (tab === ProspectActivityTabs.Clicked) {
    return 'clicked';
  }

  return 'all';
};

export const getActivityData = (activityItems, response): boolean =>
  activityItems ? activityItems.concat(response) : [];

export const checkIsActivityExsist = (loading, activityItems): boolean =>
  !loading && activityItems?.length <= 0;

export const handleActivityItemsFilters = (tab, item): boolean => {
  if (tab === 'all') {
    return true;
  }

  if (
    tab === ProspectActivityTabs.Replied &&
    (item.key === MasterLog.ReplyReceivedManual ||
      item.key === MasterLog.ReplyReceivedAuto)
  ) {
    return true;
  }

  return item.key === tab;
};
