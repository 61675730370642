import React, { memo, useMemo } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { DateTime } from 'luxon';
import { stringToDate } from '../../../../functions/date';
import Icon from '../../atoms/icon';
import { getUserTimeZone } from '../../../../utils/user-details';

export type DateFilterProps = {
    /**
     *  Select change handler
     */
    onSelect: (x: any) => void;
    /**
     * Selected values.
     */
    values: Date[];
};

const DateFilter: React.FC<DateFilterProps> = ({ onSelect, values = [] }) => {
    const [isOpen, setIsOpen] = React.useState<boolean>(false);

    const onDateChange = (date: Date) => {
        onSelect(date);
        date[1] && setIsOpen(false);
    };

    const Input = React.forwardRef<
        HTMLInputElement,
        React.HTMLProps<HTMLInputElement>
    >(({ value, onClick, onChange }, ref) => (
        <div className=" bs-input date-picker-container">
            <input
                placeholder="Reveal Date"
                onClick={onClick}
                className="input bs-input"
                style={{textIndent: 10}}
                onFocus={() => setIsOpen(true)}
                onChange={onChange}
                value={value.toString().length > 15 ? value : ''} // Displaying the value only when both start_date and end_date are selected
                ref={ref}
            />
            <div className="d-flex align-items-center p-2">
                <Icon
                    className="cal-icon mr-1 sh-close close-icon"
                    identifier="close"
                    onClick={() => {
                        onSelect([]);
                    }}
                />
                <Icon className="cal-icon" identifier="calendar-today" />
            </div>
        </div>
    ));

    const startDate = useMemo(
        () =>
            values[0]
                ? values[0]
                : null,
        [values],
    );

    const endDate = useMemo(
        () => (values[1] ? values[1] : null),
        [values],
    );

    return (
        <div className="mt-1 prospect-filter-date-piker">
            <DatePicker
                onInputClick={() => setIsOpen(true)}
                selected={startDate}
                onChange={onDateChange}
                startDate={startDate}
                endDate={endDate}
                onClickOutside={() => setIsOpen(false)}
                selectsRange
                open={isOpen}
                customInput={<Input />}
                dateFormat="MMM d, yyyy"
            />
        </div>
    );
};

export default memo(DateFilter);
