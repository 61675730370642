import React from 'react';
import { SkeletonLoading } from '@saleshandy/design-system';
import { LeadFinderTabKey } from '../../type';

const LeadsHeaderSkeleton = ({ activeTabKey }) => (
  <div className="leads-header-skeleton">
    <div className="d-flex align-items-center">
      <SkeletonLoading width={16} height={16} />
      <SkeletonLoading width={60} height={8} className="ml-3" />
      <SkeletonLoading width={159} height={28} className="add-prospect-btn" />
      <SkeletonLoading width={159} height={28} className="ml-2" />
      {activeTabKey === LeadFinderTabKey.SAVED_TAB && (
        <SkeletonLoading width={135} height={28} className="ml-2" />
      )}
    </div>
    <div className="credits-btn-skeleton d-flex align-items-center">
      {activeTabKey === LeadFinderTabKey.SAVED_TAB && (
        <SkeletonLoading width={135} height={28} className="mr-2" />
      )}
      <SkeletonLoading width={144} height={28} />
    </div>
  </div>
);

export default LeadsHeaderSkeleton;
