import React from 'react';

export const EmailAccount = ({ width = 16, height = 16 }) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_41203_16115)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.33325 4.00008C3.15644 4.00008 2.98687 4.07032 2.86185 4.19534C2.73682 4.32037 2.66659 4.48994 2.66659 4.66675V11.3334C2.66659 11.5102 2.73682 11.6798 2.86185 11.8048C2.98687 11.9298 3.15644 12.0001 3.33325 12.0001V12.6667V13.3334C2.80282 13.3334 2.29411 13.1227 1.91904 12.7476C1.54397 12.3726 1.33325 11.8638 1.33325 11.3334V4.66675C1.33325 4.13632 1.54397 3.62761 1.91904 3.25253C2.29411 2.87746 2.80282 2.66675 3.33325 2.66675H12.6666C13.197 2.66675 13.7057 2.87746 14.0808 3.25253C14.4559 3.62761 14.6666 4.13631 14.6666 4.66675V7.33341C14.6666 7.7016 14.3681 8.00008 13.9999 8.00008C13.6317 8.00008 13.3333 7.7016 13.3333 7.33341V4.66675C13.3333 4.48994 13.263 4.32037 13.138 4.19534C13.013 4.07032 12.8434 4.00008 12.6666 4.00008H3.33325ZM3.33325 12.6667V13.3334H6.66659C7.03478 13.3334 7.33325 13.0349 7.33325 12.6667C7.33325 12.2986 7.03478 12.0001 6.66659 12.0001H3.33325V12.6667Z"
        fill="#1F2937"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.41878 4.34032C1.59908 4.0193 2.00548 3.90522 2.3265 4.08553L8.00004 7.27209L13.6736 4.08553C13.9946 3.90522 14.401 4.0193 14.5813 4.34032C14.7616 4.66134 14.6475 5.06774 14.3265 5.24805L8.32651 8.61797C8.12376 8.73185 7.87632 8.73185 7.67357 8.61797L1.67357 5.24805C1.35255 5.06774 1.23847 4.66134 1.41878 4.34032Z"
        fill="#1F2937"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4061 10.3163C10.8526 9.86975 11.4582 9.6189 12.0896 9.6189C12.7211 9.6189 13.3267 9.86975 13.7732 10.3163C14.2197 10.7628 14.4705 11.3684 14.4705 11.9998C14.4705 12.6313 14.2197 13.2369 13.7732 13.6834C13.3267 14.1299 12.7211 14.3808 12.0896 14.3808C11.4582 14.3808 10.8526 14.1299 10.4061 13.6834C9.95958 13.2369 9.70874 12.6313 9.70874 11.9998C9.70874 11.3684 9.95958 10.7628 10.4061 10.3163ZM12.0896 10.9522C11.8118 10.9522 11.5454 11.0626 11.3489 11.2591C11.1524 11.4555 11.0421 11.722 11.0421 11.9998C11.0421 12.2777 11.1524 12.5442 11.3489 12.7406C11.5454 12.9371 11.8118 13.0475 12.0896 13.0475C12.3675 13.0475 12.6339 12.9371 12.8304 12.7406C13.0268 12.5442 13.1372 12.2777 13.1372 11.9998C13.1372 11.722 13.0268 11.4555 12.8304 11.2591C12.6339 11.0626 12.3675 10.9522 12.0896 10.9522Z"
        fill="#1F2937"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0893 8.33325C12.4575 8.33325 12.7559 8.63173 12.7559 8.99992V10.2856C12.7559 10.6538 12.4575 10.9523 12.0893 10.9523C11.7211 10.9523 11.4226 10.6538 11.4226 10.2856V8.99992C11.4226 8.63173 11.7211 8.33325 12.0893 8.33325Z"
        fill="#1F2937"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0893 13.0476C12.4575 13.0476 12.7559 13.3461 12.7559 13.7143V15C12.7559 15.3682 12.4575 15.6667 12.0893 15.6667C11.7211 15.6667 11.4226 15.3682 11.4226 15V13.7143C11.4226 13.3461 11.7211 13.0476 12.0893 13.0476Z"
        fill="#1F2937"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2655 10.1667C15.4496 10.4855 15.3403 10.8933 15.0215 11.0774L13.9081 11.7202C13.5892 11.9043 13.1815 11.7951 12.9974 11.4762C12.8133 11.1574 12.9225 10.7496 13.2414 10.5655L14.3548 9.92269C14.6736 9.73859 15.0814 9.84782 15.2655 10.1667Z"
        fill="#1F2937"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1832 12.5238C11.3672 12.8427 11.2578 13.2504 10.9389 13.4344L9.82464 14.0773C9.50572 14.2613 9.09803 14.1519 8.91403 13.833C8.73004 13.5141 8.83941 13.1064 9.15833 12.9224L10.2726 12.2795C10.5915 12.0955 10.9992 12.2049 11.1832 12.5238Z"
        fill="#1F2937"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.91403 10.1669C9.09803 9.84796 9.50572 9.73858 9.82464 9.92258L10.9389 10.5654C11.2578 10.7494 11.3672 11.1571 11.1832 11.476C10.9992 11.795 10.5915 11.9043 10.2726 11.7203L9.15833 11.0775C8.83941 10.8935 8.73004 10.4858 8.91403 10.1669Z"
        fill="#1F2937"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9973 12.5238C13.1813 12.2049 13.589 12.0955 13.9079 12.2795L15.0221 12.9224C15.3411 13.1064 15.4504 13.5141 15.2664 13.833C15.0824 14.1519 14.6747 14.2613 14.3558 14.0773L13.2416 13.4344C12.9227 13.2504 12.8133 12.8427 12.9973 12.5238Z"
        fill="#1F2937"
      />
    </g>
    <defs>
      <clipPath id="clip0_41203_16115">
        <rect width="16" height="16" fill="#1F2937" />
      </clipPath>
    </defs>
  </svg>
);
