import React, { ReactNode } from 'react';
import ConnectEmailAccountModal from '../../../../../../../shared/components/connect-email-account-modal';
import { SHModules } from '../../../../../../../shared/enums/module';
import { ProfileProgressSteps } from '../../../../../../../shared/types/user-setting';
import { SubscriptionPlans } from '../../../../../../../shared/utils/subscription-plans';
import {
  SequenceAction,
  SequenceProgress,
  SequenceSettingCode,
} from '../../../../../enums';
import AddContactsButton from '../../../../../shared/modals/add-contact-button';
import ReachedEmailSendingLimitModal from '../../../../../shared/modals/reached-email-sending-limit/reached-email-sending-limit';
import { Sequence } from '../../../../../types';

export const getSequenceLabel = (sequenceProgress): string => {
  if (sequenceProgress === SequenceProgress.Active) {
    return 'Active';
  }
  if (sequenceProgress === SequenceProgress.Pause) {
    return 'Paused';
  }

  return 'Draft';
};

export const renderUpgradePlanModal = (
  showUpgradePlanModal,
  hideUpgradePlanModal,
): ReactNode => (
  <>
    {showUpgradePlanModal && (
      <ReachedEmailSendingLimitModal onClose={hideUpgradePlanModal} />
    )}
  </>
);

export const renderConnectEmailAccountModal = (
  showConnectEmailModal,
  skipConnectEmailAccount,
  sendConnectEmailAccountRequest,
) => (
  <>
    {showConnectEmailModal && (
      <ConnectEmailAccountModal
        skipConnectEmailAccount={skipConnectEmailAccount}
        sendConnectEmailAccountRequest={sendConnectEmailAccountRequest}
      />
    )}
  </>
);

export const renderAddContactsButton = (
  currentTab,
  isStepsListEmpty,
  isContactsEmpty,
) =>
  currentTab === 'prospects' &&
  !isStepsListEmpty &&
  !isContactsEmpty && <AddContactsButton fromModule={SHModules.Sequence} />;

export const shouldRenderAddStepButton = (currentTab, isStepsListEmpty) =>
  currentTab === 'steps' && !isStepsListEmpty;

export const shouldRenderSequenceScoreRefreshButton = (currentTab) =>
  currentTab === 'sequence-score';

export const getTooltipText = (reason, t) =>
  reason ? t(`messages.${reason}`) : '';

export const shouldShowPlanUpgradeErrorModal = (subscriptionDetails) =>
  subscriptionDetails?.planCode === SubscriptionPlans.EMAIL_STARTER_MONTHLY ||
  subscriptionDetails?.planCode === SubscriptionPlans.EMAIL_STARTER_YEARLY;

export const getSequenceAction = (checked) =>
  checked ? SequenceAction.Resume : SequenceAction.Pause;

export const checkProfileProgressAndRequest = (
  profileProgress,
  updateProfileProgressRequest,
  sequence: Sequence,
) => {
  if (profileProgress) {
    const isSampleSequence = sequence.settings.find(
      (item) => item.code === SequenceSettingCode.IS_SAMPLE_SEQUENCE,
    );
    if (!isSampleSequence) {
      const activateSequenceStep = profileProgress.find(
        (step) =>
          step.profileProgressStep.stepName ===
          ProfileProgressSteps.ActivateSequence,
      );

      const watchVideoStep = profileProgress.find(
        (step) =>
          step.profileProgressStep.stepName === ProfileProgressSteps.WatchVideo,
      );

      if (!activateSequenceStep.isCompleted) {
        updateProfileProgressRequest({
          step: ProfileProgressSteps.ActivateSequence,
          isCompleted: true,
        });
      }

      if (!watchVideoStep.isCompleted) {
        updateProfileProgressRequest({
          step: ProfileProgressSteps.WatchVideo,
          isCompleted: true,
        });
      }
    }
  }
};
