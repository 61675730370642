import React from 'react';
import { withTranslation } from 'react-i18next';
import { EmailAccountMethod } from '../../../components/settings/enums/email-account';
import { Images } from '../../app-constants';
import Button from '../../design-system/components/atoms/button';
import Modal from '../../design-system/components/atoms/modal';
import { redirectWithoutRefresh } from '../../utils';
import { accessibleOnClick } from '../../utils/accessible-on-click';
import ImageIcon from '../images/image-icon';
import { IProps } from './types';

const ConnectEmailAccountModal: React.FC<IProps> = ({
  skipConnectEmailAccount,
  sendConnectEmailAccountRequest,
  t,
}) => {
  const title = t('messages.please_connect_email');
  const body = t('messages.adding_email_account');
  const handleConnectOnClick = (method: EmailAccountMethod) => {
    window.localStorage.setItem('onboarding-step-7', 'true');
    sendConnectEmailAccountRequest(method);
  };

  const onSmtpImapAccount = () => {
    redirectWithoutRefresh('/settings/email-accounts?connect-smtp=true');
  };

  return (
    <Modal
      show={true}
      className="connect-email-account-modal"
      titleContent={title || t('labels.add_email_account')}
      hideCancelButton={true}
      hideSubmitButton={true}
      backdrop="static"
      extraModalProps={{
        'aria-labelledby': 'contained-modal-title-vcenter',
        centered: true,
      }}
      footerContent={
        <div className="local-footer mb-2">
          <span
            className="regular-2 btn-later gray-txt-15 mr-2"
            {...accessibleOnClick(skipConnectEmailAccount)}
          >
            {t('labels.do_later')}
          </span>
        </div>
      }
    >
      <div className="connect-email-account-modal__body">
        <p className="regular-2 gray-txt-15">
          {body || t('messages.connect_email_account_description')}
        </p>
        <div className="connect-email-account-modal__options">
          <div className="semibold-2">{t('labels.select_email_service')}</div>
          <div className="connect-email-account-modal__options-items">
            <Button
              className="connect-email-account-modal__options-item d-flex flex-column"
              onClick={() => handleConnectOnClick(EmailAccountMethod.Gmail)}
            >
              <ImageIcon src={Images.Google30x30} />
              <span className="semibold-1 blue-txt-16 mt-1 email-label">
                Google
              </span>
              <span className="regular-1 font-10 gray-txt-15 item-description">
                {t('messages.google')}
              </span>
            </Button>

            <Button
              className="btn-solid btn-solid-default options-item d-flex flex-column"
              onClick={() => handleConnectOnClick(EmailAccountMethod.Microsoft)}
            >
              <ImageIcon src={Images.Microsoft30x30} />
              <span className="semibold-1 blue-txt-16 mt-1 email-label">
                Microsoft
              </span>
              <span className="regular-1 font-10 gray-txt-15 item-description">
                {t('messages.microsoft')}
              </span>
            </Button>
            <Button
              className="btn-solid btn-solid-default options-item d-flex flex-column"
              onClick={onSmtpImapAccount}
            >
              <div className="ribbon rib-danger">
                <span className="rib-danger gray-txt-1">Beta</span>
              </div>
              <ImageIcon src={Images.Server30x30} />
              <span className="semibold-1 blue-txt-16 mt-1 email-label">
                SMTP/IMAP
              </span>
              <span className="regular-1 font-10 gray-txt-15 item-description">
                {t('messages.smtp_imap')}
              </span>
            </Button>
          </div>
          <div className="gray-txt-15 regular-2 mt-4">
            {t('messages.dont_know_your_email_provider')}
            <a
              href={process.env.REACT_APP_CHECK_EMAIL_SERVICE_PROVIDER}
              className="ml-1 link"
              rel="noreferrer"
              target="_blank"
            >
              {t('labels.click_here')}.
            </a>
          </div>
          <div className="gray-txt-15 mt-3 regular-2">
            {t('messages.email_account_connect_note')}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default withTranslation()(ConnectEmailAccountModal);
