import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import StripeCheckout from 'react-stripe-checkout';

import type { IProps } from './purchase-credits-modal-container';
import Button from '../../../../../../shared/design-system/components/atoms/button';
import ImageIcon from '../../../../../../shared/components/images/image-icon';
import { Images } from '../../../../../../shared/app-constants';
import Icon from '../../../../../../shared/design-system/components/atoms/icon';
import EvCreditPlans from '../ev-credits/components/ev-credit-plans';
import Checkbox from '../../../../../../shared/design-system/components/atoms/checkbox';
import { contactAdmin } from '../../../../../../shared/utils/contact-admin';
import { accessibleOnClick } from '../../../../../../shared/utils/accessible-on-click';
import hasPermission from '../../../../../../shared/utils/access-control/has-permission';
import { Permissions } from '../../../../../../shared/utils/access-control/enums/permissions';
import { AnalyticsEvents } from '../../../../../../shared/enums/analytics';
import {
  executeOnRequestStatus,
  getIsRequestPending,
} from '../../../../../../shared/utils';

const PurchaseCreditsModal: React.FC<IProps> = ({
  show,
  evCreditPlans = [],
  onBuyCredits,
  onClose,
  isPurchaseEmailVerificationCreditsLoading,
  remainingCredit,
  sequenceName,
  currentPlan,
  getEmailVerificationPlanResponse,
  sendGetEmailVerificationPlanRequest,
  getEmailVerificationPlanRequestStatus,
}) => {
  const { t } = useTranslation();
  const [selectedPlan, setSelectedPlan] = useState(
    () =>
      (evCreditPlans?.length > 0 &&
        evCreditPlans.find((plan) => plan.isBestValue === 1)) ||
      null,
  );
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const handleCheckBox = () => {
    setIsChecked((preState) => !preState);
  };

  const onSelectPlan = (plan) => {
    setSelectedPlan(plan);
  };

  const onPurchaseHandler = (token) => {
    onBuyCredits(token.id, +selectedPlan.id);
  };

  const handlePayNowAnalyticsEvent = () => {
    window.analytics?.track({
      event: AnalyticsEvents.ClickedOnPayNow,
      properties: {
        Source: 'Verification Credits',
        current_plan: currentPlan,
      },
    });
  };
  const contactAdminHandler = () => {
    const generalEmailSub = t('messages.general_email_sub_for_purchase_ev');
    const emailSubForSequenceProspectVerify = t(
      'messages.email_sub_for_purchase_ev_from_sequence_settings',
    );
    const generalEmailBody =
      `Hey, %0D%0A %0D%0A I wish to buy ${selectedPlan.name} for Email Verification from SalesHandy.` +
      `%0D%0A Please make arrangements for the same by given URL.` +
      `%0D%0A ${process.env.REACT_APP_V3_PURCHASE_EV_CREDIT_URL} %0D%0A %0D%0AThanks in advance`;
    const sequenceProspectEmailBody =
      `To verify the prospects of our lates sequence "${sequenceName}" we need to purchase credits.` +
      `%0D%0A %0D%0APlease purchase credits to ensure that the sequence "${sequenceName}" continues smoothly.`;

    contactAdmin(
      sequenceName ? emailSubForSequenceProspectVerify : generalEmailSub,
      sequenceName ? sequenceProspectEmailBody : generalEmailBody,
    );
  };

  useEffect(() => {
    if (show && (!evCreditPlans || evCreditPlans?.length === 0)) {
      sendGetEmailVerificationPlanRequest();
    }
  }, [show]);

  useEffect(() => {
    executeOnRequestStatus({
      status: getEmailVerificationPlanRequestStatus,
      onSuccess: () => {
        setSelectedPlan(
          () =>
            (getEmailVerificationPlanResponse?.length > 0 &&
              getEmailVerificationPlanResponse.find(
                (plan) => plan.isBestValue === 1,
              )) ||
            null,
        );
      },
    });
  }, [getEmailVerificationPlanRequestStatus]);

  return (
    <Modal
      show={show}
      backdrop="static"
      className="purchase-credits-modal p-5"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="d-flex justify-content-between purchase-credits-modal-header">
          <div>
            <ImageIcon src={Images.WalletBlue} alt="sent-icon" />
            <span className="semibold-3 ml-3">
              {t('labels.purchase_credits')}
            </span>
          </div>

          <div
            role="button"
            className="close-btn-wrap"
            {...accessibleOnClick(onClose)}
          >
            <Icon identifier="close" className="semibold-4" />
          </div>
        </div>

        <div className="credits-plan-wrap mt-3">
          <EvCreditPlans
            credits={
              evCreditPlans?.length > 0
                ? evCreditPlans
                : getEmailVerificationPlanResponse
            }
            onSelectPlan={(plan) => onSelectPlan(plan)}
            selectedPlan={selectedPlan}
            remainingCredit={remainingCredit}
            isPlansLoading={getIsRequestPending(
              getEmailVerificationPlanRequestStatus,
            )}
          />

          {hasPermission(Permissions.ACCOUNT_SUBSCRIPTION_READ) && (
            <Checkbox
              disabled={false}
              label={t('messages.purchase_ev_credits_agreement')}
              intermediate={false}
              checked={isChecked}
              onChange={handleCheckBox}
              className="purchase-ev-credits-agreement"
            />
          )}
        </div>
      </Modal.Body>

      <div className="purchase-credits-modal-footer">
        <Modal.Footer>
          <Button
            theme={Button.Theme.Ghost}
            disabled={isPurchaseEmailVerificationCreditsLoading}
            variant={Button.Variant.Outlined}
            onClick={onClose}
          >
            {t('labels.cancel')}
          </Button>
          {hasPermission(Permissions.ACCOUNT_SUBSCRIPTION_READ) ? (
            <StripeCheckout
              token={onPurchaseHandler}
              name="Saleshandy"
              image={Images.SHIcon70}
              stripeKey={process.env.REACT_APP_STRIPE_KEY}
              amount={selectedPlan?.amount * 100}
              zipCode
              billingAddress
              ComponentClass="div"
            >
              <Button
                onClick={() => handlePayNowAnalyticsEvent()}
                isLoading={isPurchaseEmailVerificationCreditsLoading}
                disabled={
                  isPurchaseEmailVerificationCreditsLoading || !isChecked
                }
                variant={Button.Variant.Primary}
                className="buy-now-btn"
              >
                {t('labels.buy_now')}
              </Button>
            </StripeCheckout>
          ) : (
            <Button
              variant={Button.Variant.Primary}
              className="contact-admin-btn"
              onClick={contactAdminHandler}
            >
              {t('labels.contact_admin')}
            </Button>
          )}
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export default PurchaseCreditsModal;
