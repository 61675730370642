/* eslint-disable no-return-assign */
import React, { useState, useEffect, useRef } from 'react';
import { CircleCheck, ForwardStep } from '@saleshandy/icons';
import type { IProps } from './tasks-content-container';
import Table from '../../../../shared/design-system/components/organisms/table';
import { PaginationShowHide } from '../../../../shared/design-system/components/organisms/table/types';
import {
  executeOnRequestStatus,
  getIsRequestPending,
} from '../../../../shared/utils';
import { tasksTableData } from './utils/tasks-data';
import { getTasksColumns } from './utils/tasks-columns';
import { TaskAction, TaskStatusKey } from '../../utils/enums';
import SnoozeModal from './components/snooze-modal';
import { getTaskStatusParams } from '../../utils/helper';
import ConfirmationModalV3 from '../../../../shared/design-system/components/atoms/confirmation-modal/confirmation-modal-v3';
import { SequenceStepType } from '../../../sequence/enums';
import Select from '../../../../shared/design-system/components/select';
import {
  getCallOutcomeOptions,
  getCallTaskDesc,
  getCompleteActionDesc,
  getConfirmationModalClass,
  getSelectedTasksId,
  getTaskActionTitle,
  getTaskTableClass,
  getTaskTypeFiltersInLocalStore,
  getToasterMessage,
  isAnyCallTaskSelected,
  isCallTask,
} from './utils/helper';
import EmptyList from '../../helper/empty-list';
import TasksFilterBar from './components/tasks-filter-bar';
import { constants } from '../../../../shared/enums/constants';
import toaster, { Theme } from '../../../../shared/toaster';
import useTasks from './hooks/useTasks';
import ProspectsFilters from '../../../prospect/components/prospect-list/components/prospects-filters';
import { Filters } from '../../../../shared/types/prospects-filters';
import { checkIsFilterApplied } from '../../../sequence/components/sequence-single-content/components/sequence-single-contacts/components/contact-list-content/helper';
import ProspectsFilterBar from '../../../prospect/components/prospect-list/components/prospects-filters/prospects-filter-bar';
import { resolveFilters } from '../../../prospect/helpers/prospect-filters-resolver';
import ReassignModal from './components/reassign-modal';
import TaskBanner from './components/task-banner';
import TaskerModal from '../tasker-modal';
import TaskNoteModal from './components/task-note-modal';
import { UpdateTaskNoteRequest } from '../../utils/types';
// import { updateTasks as sendUpdateTasksRequest } from '../../helper/tasks.api';

const TasksContent: React.FC<IProps> = ({
  taskStatus,
  taskActionRequestStatus,
  updateTaskRequestMessage,
  tasksFilters,
  selectedTasks,
  updateTaskNoteRequestStatus,
  updateTasksRequest,
  updateTaskNoteRequest,
  showLoadingBar,
  hideLoadingBar,
  setTasksFilters,
  resetTasksFilters,
  getTasksStatusCountRequest,
  checkSingle,
  resetSelectedTasks,
  removeTaskFromTaskerData,
}) => {
  const tableRef = useRef<any>(null);

  const [paginationOptions, setPaginationOptions] = useState({
    pageNumber: 1,
    pageLimit: constants.DEFAULT_PAGE_SIZE,
  });
  const [search, setSearch] = useState<string>('');
  const [taskId, setTaskId] = useState<number>(null);
  const [taskAction, setTaskAction] = useState<TaskAction>(null);
  const [taskType, setTaskType] = useState<SequenceStepType>(null);
  const [callOutcome, setCallOutcome] = useState<{
    key: string;
    label: string;
  }>(null);
  const [taskNote, setTaskNote] = useState<string>('');
  const [extraPayload, setExtraPayload] = useState<any>(null);

  const [
    isSnoozeConfirmationModalVisible,
    setIsSnoozeConfirmationModalVisible,
  ] = useState<boolean>(false);
  const [
    isConfirmationModalVisible,
    setIsConfirmationModalVisible,
  ] = useState<boolean>(false);
  const [
    isTaskFilterModalVisible,
    setIsTaskFilterModalVisible,
  ] = useState<boolean>(false);
  const [selectedTaskTypeIds, setSelectedTaskTypeIds] = useState<string>(
    getTaskTypeFiltersInLocalStore()?.join(',') || '',
  );
  const [isReassignModalVisible, setIsReassignModalVisible] = useState<boolean>(
    false,
  );
  const [
    isSelectedRowBannerVisible,
    setIsSelectedRowBannerVisible,
  ] = useState<boolean>(false);
  const [isTaskerModalVisible, setTaskerModalVisible] = useState<boolean>(
    false,
  );
  const [isTaskNoteModalOpen, setIsTaskNoteModalOpen] = useState<boolean>(
    false,
  );

  const filters = resolveFilters(tasksFilters);

  const params = {
    ...(filters?.sequences && { sequenceIds: filters?.sequences }),
    ...(filters?.owners && { owners: filters?.owners }),
    ...(filters?.['created date'] && {
      dueDate: filters?.['created date'],
    }),
    ...(filters?.outcomes && { outcomes: filters?.outcomes }),
    ...(filters?.['task priority'] && { priority: filters?.['task priority'] }),
    ...(search && { search }),
    ...(selectedTaskTypeIds && { taskType: selectedTaskTypeIds }),
  };

  // SWR
  const { tasks, isLoading, isValidating, mutateTasks } = useTasks({
    status: getTaskStatusParams(taskStatus),
    pageLimit: paginationOptions.pageLimit,
    pageNumber: paginationOptions.pageNumber,
    ...params,
  });

  const onRefresh = () => {
    mutateTasks();
    getTasksStatusCountRequest({ ...params });
  };

  const paginationChange = ({ page, limit }) => {
    setPaginationOptions({
      pageNumber: page,
      pageLimit: limit,
    });

    if (paginationOptions.pageLimit !== limit) {
      setTimeout(() => {
        mutateTasks();
      }, 100);
    }
  };

  const updateTasks = (
    tId: number,
    tAction: TaskAction,
    tType: SequenceStepType,
    ePayload = null,
    resetOutcome = true,
  ) => {
    setTaskId(tId);
    setTaskAction(tAction);
    setTaskType(tType);
    setExtraPayload(ePayload);

    if (tAction === TaskAction.SnoozeTask) {
      setIsSnoozeConfirmationModalVisible(true);
      return;
    }

    resetOutcome && setCallOutcome(null);
    setIsConfirmationModalVisible(true);
  };

  const onTaskNoteClick = (tId, tNote) => {
    setTaskId(tId);
    setTaskNote(tNote);
    setIsTaskNoteModalOpen(true);
  };

  const onTaskClick = (id: number) => {
    if (
      taskStatus === TaskStatusKey.Completed ||
      taskStatus === TaskStatusKey.Skipped
    ) {
      return null;
    }
    setTaskId(id);
    setTaskerModalVisible(true);
  };

  const onSnooze = async (date) => {
    const selectedTaskIds = getSelectedTasksId(selectedTasks);

    const payload = {
      taskIds: selectedTaskIds?.length ? selectedTaskIds : [taskId],
      rescheduledDate: date.toString(),
      taskAction: TaskAction.SnoozeTask,
    };

    setPaginationOptions({
      pageLimit: paginationOptions.pageLimit,
      pageNumber:
        paginationOptions.pageNumber > 1 && tasks?.data?.length === 1
          ? paginationOptions.pageNumber - 1
          : paginationOptions.pageNumber,
    });

    await updateTasksRequest(payload);
    mutateTasks();
    removeTaskFromTaskerData(taskId);
  };

  const onConfirm = async () => {
    const selectedTaskIds = getSelectedTasksId(selectedTasks);

    const payload = {
      taskIds: selectedTaskIds?.length ? selectedTaskIds : [taskId],
      taskAction,
      ...(taskAction === TaskAction.ExecuteTask &&
        taskType === SequenceStepType.Call && {
          callOutcome: callOutcome.key,
        }),
      ...(extraPayload && { ...extraPayload }),
    };

    setPaginationOptions({
      pageLimit: paginationOptions.pageLimit,
      pageNumber:
        paginationOptions.pageNumber > 1 && tasks?.data?.length === 1
          ? paginationOptions.pageNumber - 1
          : paginationOptions.pageNumber,
    });

    await updateTasksRequest(payload);
    mutateTasks();
    removeTaskFromTaskerData(taskId);
    setTaskId(null);
  };

  //! Bulk Actions
  const onReassign = async (reAssigneeId: number) => {
    const selectedTaskIds = getSelectedTasksId(selectedTasks);

    const payload = {
      taskIds: selectedTaskIds?.length ? selectedTaskIds : [taskId],
      newAssigneeId: reAssigneeId,
      taskAction,
    };

    await updateTasksRequest(payload);
    mutateTasks();
  };

  const onAction = (action: TaskAction) => {
    setTaskAction(action);

    if (action === TaskAction.SnoozeTask) {
      setIsSnoozeConfirmationModalVisible(true);
      return;
    }
    if (action === TaskAction.ReassignTask) {
      setIsReassignModalVisible(true);
      return;
    }

    setIsConfirmationModalVisible(true);
  };

  const showProspectSelectedRowInfoBanner = () => {
    setIsSelectedRowBannerVisible(true);
  };
  const hideProspectSelectedRowInfoBanner = () => {
    setIsSelectedRowBannerVisible(false);
  };

  const allSelected = (rows: any, status: boolean) => {
    console.log('selected all', rows, status);
  };

  const singleSelected = (row, status: boolean) => {
    // handleResetSelectAllProspectData();
    checkSingle({ row, status });
    // hideProspectSelectedRowInfoBanner();
  };

  //! Tasks Filter
  const toggleFilter = () =>
    setIsTaskFilterModalVisible(!isTaskFilterModalVisible);
  const hideFilterModal = () => setIsTaskFilterModalVisible(false);

  const getTasksAfterFilter = () => {
    resolveFilters(tasksFilters);
  };
  const onClearFilter = () => {
    mutateTasks();
  };
  const onTaskTypeFilterApply = (selectedIds: number[]) => {
    setSelectedTaskTypeIds(selectedIds.join(','));

    getTasksStatusCountRequest({ ...params, taskType: selectedIds.join(',') });
    setTimeout(() => {
      mutateTasks();
    }, 100);
  };
  const handleSearch = (searchValue: string) => {
    setSearch(searchValue);

    getTasksStatusCountRequest({ ...params, search: searchValue });
    setTimeout(() => {
      mutateTasks();
    }, 100);
  };

  //! useEffect
  useEffect(() => {
    executeOnRequestStatus({
      status: taskActionRequestStatus,
      onSuccess: () => {
        toaster.success(getToasterMessage(taskAction), { theme: Theme.New });
        getTasksStatusCountRequest({
          ...params,
        });
        setIsSnoozeConfirmationModalVisible(false);
        setIsConfirmationModalVisible(false);
        setIsReassignModalVisible(false);
        setTaskId(null);
        resetSelectedTasks();
      },
    });
  }, [taskActionRequestStatus]);

  useEffect(() => {
    setPaginationOptions({
      pageLimit: constants.DEFAULT_PAGE_SIZE,
      pageNumber: 1,
    });
    tableRef.current?.resetPagination();
  }, [taskStatus]);

  useEffect(() => {
    mutateTasks();
    getTasksStatusCountRequest({
      ...params,
    });
  }, [tasksFilters]);

  useEffect(
    () => () => {
      hideLoadingBar();
      resetSelectedTasks();
    },
    [],
  );

  //! Render Function
  const getModalContent = () => {
    if (taskAction === TaskAction.ExecuteTask) {
      return (
        <>
          {taskType === SequenceStepType.Call ||
          isAnyCallTaskSelected(selectedTasks) > 0 ? (
            <>
              <span className="regular-2 popover-arrow-color-txt mb-3">
                {getCallTaskDesc(selectedTasks?.length)}
              </span>
              <div className="mt-3">
                <Select<{ key: string; label: string }>
                  options={getCallOutcomeOptions}
                  placeholder="Select call outcome"
                  selectedOptionKey={callOutcome?.key}
                  onChange={([option]) => setCallOutcome(option)}
                  optionRenderer={(option) => <span>{option?.label}</span>}
                  selectedOptionRenderer={([option]) => (
                    <span>{option?.label}</span>
                  )}
                />
              </div>
            </>
          ) : (
            <span className="regular-2 popover-arrow-color-txt mb-3">
              {getCompleteActionDesc(selectedTasks?.length)}
            </span>
          )}
        </>
      );
    }

    return 'Are you sure you want to skip this task?';
  };

  const isButtonDisabled = () =>
    getIsRequestPending(taskActionRequestStatus) ||
    (!callOutcome &&
      taskType === SequenceStepType.Call &&
      taskAction !== TaskAction.SkipTask);

  useEffect(() => {
    if (!isLoading && isValidating) {
      showLoadingBar();
    } else {
      hideLoadingBar();
    }
  }, [isLoading, isValidating]);

  return (
    <>
      <div className="tasks__content">
        <TasksFilterBar
          onFilterToggle={toggleFilter}
          onSearch={(searchValue: string) => handleSearch(searchValue)}
          onTaskTypeFilterApply={(selectedIds: number[]) =>
            onTaskTypeFilterApply(selectedIds)
          }
          onRefresh={onRefresh}
          onAction={(action: TaskAction) => onAction(action)}
          onStartAllTasks={() => setTaskerModalVisible(true)}
          tasksCount={tasks?.count}
          status={taskStatus}
        />

        {checkIsFilterApplied(tasksFilters) && (
          <div className="mt-4 bs-mb-20 pl-4 pr-4 tasks-filter">
            <ProspectsFilterBar
              prospectsFilters={tasksFilters}
              resetProspectsFilters={resetTasksFilters}
              getProspects={getTasksAfterFilter}
            />
          </div>
        )}

        {selectedTasks?.length > 0 && (
          <div className="pr-4 pl-4">
            <TaskBanner selectedTasks={selectedTasks} />
          </div>
        )}

        {!isLoading && tasks?.count === 0 ? (
          <EmptyList activeTab={taskStatus} />
        ) : (
          <div
            className={`tasks__content-table-list ${getTaskTableClass(
              tasksFilters,
              selectedTasks?.length,
            )}`}
          >
            <Table
              columns={getTasksColumns(taskStatus, updateTasks, onTaskClick)}
              data={tasksTableData(tasks?.data)}
              onPaginationOptionsChange={paginationChange}
              paginationOptions={{
                options: {
                  limit: paginationOptions.pageLimit,
                  page: paginationOptions.pageNumber,
                  totalElements: tasks?.count,
                },
              }}
              // onRowSelect={(row, isSelect) => singleSelected(row, isSelect)}
              // onRowSelectAll={(rows, isSelect) => allSelected(rows, isSelect)}
              pagination={PaginationShowHide.SHOW}
              isNewPagination={true}
              headerWrapperClasses="tasks-table-header"
              bodyWrapperClasses="tasks-body-wrapper"
              isLoading={isLoading}
              tableRef={(refValue: any) => (tableRef.current = refValue)}
            />
          </div>
        )}
      </div>
      {isSnoozeConfirmationModalVisible && (
        <SnoozeModal
          show={isSnoozeConfirmationModalVisible}
          onClose={() => setIsSnoozeConfirmationModalVisible(false)}
          onSubmit={(date: Date) => onSnooze(date)}
          isRequestPending={getIsRequestPending(taskActionRequestStatus)}
        />
      )}
      {isConfirmationModalVisible && (
        <ConfirmationModalV3
          show={isConfirmationModalVisible}
          onSubmit={onConfirm}
          onClose={() => setIsConfirmationModalVisible(false)}
          showCloseIcon={false}
          hideTitleIcon={true}
          icon={
            taskAction === TaskAction.ExecuteTask ? (
              <CircleCheck width={24} height={24} className="orange-txt-14" />
            ) : (
              <ForwardStep width={24} height={24} className="orange-txt-14" />
            )
          }
          title={getTaskActionTitle(taskAction, selectedTasks?.length)}
          cancelButtonText="No"
          content={getModalContent()}
          isSubmitLoading={getIsRequestPending(taskActionRequestStatus)}
          isSubmitDisabled={isButtonDisabled()}
          className={`task-confirmation-modal ${getConfirmationModalClass(
            taskAction,
            taskType,
            selectedTasks,
          )}`}
        />
      )}

      {isTaskFilterModalVisible && (
        <ProspectsFilters
          show={isTaskFilterModalVisible}
          hide={hideFilterModal}
          getProspects={getTasksAfterFilter}
          resetProspectsFilters={resetTasksFilters}
          setProspectsFilters={setTasksFilters}
          prospectsFilters={tasksFilters}
          except={[
            Filters.ConversationType,
            Filters.Owners,
            Filters.Tags,
            Filters.VerificationStatus,
            Filters.CreationSource,
            Filters.Status,
            Filters.ClientAssociated,
            Filters.EmailAccount,
          ]}
          isATMOwnerList={true}
          isTasksFilter={true}
          handleClearAll={onClearFilter}
        />
      )}

      {isReassignModalVisible && (
        <ReassignModal
          show={isReassignModalVisible}
          onClose={() => setIsReassignModalVisible(false)}
          onSubmit={(id: number) => onReassign(id)}
        />
      )}

      {isTaskerModalVisible && (
        <TaskerModal
          show={isTaskerModalVisible}
          onHide={() => setTaskerModalVisible(false)}
          taskStatus={taskStatus}
          tasksCount={tasks?.count}
          paginationOptions={paginationOptions}
          taskId={taskId}
          onOutcomeChange={(outcome) => setCallOutcome(outcome)}
          updateTasks={(
            tId: number,
            tAction: TaskAction,
            tType: SequenceStepType,
            ePayload?: any,
          ) => updateTasks(tId, tAction, tType, ePayload, false)}
          onTaskNoteClick={(tId, tNote) => onTaskNoteClick(tId, tNote)}
          filters={params}
          resetTaskId={() => setTaskId(null)}
          onRefresh={onRefresh}
        />
      )}

      {isTaskNoteModalOpen && (
        <TaskNoteModal
          taskId={taskId.toString()}
          show={isTaskNoteModalOpen}
          taskNote={taskNote}
          onClose={() => setIsTaskNoteModalOpen(false)}
          onSubmit={(payload: UpdateTaskNoteRequest) =>
            updateTaskNoteRequest(payload)
          }
          isLoading={getIsRequestPending(updateTaskNoteRequestStatus)}
        />
      )}
    </>
  );
};

export default TasksContent;
