import React, { ReactNode } from 'react';

type IProps = {
  title: string;
  children: ReactNode;
};

const AdminSettingSection: React.FC<IProps> = ({ title, children }) => (
  <div className="admin-setting-section">
    <div className="admin-setting-section--title">{title}</div>
    <div className="admin-setting-section--children">{children}</div>
  </div>
);

export default AdminSettingSection;
