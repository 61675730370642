import React, { useState, useEffect } from 'react';
import { Button } from '@saleshandy/design-system';
import { useTranslation } from 'react-i18next';
import type { IProps } from './sequence-sending-priority-container';
import { SequencePrioritySettingCode } from '../../../../../../../../enums';
import {
  executeOnErrorWithErrorCheck,
  executeOnRequestStatus,
} from '../../../../../../../../../../shared/utils';
import toaster, { Theme } from '../../../../../../../../../../shared/toaster';
import RadioButtonGroupWithShow from './radio-button-show';
import { OverlayTooltip } from '../../../../../../../../../../shared/design-system/components/overlay';
import { supportUrls } from '../../../../../../../../../../shared/utils/urls';
import LinkBadge from '../../../../../../../../../../shared/design-system/components/atoms/link-badge/link-badge';

// Helper function to generate options
export const createOption = (
  id: number,
  titleKey: string,
  descriptionKey: string,
  value: string,
  shouldDisplayHorizontal = false,
) => ({
  id,
  label: shouldDisplayHorizontal ? (
    <div className="sequence-setting-priprity-title">{titleKey}</div>
  ) : (
    <div>
      <div className="sequence-setting-priprity-title">{titleKey}</div>
      <div className="sequence-setting-priprity-description">
        {descriptionKey}
      </div>
    </div>
  ),
  value,
});

export const options = [
  createOption(
    1,
    'Prioritise Follow-Ups',
    'More emails will be sent from follow-up steps',
    SequencePrioritySettingCode.LAST_TO_FIRST,
  ),
  createOption(
    2,
    'Prioritise New Prospects',
    'More emails will be sent from the first step',
    SequencePrioritySettingCode.FIRST_TO_LAST,
  ),
  createOption(
    3,
    'Balanced Sending',
    'Equal number of emails will be sent from each step',
    SequencePrioritySettingCode.EQUAL,
  ),
  createOption(
    4,
    'Aggressively Prioritise New Prospects',
    'Up to 80% of total emails will be sent from the first Step',
    SequencePrioritySettingCode.AGGRESSIVE_FISRT,
  ),
];
const SequenceSendingPriority: React.FC<IProps> = ({
  prioritySetting = [],
  sequenceId,
  updateSequencePriorityConfigRequest,
  updateSequencePriorityConfigError,
  updateSequencePriorityConfigMessage,
  updateSequencePriorityConfigStatus,
}) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [selectedPriorityCode, setSelectedPriorityCode] = useState<string>('');
  const [initialPriorityCode, setInitialPriorityCode] = useState<string>('');

  // This will handle the radio button value change
  const handlePriorityChange = (val: string) => {
    setSelectedPriorityCode(val);
    setIsDisabled(initialPriorityCode === val);
  };

  useEffect(() => {
    if (prioritySetting.length > 0) {
      const selected = prioritySetting.find((setting) => setting.value === '1');
      if (selected) {
        setSelectedPriorityCode(selected.code);
        setInitialPriorityCode(selected.code);
        setIsDisabled(true);
      }
    }
  }, [prioritySetting]);

  const handleSave = async () => {
    setIsLoading(true);
    try {
      const selectedOption = options.find(
        (option) => option.value === selectedPriorityCode,
      );
      if (selectedOption) {
        await updateSequencePriorityConfigRequest({
          sequenceId,
          priorityDistributionId: selectedOption.id,
        });
        setIsLoading(false);
        setIsDisabled(true);
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  //* useEffect
  useEffect(() => {
    executeOnRequestStatus({
      status: updateSequencePriorityConfigStatus,
      onSuccess: () => {
        toaster.success(
          'Sending priority is updated successfully for this sequence',
        );
        setInitialPriorityCode(selectedPriorityCode);
      },
      onFailed: () => {
        executeOnErrorWithErrorCheck({
          error: updateSequencePriorityConfigError,
          onError: () => {
            toaster.error(updateSequencePriorityConfigMessage, {
              theme: Theme.New,
            });
          },
        });
      },
    });
  }, [
    updateSequencePriorityConfigStatus,
    updateSequencePriorityConfigError,
    updateSequencePriorityConfigMessage,
  ]);

  return (
    <>
      <div className="card">
        <div className="sequence-sending-priority__card-body">
          <div className="sequence-sending-priority-title info-circle-container">
            <span className="sequence-setting-title">
              {t('labels.sending_priority')}
            </span>
            <OverlayTooltip text="Click to know more about sending priority.">
              <LinkBadge url={supportUrls.sendingPriorityBlog} />
            </OverlayTooltip>
          </div>
          <div className="safety-settings-switch-wrapper sending-priority-radio-group">
            <RadioButtonGroupWithShow
              name="priority-set"
              shouldDisplayHorizontal={false}
              options={options}
              value={selectedPriorityCode} // Set the prepopulated value based on prioritySetting array
              onChange={(val) => handlePriorityChange(val)} // Call API on value change
            />
          </div>
          <Button
            variant="primary"
            onClick={() => handleSave()}
            isLoading={isLoading}
            disabled={isDisabled}
            className="sending-priority-save-btn"
            loadingText="Save"
          >
            {t('labels.save')}
          </Button>
        </div>
      </div>
    </>
  );
};

export default SequenceSendingPriority;
