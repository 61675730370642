import React from 'react';

const EmailVerificationError = ({ availableCredits, requiredCredits }) => {
  const accountCreditData = [
    {
      key: 'Available Verification Credits',
      value: availableCredits,
    },
    {
      key: 'Required Verification Credits',
      value: requiredCredits,
    },
  ];
  return (
    <>
      <div className="email-verification-error-container">
        <div className="email-verification-error-content">
          <div className="email-verification-error-table">
            {accountCreditData.map((item) => (
              <div className="credit-table">
                <p className="regular-1 m-0 left-content">{item.key}</p>
                <p className="semibold-1 m-0 right-content">{item.value}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailVerificationError;
