/* eslint-disable react/no-array-index-key */
import React, { ReactNode } from 'react';
import { Dropdown } from 'react-bootstrap';
import { ChevronDown } from '@saleshandy/icons';

type IProps = {
  style?: React.CSSProperties;
  heading: string;
  disabled?: boolean;
  id?: string;
  options: {
    opId?: string;
    listKey: string;
    icon?: string;
    onClick: any;
    listLabel: string;
    iconComponent?: ReactNode;
  }[];
};

const DropDownAtom: React.FC<IProps> = ({
  style,
  options,
  heading,
  disabled,
  id,
}) => (
  <Dropdown bsPrefix="dropdown-custom" drop="down">
    <Dropdown.Toggle
      bsPrefix="dropdown-toggle-custom"
      variant="primary"
      id={`dropdown-basic ${id}`}
      style={style}
      disabled={!!disabled}
    >
      <span className="m-2">{heading}</span>
      <ChevronDown />
    </Dropdown.Toggle>

    <Dropdown.Menu bsPrefix="dropdown-menu-custom mt-2">
      {options.map(
        ({ opId, icon, listKey, onClick, listLabel, iconComponent }, index) => (
          <Dropdown.Item
            id={opId}
            key={index}
            bsPrefix="dropdown-item-custom"
            href="#"
            onClick={() => onClick(listKey)}
          >
            {icon && <i className={`sh-${icon} lfloat`} />}
            &nbsp;
            {iconComponent && iconComponent}
            &nbsp; &nbsp;
            <span>{listLabel}</span>
          </Dropdown.Item>
        ),
      )}
    </Dropdown.Menu>
  </Dropdown>
);

export default DropDownAtom;
