import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  AddTagsToLeadsPayload,
  BulkActionPayload,
  LeadsFindRequestPayload,
} from './type';
import { ResponseErrorWithHandled, ResponseSuccess } from '../../shared/types';
import {
  addTagsToBulkLeads,
  addTagsToLeads,
  getLeads,
  getTagsToLeads,
  removeTagsFromBulkLeads,
} from './helpers/leads.api';

export const getLeadsListRequest = createAsyncThunk<
  ResponseSuccess,
  { LeadsFindRequestPayload: LeadsFindRequestPayload },
  { rejectValue: ResponseErrorWithHandled }
  // eslint-disable-next-line @typescript-eslint/no-shadow
>('leads/getLeadList', async ({ LeadsFindRequestPayload }, thunkAPI) => {
  try {
    return await getLeads(LeadsFindRequestPayload);
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const addTagsToLeadsRequest = createAsyncThunk<
  ResponseSuccess,
  AddTagsToLeadsPayload,
  { rejectValue: ResponseErrorWithHandled }
>('leads/addTagsToLeadsRequest', async (args, thunkAPI) => {
  try {
    return await addTagsToLeads(args);
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getTagsToLeadsRequest = createAsyncThunk<
  ResponseSuccess,
  null,
  { rejectValue: ResponseErrorWithHandled }
>('leads/getTagsToLeads', async (args, thunkAPI) => {
  try {
    return await getTagsToLeads();
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const addTagsToBulkLeadsRequest = createAsyncThunk<
  ResponseSuccess,
  BulkActionPayload,
  { rejectValue: ResponseErrorWithHandled }
>('leads/addTagsToBulkLeadsRequest', async (args, thunkAPI) => {
  try {
    return await addTagsToBulkLeads(args);
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const removeTagsFromBulkLeadsRequest = createAsyncThunk<
  ResponseSuccess,
  BulkActionPayload,
  { rejectValue: ResponseErrorWithHandled }
>('leads/removeTagsFromBulkLeadsRequest', async (args, thunkAPI) => {
  try {
    return await removeTagsFromBulkLeads(args);
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});
