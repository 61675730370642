import React, { useEffect, useState } from 'react';
import SearchInput from '../../../../../../../../shared/design-system/components/atoms/search-input';
import { constants } from '../../../../../../../../shared/enums/constants';
import {
  ProspectCountParams,
  ProspectPagination,
  ProspectParams,
} from '../../../../../../types';
import { IconPlace } from '../../../../../../../../shared/design-system/components/input/enums';

type IProps = {
  sendGetProspectRequest: (params: ProspectParams) => void;
  sendGetProspectCountRequest: (params: ProspectCountParams) => void;
  saveSearch: (query: string) => void;
  changePagination: (params: ProspectPagination) => void;
  searchValue: string;
};

const ProspectListSearchBar: React.FC<IProps> = ({
  sendGetProspectRequest,
  sendGetProspectCountRequest,
  saveSearch,
  changePagination,
  searchValue,
}) => {
  const [initialValue, setInitialValue] = useState('');

  const onSearchChangeHandler = (value: string) => {
    saveSearch(value);
  };

  const handleSubmit = () => {
    changePagination({
      pageNum: constants.DEFAULT_PAGE_NUM,
      pageSize: constants.DEFAULT_PAGE_SIZE,
    });
    sendGetProspectRequest({
      pageNum: constants.DEFAULT_PAGE_NUM,
      pageSize: constants.DEFAULT_PAGE_SIZE,
      search: searchValue,
    });
    sendGetProspectCountRequest({ search: searchValue });
  };

  const handleClearSearch = () => {
    changePagination({
      pageNum: constants.DEFAULT_PAGE_NUM,
      pageSize: constants.DEFAULT_PAGE_SIZE,
    });
    sendGetProspectRequest({
      pageNum: constants.DEFAULT_PAGE_NUM,
      pageSize: constants.DEFAULT_PAGE_SIZE,
      search: '',
    });
    sendGetProspectCountRequest({ search: '' });
  };

  useEffect(() => {
    if (searchValue !== '') {
      setInitialValue(searchValue);
    }
  }, []);

  return (
    <div className="search-bar">
      <SearchInput
        onSearch={handleSubmit}
        searchValue={searchValue}
        initialValue={initialValue}
        setSearchValue={onSearchChangeHandler}
        onClearSearch={handleClearSearch}
        placeholder="Search & hit Enter"
        searchIconPlace={IconPlace.Left}
      />
    </div>
  );
};

export default ProspectListSearchBar;
