import { RootState } from '../../../../../../../../store/root-reducer';
import { connect, ConnectedProps } from 'react-redux';
import { CreateCustomFieldRequestPayload } from '../../../../../../../settings/types/request-payload';
import {
  createCustomFieldRequest,
  getCustomFieldRequest,
} from '../../../../../../../settings/components/custom-fields/extra-actions';
import MapFields from './map-fields';
import { MapFieldsProps } from './types';
import { getContactFieldsRequest } from '../../../../../../extra-actions';
import { resetCreateCustomFieldRequest } from '../../../../../../../settings/components/custom-fields/custom-field-slice';

const mapStateToProps = (state: RootState) => ({
  getCustomFieldRequestStatus: state.customField.getCustomFieldRequest.status,
  getCustomFieldRequestMessage: state.customField.getCustomFieldRequest.message,
  getCustomFieldRequestError: state.customField.getCustomFieldRequest.error,

  createCustomFieldRequestStatus:
    state.customField.createCustomFieldRequest.status,
  createCustomFieldRequestMessage:
    state.customField.createCustomFieldRequest.message,
  createCustomFieldRequestError:
    state.customField.createCustomFieldRequest.error,

  customField: state.customField.customField,
  isLoading: state.customField.isLoading,
});

const mapDispatchToProps = {
  sendGetCustomFieldRequest: () => getCustomFieldRequest(),

  sendCreateCustomFieldRequest: (payload: CreateCustomFieldRequestPayload) =>
    createCustomFieldRequest(payload),

  sendGetContactFieldsRequest: () => getContactFieldsRequest(),

  resetCreateCustomFieldRequest: () => resetCreateCustomFieldRequest(),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & MapFieldsProps;

export default connector(MapFields);
