import React, { memo } from 'react';
import classNames from 'classnames';
import 'react-datepicker/dist/react-datepicker.css';
import { FilterType, ProspectsFilterProps } from './type';
import DateFilter from './date-filter';
import SelectFilter from './select-filter';

const ProspectsFilter: React.FC<ProspectsFilterProps> = ({
  label,
  placeholder,
  containerClassName,
  options,
  labelKey,
  onSelect,
  uniqueKey,
  placement,
  values,
  filterType = FilterType.DropDown,
  isMultiSelect = true,
  isDisabled = false,
}) => (
  <div
    className={classNames([
      'prospects-filter-container d-flex flex-column',
      containerClassName,
    ])}
  >
    <div className="label-container label-text">{label}</div>
    <div className="filter-container mt-1">
      {filterType === FilterType.DropDown ? (
        <SelectFilter
          placeholder={placeholder}
          options={options}
          labelKey={labelKey}
          uniqueKey={uniqueKey}
          values={values}
          onSelect={onSelect}
          placement={placement}
          isMultiSelect={isMultiSelect}
          isDisabled={isDisabled}
        />
      ) : (
        <DateFilter
          values={values}
          onSelect={onSelect}
          isDisabled={isDisabled}
        />
      )}
    </div>
  </div>
);

export default memo(ProspectsFilter);
