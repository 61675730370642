import React, { useEffect } from 'react';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Icon from '../../../../../../../../../../shared/design-system/components/atoms/icon/icon';
import Select from '../../../../../../../../../../shared/design-system/components/select';
import { MapListProps } from './types';
import { FieldOption } from '../../map-fields';
import {
  headerLikeEmail,
  headerLikeFirstName,
  headerLikeLastName,
} from './utils/possible-csv-default-headers';
import { DefaultFields } from './enums/default-fields';
import {
  Overlay,
  Placement,
  Tooltip,
} from '../../../../../../../../../../shared/design-system/components/overlay';
import { isRowDataTypeValid } from './utils/field-type-validator';
import { accessibleOnClick } from '../../../../../../../../../../shared/utils/accessible-on-click';

const MapList = ({
  headers,
  firstRow,
  fieldKeys,
  fieldOptions,
  onFieldRemove,
  onFieldChange,
  setFieldKeys,
  customFieldFooter,
}: MapListProps) => {
  useEffect(() => {
    const suggestedCustomFields = fieldOptions.map((item) => {
      if (item.isDefault) {
        if (item.label === DefaultFields.Email) {
          return {
            id: item.id,
            suggested: headerLikeEmail,
            fieldType: item.fieldType,
          };
        }
        if (item.label === DefaultFields.firstName) {
          return {
            id: item.id,
            suggested: headerLikeFirstName,
            fieldType: item.fieldType,
          };
        }
        if (item.label === DefaultFields.lastName) {
          return {
            id: item.id,
            suggested: headerLikeLastName,
            fieldType: item.fieldType,
          };
        }
      }
      return {
        id: item.id,
        suggested: [item.label.toLowerCase().trim()],
      };
    });

    const keyFieldMap = [...fieldKeys];

    headers.forEach((header, index) => {
      const currentHeader = header
        .split(' ')
        .map((item) => item.trim())
        .filter(Boolean)
        .join(' ');
      suggestedCustomFields.forEach((item) => {
        if (item.suggested.includes(currentHeader.toLowerCase().trim())) {
          keyFieldMap[index] = item.id;
          if (firstRow?.[index]) {
            firstRow[index].isTypeValid = isRowDataTypeValid(
              item.fieldType,
              firstRow[index]?.value || '',
            );
          }
        }
      });
    });
    setFieldKeys(keyFieldMap);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { t } = useTranslation();

  const iconJsx = (row) =>
    row?.isTypeValid ? (
      <Icon
        identifier="check-o"
        className="lime-txt-8"
        style={{ margin: '.5rem' }}
      />
    ) : (
      <Overlay
        placement={Placement.Right}
        overlay={<Tooltip id="hola-tooltip" text="Invalid custom field type" />}
      >
        <Icon
          identifier="danger"
          className="gold-txt-8"
          style={{ margin: '.5rem' }}
        />
      </Overlay>
    );

  return (
    <div className="map-list overflow-hidden">
      <div className="map-list-header semibold-2">
        <Row>
          <div className="ml-3">{t(`Fields from file`)}</div>
          <div className="sample-map-field">{t(`Sample`)}</div>
        </Row>
        <div className="mr-5">{t(`Fields`)}</div>
      </div>
      <div className="map-list-content">
        {headers.map((header, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index} className="map-list-item">
            <div className="map-list-item-child-left">
              <div className="csv-header-label semibold-2" title={header}>
                {header}
              </div>
              <div
                className="csv-row-value regular-2"
                title={firstRow[index]?.value || ''}
              >
                {firstRow[index]?.value || ''}

                {firstRow[index]?.isTypeValid === null
                  ? null
                  : iconJsx(firstRow[index])}
              </div>
            </div>
            <div className="map-list-item-child-right">
              {(fieldKeys[index] || fieldKeys[index] === 0) && (
                <div
                  className="map-list-item-remove-icon-wrapper"
                  {...accessibleOnClick(() => onFieldRemove(index))}
                >
                  <Icon identifier="trash" />
                </div>
              )}
              <div>
                <Select<FieldOption>
                  selectedOptionRenderer={([option]) => (
                    <span>{option?.label}</span>
                  )}
                  optionRenderer={(option) => <span>{option?.label}</span>}
                  options={fieldOptions.filter(
                    // Show only non-selected fields (and the currently selected field)
                    (option) =>
                      !fieldKeys.includes(option.key) ||
                      option.key === fieldKeys[index],
                  )}
                  onChange={([{ key, fieldType }]) =>
                    onFieldChange(index, key, fieldType)
                  }
                  selectedOptionKey={fieldKeys[index]}
                  placeholder="Select Field"
                  className="map-field-select"
                />
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="map-list-footer">{customFieldFooter}</div>
    </div>
  );
};
export default MapList;
