import React, { memo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import EmailVerificationError from '../../atoms/email-verification-error/email-verification-error';
import { Images } from '../../../../app-constants';
import ImageIcon from '../../../../components/images/image-icon';

const ManualEmailVerification = ({
  availableCredits,
  requiredCredits,
  title,
}) => {
  const { t } = useTranslation();
  const [
    isRenderEvPurchaseActionContent,
    setIsRenderEvPurchaseActionContent,
  ] = useState<boolean>(false);

  useEffect(() => {
    setIsRenderEvPurchaseActionContent(requiredCredits > availableCredits);
  }, [availableCredits, requiredCredits]);

  const renderEvPurchaseActionContent = !isRenderEvPurchaseActionContent
    ? t('messages.email_verification_title')
    : t('messages.email_verification_action_required_message');

  return (
    <div className="email-verification-content">
      {!isRenderEvPurchaseActionContent ? (
        <>
          {title && (
            <div className="regular-2">
              <span>Verifying </span>
              <span className="semibold-2">{` ${requiredCredits} `}</span>
              <span>
                from the sequence
                <span className="semibold-2">{` "${title}" `}</span>, Below are
                the two types of prospects to whom we recommend you to send
                emails.
              </span>
            </div>
          )}
          {!title && (
            <div className="regular-2">
              {t('messages.help_remove_invalid_prospect')} <br />
              {t('messages.two_types_of_prospect')}
            </div>
          )}
          <div className="valid-contact">
            <div className="status-name regular-2">
              <ImageIcon src={Images.UserCheck} />
              {t('messages.valid_prospect')}
            </div>
            <div className="status-content regular-1 gray-txt-12">
              {t('messages.high_delivery_chances')}
            </div>
          </div>
          <div className="risky-contact">
            <div className="status-name regular-2">
              <ImageIcon src={Images.UserExcremental} />
              {t('messages.risky_prospect')}
            </div>
            <div className="regular-1 gray-txt-12">
              {t('messages.low_delivery_chances')}
            </div>
          </div>
        </>
      ) : (
        <div className="verification-error">
          <span className="regular-2 mb-2">
            {title && t('messages.buy_email_credits_sequence_settings_message')}
            {!title && renderEvPurchaseActionContent}
          </span>
          <EmailVerificationError
            availableCredits={availableCredits}
            requiredCredits={requiredCredits}
          />
        </div>
      )}
    </div>
  );
};

export default memo(ManualEmailVerification);
