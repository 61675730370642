import { createSlice } from '@reduxjs/toolkit';
import { constants } from '../../shared/enums/constants';
import { RequestStatus } from '../../shared/enums/request-status';
import { ResponseErrorWithHandled } from '../../shared/types';
import {
  addTagsToBulkLeadsRequest,
  removeTagsFromBulkLeadsRequest,
} from '../leads/extra-actions';

interface RequestState {
  status: RequestStatus;
  message: string;
  error: ResponseErrorWithHandled;
}

interface State {
  addTagsToBulkLeadsRequest: RequestState;
  removeTagsFromBulkLeadsRequest: RequestState;
  selectedLeads: any[];
  deselectedLeads: number[];
}

const initialState: State = {
  addTagsToBulkLeadsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  removeTagsFromBulkLeadsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  selectedLeads: [],
  // selectAllLeadsDetails: null,
  deselectedLeads: [],
};

const leadSlice = createSlice({
  name: 'lead',
  initialState,
  reducers: {
    clearSelection: (state) => {
      // state.selectAllLeadsDetails = initialState.selectAllLeadsDetails;
      state.selectedLeads = initialState.selectedLeads;
    },

    resetSelectedLeads: (state) => {
      state.selectedLeads = initialState.selectedLeads;
    },
    selectedLeads: (state, action) => {
      state.selectedLeads = action.payload;
    },
    deselectLeads: (state, action) => {
      const leadsToDeselect = action?.payload; // An array of lead IDs to be deselected
    },
  },

  extraReducers: (builder) => {
    // Add Tags to Prospects
    builder.addCase(addTagsToBulkLeadsRequest.pending, (state) => {
      state.addTagsToBulkLeadsRequest.status = RequestStatus.Pending;
      state.addTagsToBulkLeadsRequest.error = null;
    });
    builder.addCase(addTagsToBulkLeadsRequest.fulfilled, (state, action) => {
      state.addTagsToBulkLeadsRequest.status = RequestStatus.Succeeded;
      state.addTagsToBulkLeadsRequest.message = action.payload.message;
    });
    builder.addCase(addTagsToBulkLeadsRequest.rejected, (state, action) => {
      state.addTagsToBulkLeadsRequest.status = RequestStatus.Failed;
      state.addTagsToBulkLeadsRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Remove Tags to Prospects
    builder.addCase(removeTagsFromBulkLeadsRequest.pending, (state) => {
      state.removeTagsFromBulkLeadsRequest.status = RequestStatus.Pending;
      state.removeTagsFromBulkLeadsRequest.error = null;
    });
    builder.addCase(
      removeTagsFromBulkLeadsRequest.fulfilled,
      (state, action) => {
        state.removeTagsFromBulkLeadsRequest.status = RequestStatus.Succeeded;
        state.removeTagsFromBulkLeadsRequest.message = action.payload.message;
      },
    );
    builder.addCase(
      removeTagsFromBulkLeadsRequest.rejected,
      (state, action) => {
        state.removeTagsFromBulkLeadsRequest.status = RequestStatus.Failed;
        state.removeTagsFromBulkLeadsRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );
  },
});

export const {
  clearSelection,
  selectedLeads,
  resetSelectedLeads,
  deselectLeads,
} = leadSlice.actions;

export default leadSlice.reducer;
