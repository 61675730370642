import React from 'react';
import { InfoCircle } from '@saleshandy/icons';
import Switch, {
  Size,
} from '../../../../../../shared/design-system/components/atoms/switch';
import { ShAccountSettingsCode } from '../../../../enums/admin-settings';
import { OverlayTooltip } from '../../../../../../shared/design-system/components/overlay';

type IProps = {
  checked: boolean;
  onChange: (code: ShAccountSettingsCode) => void;
  label: string;
  code: ShAccountSettingsCode;
  learnMoreLink?: string;
};

const AdminSettingToggle: React.FC<IProps> = ({
  checked,
  onChange,
  label,
  code,
  learnMoreLink = '',
}) => (
  <div className="admin-setting-toggle">
    <Switch
      className="admin-setting-toggle--switch"
      checked={checked}
      onChange={() => onChange(code)}
      size={Size.Small}
    />
    <div className="admin-setting-toggle--label">
      <span>{label}</span>
      {learnMoreLink && (
        <a href={learnMoreLink} target="_blank" rel="noreferrer">
          Learn More
        </a>
      )}
    </div>
    {code === ShAccountSettingsCode.IntegrationSetting && (
      <OverlayTooltip text="When this is turned ON, all events including member's events will be sent which might cause duplication of data.">
        <InfoCircle />
      </OverlayTooltip>
    )}
  </div>
);

export default AdminSettingToggle;
