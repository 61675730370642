/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import { Helmet } from 'react-helmet';
import { useFavicon } from 'react-use';
import type { IProps } from './type';
import { defaultAgencyObj } from '../../../components/home/utils/helper';

const HelmetIcon: React.FC<IProps> = ({ icon }) => {
  useFavicon(icon || defaultAgencyObj?.favicon);
  document.querySelector('#favicon').setAttribute('href', icon);

  return (
    <Helmet>
      <link
        rel="apple-touch-icon"
        type="image/png"
        href={icon || defaultAgencyObj?.favicon}
        sizes="180x180"
      />
      <link rel="icon" href={icon} sizes="16x16" />
      <link rel="icon" href={icon} sizes="32x32" />
      <link rel="icon" href={icon} sizes="180x180" />
    </Helmet>
  );
};

export default HelmetIcon;
