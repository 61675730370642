import React from 'react';
import classnames from 'classnames';
import { SkeletonLoading } from '@saleshandy/design-system';
import { IProps } from './types';
import { accessibleOnClick } from '../../../../../../../../shared/utils/accessible-on-click';

const EvCreditPlans: React.FC<IProps> = ({
  credits = [],
  onSelectPlan,
  selectedPlan,
  remainingCredit,
  isPlansLoading = false,
}) => {
  const handleSelectPlan = (plan) => {
    onSelectPlan(plan);
  };

  const renderCreditPlans = () => {
    if (isPlansLoading) {
      return (
        <>
          <div className="credit-plan border-gray">
            <div className="d-flex align-items-center">
              <SkeletonLoading width={70} height={22} />
            </div>
            <SkeletonLoading width={24} height={22} />
          </div>
          <div className="credit-plan border-gray">
            <div className="d-flex align-items-center">
              <SkeletonLoading width={80} height={22} />
            </div>
            <SkeletonLoading width={24} height={22} />
          </div>
          <div className="credit-plan border-gray">
            <div className="d-flex align-items-center">
              <SkeletonLoading width={100} height={22} />
            </div>
            <SkeletonLoading width={24} height={22} />
          </div>
        </>
      );
    }

    return credits?.map((credit) => {
      const creditPlanClass = classnames([
        'credit-plan',
        {
          'disable-credit-plan': remainingCredit > credit.credits,
        },
        {
          'border-blue': credit.id === selectedPlan?.id,
        },
        {
          'border-gray': credit.id !== selectedPlan?.id,
        },
      ]);

      return (
        <div
          className={creditPlanClass}
          {...accessibleOnClick(() => handleSelectPlan(credit))}
        >
          <div className="d-flex align-items-center">
            <span className="semibold-2">{credit.name}</span>
            {credit.isBestValue === 1 && (
              <span className="best-value-txt">BEST VALUE</span>
            )}
          </div>
          <span className="semibold-2">${credit.amount}</span>
        </div>
      );
    });
  };

  return <>{renderCreditPlans()}</>;
};

export default EvCreditPlans;
