import React, { useEffect } from 'react';
import { Cross } from '@saleshandy/icons';

import type { IProps } from './verify-email-modal-container';
import Modal from '../../design-system/components/atoms/modal';
import { VerifyEmail } from '../../svg';
import Button from '../../design-system/ui/button';
import { openChatSupport } from '../../utils/open-chat-support';
import { accessibleOnClick } from '../../utils/accessible-on-click';
import { executeOnRequestStatus, getIsRequestPending } from '../../utils';

const VerifyEmailModal: React.FC<IProps> = ({
  show,
  onClose,
  email,
  resendVerificationEmailRequest,
  resendVerificationEmailRequestStatus,
}) => {
  const onResendVerificationEmail = () => {
    resendVerificationEmailRequest(email);
  };

  useEffect(() => {
    executeOnRequestStatus({
      status: resendVerificationEmailRequestStatus,
      onSuccess: () => {
        onClose();
      },
    });
  }, [resendVerificationEmailRequestStatus]);

  const isLoading = getIsRequestPending(resendVerificationEmailRequestStatus);

  return (
    <Modal
      show={show}
      onClose={onClose}
      onHide={onClose}
      hideHeader
      hideFooter
      backdrop="static"
      className="verify-email-modal"
      extraModalProps={{
        centered: true,
      }}
    >
      <div className="verify-email-close" {...accessibleOnClick(onClose)}>
        <Cross />
      </div>
      <h1>Verify your email to move forward</h1>
      <div>
        <VerifyEmail />
      </div>
      <p>
        A verification email is sent to <span>{email}</span>. Please verify your
        email by clicking the link in the email. If you need any help with
        verification, contact us.
      </p>
      <div className="action-btn">
        <Button
          variant="secondary"
          onClick={openChatSupport}
          disabled={isLoading}
        >
          I need help
        </Button>
        <Button
          onClick={onResendVerificationEmail}
          isLoading={isLoading}
          loadingText="Sending, please wait..."
        >
          Resend verification email
        </Button>
      </div>
    </Modal>
  );
};

export default VerifyEmailModal;
