import React from 'react';
import { Container } from 'react-bootstrap';
import { Redirect, Route, Switch } from 'react-router-dom';
import { IProps } from './type';
import Suspense from '../../shared/components/suspense';
import ErrorBoundaryWrapper from '../../shared/components/error-boundary-wrapper';
import retryLazy from '../retry-lazy';
import SequenceScore from './components/sequence-single-content/components/sequence-score';

const SequenceList = React.lazy(() =>
  retryLazy(() => import('./components/sequence-list')),
);
const SequenceSingle = React.lazy(() =>
  retryLazy(() => import('./components/sequence-single')),
);

const Sequence: React.FC<IProps> = () => (
  <Container fluid className="sequence-container p-0">
    <ErrorBoundaryWrapper>
      <Suspense>
        <Switch>
          <Route exact path="/sequence" component={SequenceList} />
          <Route
            path="/sequence/:sequenceId/:activeTab"
            component={SequenceSingle}
          />
          {/* <Route
            path="/sequence/:sequenceId/steps"
            component={SequenceSingle}
          />
          <Route
            path="/sequence/:sequenceId/prospects"
            component={SequenceSingle}
          />
          <Route
            path="/sequence/:sequenceId/emails"
            component={SequenceSingle}
          />
          <Route
            path="/sequence/:sequenceId/settings"
            component={SequenceSingle}
          /> */}
          {/* <Route
            path="/sequence/:sequenceId/sequence-score"
            component={SequenceScore}
          /> */}
          <Redirect to="/sequence" />
        </Switch>
      </Suspense>
    </ErrorBoundaryWrapper>
  </Container>
);

export default Sequence;
