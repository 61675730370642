import React from 'react';
import ManualEmailVerification from './manual-email-verification';
import ImportEmailVerification from './import-email-verification';
import { EmailVerificationProcess } from './enum';
import { IProps } from './type';

const EmailVerificationContent: React.FC<IProps> = ({
  verificationProcess = EmailVerificationProcess.Manual,
  availableCredits,
  requiredCredits,
  title,
  showVerifyProspectCheckbox,
  verifyProspect,
  setVerifyProspect,
}) =>
  verificationProcess === EmailVerificationProcess.ImportFlow ? (
    <ImportEmailVerification
      availableCredits={availableCredits}
      requiredCredits={requiredCredits}
      showVerifyProspectCheckbox={showVerifyProspectCheckbox}
      verifyProspect={verifyProspect}
      setVerifyProspect={setVerifyProspect}
    />
  ) : (
    <ManualEmailVerification
      availableCredits={availableCredits}
      requiredCredits={requiredCredits}
      title={title}
    />
  );

export default EmailVerificationContent;
