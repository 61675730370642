export const headerLikeEmail = [
  'email address',
  'email_address',
  'email',
  'email id',
];

export const headerLikeFirstName = ['first name', 'first_name', 'firstname'];

export const headerLikeLastName = ['last name', 'last_name', 'lastname'];
