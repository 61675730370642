import { leadFinderBulkOperationLimit } from "../../../shared/utils/user-details";

export const LEAD_SAVED_FILTER_MESSAGES = {
  saved_filter_add_success_msg: 'Filter saved',
  saved_filter_edit_success_msg: 'Filter saved',
  saved_filter_delete_success_msg: 'Filter deleted',
};

export const LEAD_RECENT_SEARCH_MESSAGES = {
  recent_search_delete_msg: 'Recent search deleted Successfully!',
};

export const LEAD_LISTING_MESSAGES = {
  reveal_single_lead_succeess_msg: 'Lead revealed',
  add_to_prospect_single_lead_succeess_msg: 'Prospect added successfully',
  add_to_sequence_single_lead_succeess_msg:
    'Prospect added to sequence successfully',
  max_limit_reached_tooltip_msg: `Max ${leadFinderBulkOperationLimit()} leads can be selected at once`,
  add_to_sequence_tooltip_msg: 'Add to Sequence',
  bulk_export_success_msg: `Lead exported successfully`,
  bulk_export_error_msg: `Lead exported successfully`,
};
