import React, { forwardRef, useState, useImperativeHandle } from 'react';

import { Stage } from './stage';
import { FileData } from '../select-file/types';
import { ConflictAction } from '../../../../../../enums';
import { ImportConfigData, ImportFlowProps } from './types';
import { Field } from '../../../../../../../settings/types/field';
import { RowDataWithTypeVerification } from '../map-fields/types';
import {
  AddTagsToImportedContactsPayload,
  MapConfig,
} from '../../../../../../types';

import SelectFile from '../select-file';
import MapFields from '../map-fields';
import EmailVerification from '../email-verification';
import ImportStageTabs from './components/import-stage-tabs';

const ImportFlow = forwardRef(
  (
    {
      isRequestPending,
      onFinish,
      fields,
      steps,
      isEmailVerification,
      onClose,
      fromModule,
      warning,
      showPurchaseEvCreditsModal,
      isPurchaseEmailVerificationCreditsLoading,
      handleCSVImport,
    }: ImportFlowProps,
    ref,
  ) => {
    const [stage, setStage] = useState<Stage>(Stage.SelectFile);

    const [fileData, setFileData] = useState<FileData>(null);

    const [
      importConfigParam,
      setImportConfigParam,
    ] = useState<ImportConfigData>(null);

    const onStageChange = (newState: Stage) => {
      setStage(newState);
    };

    const selectFileFinishHandler = (fileDataObj: FileData) => {
      setFileData(fileDataObj);
      setStage(Stage.MapFields);
    };

    const mapFieldsFinishHandler = (
      mapConfig: MapConfig,
      conflictAction: ConflictAction,
      stepId: number,
    ) => {
      setStage(Stage.EmailVerification);
      setImportConfigParam({
        file: fileData.file,
        mapConfig,
        conflictAction,
        stepId,
      });
    };

    const handleOnFinishWithoutVerification = (
      tags?: AddTagsToImportedContactsPayload,
    ) => {
      onFinish({
        ...importConfigParam,
        tags,
      });
    };

    const verificationResponse = (
      includeRiskyContact: boolean,
      includeVerification: boolean,
      tags?: AddTagsToImportedContactsPayload,
    ) => {
      if (includeVerification) {
        onFinish({
          ...importConfigParam,
          includeRiskyContact,
          includeVerification,
          tags,
        });
      } else {
        onFinish({
          ...importConfigParam,
          includeVerification,
          tags,
        });
      }
    };

    const filteredFields = fields.reduce((result, field: Field) => {
      const name = field.label.replace(' ', '').toLowerCase();
      if (name !== 'companydomain') {
        result.push(field);
      }
      return result;
    }, []);

    const handleCSVImportCount = (fileDataObj: FileData) => {
      setFileData(fileDataObj);
      handleCSVImport(fileDataObj?.importedContactsCount);
    };

    useImperativeHandle(ref, () => ({
      importAnyWay() {
        selectFileFinishHandler(fileData);
      },
    }));

    return (
      <div className="import-flow import-contacts-csv-modal--flow">
        <ImportStageTabs stage={stage} onStageChange={onStageChange} />

        <div className="import-flow-stage-wrapper import-contacts-csv-modal--stages">
          {stage === Stage.SelectFile && (
            <SelectFile
              onFinish={selectFileFinishHandler}
              onClose={onClose}
              handleCSVImport={handleCSVImportCount}
            />
          )}
          {stage === Stage.MapFields && (
            <MapFields
              headers={fileData.headers}
              firstRow={fileData.firstRow.map(
                (el): RowDataWithTypeVerification => ({
                  value: el,
                  isTypeValid: null,
                }),
              )}
              fields={filteredFields}
              onFinish={mapFieldsFinishHandler}
              isRequestPending={isRequestPending}
              steps={steps}
              onClose={onClose}
              fromModule={fromModule}
            />
          )}
          {stage === Stage.EmailVerification && (
            <EmailVerification
              warning={warning}
              onClick={verificationResponse}
              isRequestPending={isRequestPending}
              fileData={fileData}
              onBuyNow={(remainingCredit) =>
                showPurchaseEvCreditsModal(remainingCredit)
              }
              isPurchaseEmailVerificationCreditsLoading={
                isPurchaseEmailVerificationCreditsLoading
              }
              isEmailVerification={isEmailVerification}
              handleOnFinishWithoutVerification={
                handleOnFinishWithoutVerification
              }
            />
          )}
        </div>
      </div>
    );
  },
);

export default ImportFlow;
