import { Field } from '../../../components/settings/types/field';
import { InputValidationType } from '../../design-system/components/organisms/form/types';
import { RequestStatus } from '../../enums/request-status';
import { showGeneralErrorNotification } from '../../utils/errors';

export const getIsLoading = ({ getFieldsRequestStatus, fields }): boolean =>
  getFieldsRequestStatus === RequestStatus.Pending || fields.length <= 0;

export const getIsCompanyDomainField = (name: string): boolean =>
  name !== 'companydomain';

export const getFieldValidator = (name: string): string =>
  // eslint-disable-next-line no-nested-ternary
  name === 'email'
    ? InputValidationType.isEmail
    : name === 'phonenumber'
    ? InputValidationType.isMobilePhone
    : InputValidationType.MaxCharLimit;

// eslint-disable-next-line consistent-return
export const generateFormFieldConfig = (field: Field, result) => {
  const name = field?.label?.replace(' ', '')?.toLowerCase();

  if (getIsCompanyDomainField(name)) {
    result.push({
      isDefault: field.isDefault,
      id: field.id,
      createdAt: field.createdAt,
      name,
      displayName: field.label,
      component: field.fieldType,
      styleClassNames: 'col-md-6',
      validator: getFieldValidator(name),
    });
  }

  return result;
};

export const getAddProspectToasterMessage = ({
  t,
  shouldSendAddToContactStepRequest,
  addProspectRequestMessage,
}) =>
  shouldSendAddToContactStepRequest
    ? t('messages.create_and_add_prospect_successfully')
    : addProspectRequestMessage;

export const getIsRequestPending = (status): boolean =>
  status === RequestStatus.Pending;

export const showGeneralError = (error) => {
  if (error) {
    showGeneralErrorNotification(error.message);
  }
};
