import { ConflictAction } from '../../../../../../enums/contact';

export type ConflictActionOption = {
  key: number;
  label: string;
  value: ConflictAction;
  text: string;
};

export const conflictActionOptions: ConflictActionOption[] = [
  {
    key: 1,
    label: 'Update Missing Fields',
    value: ConflictAction.AddMissingFields,
    text:
      'Only updates fields that are missing in your existing prospects with details from the CSV.',
  },
  {
    key: 2,
    label: 'Overwrite',
    value: ConflictAction.Overwrite,
    text:
      'Replaces all existing prospect field details with new information from the CSV.',
  },
  {
    key: 3,
    label: 'Skip Existing Prospects',
    value: ConflictAction.NoUpdate,
    text:
      'Keeps existing prospect field details unchanged and does not apply any updates.',
  },
];
