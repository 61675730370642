import React, { useState } from 'react';
import Papa from 'papaparse';
import { DragAndDrop } from '@saleshandy/design-system';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import FileUploader from '../file-uploader';
import { PreSelectProps } from './types';
import toaster, { Theme } from '../../../../../../../../../../shared/toaster';
import { FileConfig } from '../../enum';
import UploadCloud from '../../../../../../../../../../shared/components/images/upload-cloud';

const allowedExtensions = ['csv'];

const PreSelect = ({ onSelectSuccess }: PreSelectProps) => {
  const [fileUploaderKey, setFileUploaderKey] = useState(0);
  const [dragging, setDragging] = useState(false);

  const resetFileUploader = () => setFileUploaderKey(1 - fileUploaderKey);

  const showFailedMessage = (message: string) =>
    toaster.error(message, { theme: Theme.New });

  const failureHandler = (message: string) => {
    showFailedMessage(message);
    resetFileUploader();
  };

  const onFileSelect = (file) => {
    let headers;
    let firstRow;
    let count = 0;
    let failedMessage;

    const extension: string[] = file?.name?.split('.');

    if (!extension || extension.length === 0) {
      failedMessage = `File extension is not available.`;
      return failureHandler(failedMessage);
    }

    if (
      !allowedExtensions.includes(
        extension[extension.length - 1].trim().toLowerCase(),
      )
    ) {
      failedMessage = `The file type you are trying to upload is not supported.`;
      return failureHandler(failedMessage);
    }

    if (file.size > FileConfig.MaxFileSizeInMB * 1024 * 1024) {
      failedMessage = `File size must be less than ${FileConfig.MaxFileSizeInMB} MB`;
      return failureHandler(failedMessage);
    }
    Papa.parse<string>(file, {
      step: ({ data }, parser) => {
        if (count > FileConfig.MaxRows) {
          failedMessage = `Maximum ${FileConfig.MaxRows} prospects are allowed`;
          return parser.abort();
        }
        if (count === 0) {
          headers = data;
          if (headers.length > FileConfig.MaxColumns) {
            failedMessage = `Maximum ${FileConfig.MaxColumns} columns are allowed`;
            return parser.abort();
          }
        }
        if (count === 1) {
          firstRow = data;
        }
        count += 1;

        return null;
      },
      complete: () => {
        if (!failedMessage && !firstRow) {
          failedMessage = 'At least 1 prospect is required';
        }
        if (failedMessage) {
          return failureHandler(failedMessage);
        }
        onSelectSuccess(file, headers, firstRow, count);

        return null;
      },
      skipEmptyLines: 'greedy',
    });

    return null;
  };

  const dragAndDropClassName = classNames([
    {
      dragging,
    },
  ]);

  const { t } = useTranslation();

  return (
    <DragAndDrop
      dragging={dragging}
      setDragging={setDragging}
      handleDroppedFiles={(files) => onFileSelect(files[0])}
      backgroundVariant="solid"
      title={t('messages.drop_your_csv_here')}
      className={dragAndDropClassName}
    >
      <div className="pre-select">
        <div className="empty-data-icon-wrapper">
          <UploadCloud />
        </div>
        <span className="pre-select-tagline">
          {t('messages.drag_and_drop_csv')}
        </span>
        <span className="pre-select-info">OR</span>

        <FileUploader
          key={fileUploaderKey}
          className="pre-select-file-uploader"
          onSelect={onFileSelect}
        />
      </div>
    </DragAndDrop>
  );
};

export default PreSelect;
