/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-did-update-set-state */
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Icon from '../../../../shared/design-system/components/atoms/icon/icon';
import { RequestStatus } from '../../../../shared/enums/request-status';
import { redirectWithRefresh } from '../../../../shared/utils';
import { SequenceAction, SequenceProgress } from '../../enums';
import SequenceSingleTitle from './components/sequence-single-title';
import { IProps } from './types';
import { planError } from '../../../../shared/utils/errors/plan-permission-error/plan-error';
import { SubscriptionPlans } from '../../../../shared/utils/subscription-plans';
import { UserRole } from '../../../../shared/enums/user-roles';
import hasResource from '../../../../shared/utils/access-control/has-resource';
import { ResourceIdentifiers } from '../../../../shared/utils/access-control/enums/resource-identifiers';
import hasPermission from '../../../../shared/utils/access-control/has-permission';
import Switch, {
  Size,
} from '../../../../shared/design-system/components/atoms/switch';
import { ProductTour } from '../../../../shared/enums/product-tour';
import { Permissions } from '../../../../shared/utils/access-control/enums/permissions';
import { getLabel } from '../sequence-single-content/components/sequence-single-contacts/components/contact-list-content/components/prospect-activity-modal/helpers';
import { Placement } from '../../../../shared/design-system/components/overlay';
import {
  getSequenceAction,
  getTooltipText,
} from '../sequence-single-content/components/sequence-single-header-actions/utils/helper';
import { sequencePauseMessages } from '../../enums/sequence-pause-reasons';
import UpgradePlanErrorModal from '../../../../shared/components/upgrade-plan-error-modal';
import VerifyEmailModal from '../../../../shared/components/verify-email-modal';
import PauseResumeSequenceModal from '../../shared/modals/pause-resume-sequence-modal';
import RestrictionErrorModal from '../../../prospect/components/prospect-list/components/modals/restriction-error-modal';
import {
  getEmailSendingRestrictionModalContent,
  getOverLimitConnectedEmailErrorMsg,
} from '../../../prospect/components/prospect-list/utils/helper';
import { getEmailBody } from '../../../home/components/header/utils/helper';

type IState = {
  showPauseResumeSequenceModal: boolean;
  action: SequenceAction;
  showConnectEmailAccountModal: boolean;
  showRestrictionModal: boolean;
  showUpgradePlanErrorModal: boolean;
  isVerifyEmailModalShow: boolean;
  isRestrictionModalVisible: boolean;
};

class SequenceSingleHeader extends React.Component<IProps, IState> {
  switchRef: React.RefObject<any>;

  constructor(props) {
    super(props);
    this.state = {
      showPauseResumeSequenceModal: false,
      action: null,
      showConnectEmailAccountModal: false,
      showRestrictionModal: false,
      showUpgradePlanErrorModal: false,
      isVerifyEmailModalShow: false,
      isRestrictionModalVisible: false,
    };
    this.showPauseResumeSequenceModal = this.showPauseResumeSequenceModal.bind(
      this,
    );
    this.hidePauseResumeSequenceModal = this.hidePauseResumeSequenceModal.bind(
      this,
    );
    this.onSwitchChange = this.onSwitchChange.bind(this);
    this.onPauseResumeSequenceModalSubmit = this.onPauseResumeSequenceModalSubmit.bind(
      this,
    );
    this.onSequenceTitleChange = this.onSequenceTitleChange.bind(this);
    this.skipConnectEmailAccount = this.skipConnectEmailAccount.bind(this);
    this.switchRef = React.createRef();
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    const {
      pauseResumeSequenceRequestStatus,
      pauseResumeSequenceRequestError,
      sendGetSequenceRequest,
      sequenceId,
      connectEmailAccountRequestStatus,
      authUrl,
    } = this.props;

    if (
      pauseResumeSequenceRequestStatus !==
      prevProps.pauseResumeSequenceRequestStatus
    ) {
      if (pauseResumeSequenceRequestStatus === RequestStatus.Succeeded) {
        // changing a state when prop changes!!
        this.hidePauseResumeSequenceModal();
        sendGetSequenceRequest(sequenceId);
      }

      if (
        pauseResumeSequenceRequestStatus === RequestStatus.Failed &&
        pauseResumeSequenceRequestError
      ) {
        if (pauseResumeSequenceRequestError.code === 4001) {
          // eslint-disable-next-line react/no-unused-state
          this.setState({ showConnectEmailAccountModal: true });
          this.hidePauseResumeSequenceModal();
        }
      }
    }

    if (
      connectEmailAccountRequestStatus !==
        prevProps.connectEmailAccountRequestStatus &&
      connectEmailAccountRequestStatus === RequestStatus.Succeeded
    ) {
      redirectWithRefresh(authUrl);
    }
  }

  onSequenceTitleChange(title: string) {
    const { sequenceId, sendRenameSequenceRequest } = this.props;
    sendRenameSequenceRequest(sequenceId, title);
  }

  onSwitchChange(checked: boolean) {
    const { subscriptionPlan, role } = this.props;
    if (subscriptionPlan === SubscriptionPlans.FREE) {
      if (role === UserRole.ADMIN) {
        planError(2003);
      } else {
        planError(3003);
      }
    } else {
      const action = checked ? SequenceAction.Resume : SequenceAction.Pause;
      this.showPauseResumeSequenceModal(action);
    }
  }

  onPauseResumeSequenceModalSubmit() {
    const { action } = this.state;
    const { sequenceId, sendPauseResumeSequenceRequest } = this.props;

    hasResource(ResourceIdentifiers.SEQUENCES_PAUSE_RESUME) &&
      sendPauseResumeSequenceRequest(sequenceId, action);
  }

  hideRestrictionModal = () => {
    this.setState({ isRestrictionModalVisible: false });
  };

  onToggleSequenceAction = (checked: boolean) => {
    const {
      remainingEmailSentLimit,
      sequence,
      subscriptionDetails,
      verified,
    } = this.props;

    if (remainingEmailSentLimit === 0 && checked) {
      this.showRestrictionModal();
      return;
    }

    if (sequence.isSequenceHasOverLimitEmails) {
      this.showRestrictionModal();
      return;
    }

    if (subscriptionDetails?.planCode === SubscriptionPlans.FREE) {
      planError(2003);
    } else if (this.shouldShowPlanUpgradeErrorModal(subscriptionDetails)) {
      this.setState({ showUpgradePlanErrorModal: true });
      // setShowUpgradePlanErrorModal(true);
    } else if (!verified) {
      this.showVerifyEmailModal();
    } else {
      const action = getSequenceAction(checked);
      hasResource(ResourceIdentifiers.SEQUENCES_PAUSE_RESUME) &&
        this.showPauseResumeSequenceModal(action);
    }
  };

  shouldShowPlanUpgradeErrorModal = (subscriptionDetails) =>
    subscriptionDetails?.planCode === SubscriptionPlans.EMAIL_STARTER_MONTHLY ||
    subscriptionDetails?.planCode === SubscriptionPlans.EMAIL_STARTER_YEARLY;

  showRestrictionModal = () => {
    this.setState({ showRestrictionModal: true });
  };

  showVerifyEmailModal = () => {
    this.setState({ isVerifyEmailModalShow: true });
  };

  hideVerifyEmailModal = () => {
    this.setState({ isVerifyEmailModalShow: false });
  };

  hideUpgradePlanErrorModal = () => {
    this.setState({ showUpgradePlanErrorModal: false });
  };

  showPauseResumeSequenceModal(action: SequenceAction) {
    this.setState({ action, showPauseResumeSequenceModal: true });
  }

  hidePauseResumeSequenceModal() {
    this.setState({ showPauseResumeSequenceModal: false });
  }

  skipConnectEmailAccount() {
    this.setState({ showConnectEmailAccountModal: false });
  }

  render() {
    const {
      title,
      sequenceId,
      progress,
      pausedReasonIdentifier,
      pauseResumeSequenceRequestStatus,
      remainingEmailSentLimit,
      t,
    } = this.props;
    const {
      showPauseResumeSequenceModal,
      showUpgradePlanErrorModal,
      isRestrictionModalVisible,
      isVerifyEmailModalShow,
      action,
    } = this.state;

    const isSequenceActive = progress === SequenceProgress.Active;
    const reason = pausedReasonIdentifier
      ? sequencePauseMessages[pausedReasonIdentifier]
      : '';
    const isPauseResumeSequenceRequestPending =
      pauseResumeSequenceRequestStatus === RequestStatus.Pending;

    return (
      <>
        <div className="d-flex justify-content-between align-items-center">
          <div className="single-sequence-header d-flex align-items-center">
            <Link className="single-sequence-header--title" to="/sequence">
              Sequence
            </Link>
            <div className="single-sequence-header--icon">
              <Icon identifier="chevron-right" />
            </div>
            <SequenceSingleTitle
              title={title}
              onChange={this.onSequenceTitleChange}
              sequenceId={sequenceId}
            />

            {hasPermission(Permissions.SEQUENCE_WRITE) && (
              <div id={ProductTour.ActiveSequence} className="switch-mt">
                <Switch
                  checked={isSequenceActive}
                  label={getLabel(progress)}
                  size={Size.Small}
                  onChange={this.onToggleSequenceAction}
                  ref={this.switchRef}
                  placement={Placement.Bottom}
                  tooltip={getTooltipText(reason, t)}
                  overlayTooltipClass="sequence-switch-tooltip"
                />
              </div>
            )}
          </div>
        </div>

        <PauseResumeSequenceModal
          show={showPauseResumeSequenceModal}
          action={action}
          onSubmit={this.onPauseResumeSequenceModalSubmit}
          onClose={this.hidePauseResumeSequenceModal}
          isRequestPending={isPauseResumeSequenceRequestPending}
        />

        <UpgradePlanErrorModal
          show={showUpgradePlanErrorModal}
          onClose={this.hideUpgradePlanErrorModal}
          planName="starter"
          restrictionOn="sequence"
        />

        {isRestrictionModalVisible && (
          <RestrictionErrorModal
            show={isRestrictionModalVisible}
            modalTitle="Upgrade your plan to resume sequence"
            bodyContent={
              remainingEmailSentLimit === 0
                ? getEmailSendingRestrictionModalContent()
                : getOverLimitConnectedEmailErrorMsg()
            }
            emailBody={getEmailBody()}
            onClose={this.hideRestrictionModal}
          />
        )}

        <VerifyEmailModal
          show={isVerifyEmailModalShow}
          onClose={this.hideVerifyEmailModal}
        />
      </>
    );
  }
}

export default withTranslation()(SequenceSingleHeader);
